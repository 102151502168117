import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Row } from "reactstrap";
import DayPassNav from "./dayPassNav";
import "./css/tours.css";

const DayPass = () => {
  return (
    <div className='mainContent scroller'>
      <Row className='mt-3'>
        <Col md={3}>
          <DayPassNav />
        </Col>
        <Col md={9} className='fullHight'>
          <div className='mainContainer'>
            <Outlet />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DayPass;
