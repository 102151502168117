import React from "react";
import { Button, Card, Col, Input, Row } from "reactstrap";
import userlogo from "./../../../images/icons/userlogo.svg";
import usercall from "./../../../images/icons/call.svg";
import usercorporatefare from "./../../../images/icons/corporate_fare.svg";
import filePdfSolid from "./../../../images/icons/file-pdf-solid.svg";
import usercorporatefare1 from "./../../../images/icons/corporate_fare1.svg";
import usermail from "./../../../images/icons/mail.svg";
import percentate from "./../../../images/icons/percentate.svg";
import comAdress from "./../../../images/icons/comAdress.svg";

import Vector7 from "./../../../images/icons/Vector7.svg";
import Vector8 from "./../../../images/icons/Vector8.svg";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import webSite from "../../../images/icons/webSite.svg";
import workTilte from "../../../images/icons/workTilte.svg";

import registrationStatus from "../../../images/icons/registrationStatus.svg";

import calendr from "../../../images/icons/calendr.svg";
import linkedin from "./../../../images/icons/linkedin.svg";
import badge_fill from "./../../../images/icons/badge_fill.svg";
import "./css/styles.css";
import UseReviewDetails from "./useReviewDetails";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import PrivacyPolicyModel from "../../../components/molicules/privacyPolicyModel";

const Index = () => {
  const Navigation = useNavigate();
  const {
    openPrivacyPolicy,
    privacyCheck,
    setPrivacyCheck,
    selectedFiles,
    selectedFiles1,
    teamArrPre,
    photoDataByApi,
    photoDataByApi1,
    dateValuePre,
    slotValuePre,
    profileData,
    navigate,
    scheduledSubmitFunc,
    modelShow,
    companyInfoPrev,
    setModelShow,
    userEmail,
    tourId,
    modelShow1,
    setModelShow1

  } = UseReviewDetails();

  return (
    <div>
      <div className="ebgcolor p-4">
        <div className="allCardsPadding">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <span className="emainheading">
                <img
                  src={backArrow}
                  alt="arrow"
                  className="arrow_small_screen"
                  onClick={() => {
                    localStorage.setItem("navActive", ["1", "2", "3", "4"]);
                    navigate("/membership/scheduleMemberInfo", {
                      state: {
                        profileData: profileData,
                        companyInfoPrev: companyInfoPrev,
                        teamArrPre: teamArrPre,
                        photoDataByApi: photoDataByApi,
                        photoDataByApi1: photoDataByApi1,
                        slotValuePre: slotValuePre,
                        dateValuePre: dateValuePre,
                      },
                    });
                  }}
                />
                Review Details
              </span>
              <p className="mailmainpheading2">
                Please confirm the details below
              </p>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              <div className="icon-box">
                <span onClick={() => setModelShow(true)}>
                  <img src={CloseIcon} alt="logo" width={24} height={24} />
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <p className="emainheadingMobView my-2 ">Membership</p>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Card className="p-4 form-card">
                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Personal details </p>

                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      localStorage.setItem("navActive", ["1"]);
                      navigate("/membership/profileInfo", {
                        state: {
                          profileData: profileData,
                          teamArrPre: teamArrPre,
                          slotValuePre: slotValuePre,
                          dateValuePre: dateValuePre,
                          companyInfoPrev: companyInfoPrev,
                          photoDataByApi1prev: photoDataByApi1,
                          photoDataByApiprev: photoDataByApi,
                          selectedFiles: selectedFiles,
                          selectedFiles1: selectedFiles1,
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>
                <div className="d-flex">
                  <img src={userlogo} alt="img" />{" "}
                  <span className="rds-h2 mx-2">
                    {profileData?.firstName} {profileData?.lastName}
                  </span>
                </div>
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usermail} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Email Address</p>
                    </div>
                    <p className="rds-h2 mx-4"> {userEmail}</p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usercorporatefare} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">Company name</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {companyInfoPrev?.companyName
                        ? (companyInfoPrev?.companyName && companyInfoPrev?.companyName.length > 50)
                          ? companyInfoPrev?.companyName.slice(0, 50) + '...'
                          : companyInfoPrev?.companyName
                        : ""


                      }
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usercall} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Phone number</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      <p> {profileData?.phoneNum}</p>
                    </p>
                  </Col>
                </Row>
                <hr />

                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Employment information </p>

                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      localStorage.setItem("navActive", ["1"]);
                      navigate("/membership/profileInfo", {
                        state: {
                          profileData: profileData,
                          teamArrPre: teamArrPre,
                          slotValuePre: slotValuePre,
                          dateValuePre: dateValuePre,
                          companyInfoPrev: companyInfoPrev,
                          photoDataByApi1prev: photoDataByApi1,
                          photoDataByApiprev: photoDataByApi,
                          selectedFiles: selectedFiles,
                          selectedFiles1: selectedFiles1,
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={badge_fill} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Employment status</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {profileData?.employmentStatus}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={Vector7} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">Occupation</p>
                    </div>
                    <p className="rds-h2 mx-4"> {profileData?.workTitle}</p>
                  </Col>
                </Row>

                {/* socials are comented  */}

                {/* <hr />

                <p className='rds-h1'>Socials </p>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className='d-flex'>
                      <img src={Vector8} alt='img' />{" "}
                      <p className='mx-2 mt-3 rds-h3'>Twitter</p>
                    </div>
                    <p className='rds-h2 mx-4'> {profileData?.twitterName}</p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className='d-flex'>
                      <img src={linkedin} alt='img' />
                      <p className='mx-2 mt-3 rds-h3'>LinkedIn</p>
                    </div>
                    <p className='rds-h2 mx-4'>{profileData?.linkedinName}</p>
                  </Col>
                </Row> */}
              </Card>
              <Card className="p-4 form-card mt-3">
                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Company Information </p>

                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      localStorage.setItem("navActive", ["1", "2"]);
                      navigate("/membership/companyInfo", {
                        state: {
                          profileData: profileData,
                          teamArrPre: teamArrPre,
                          slotValuePre: slotValuePre,
                          dateValuePre: dateValuePre,
                          companyInfoPrev: companyInfoPrev,
                          selectedFilesPrev: selectedFiles,
                          selectedFilesPrev1: selectedFiles1,
                          photoDataByApi1prev: photoDataByApi1,
                          photoDataByApiprev: photoDataByApi,
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usercorporatefare1} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Company name</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {companyInfoPrev?.companyName
                        ? (companyInfoPrev?.companyName && companyInfoPrev?.companyName.length > 50)
                          ? companyInfoPrev?.companyName.slice(0, 50) + '...'
                          : companyInfoPrev?.companyName
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usercorporatefare1} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">Operational Status </p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {companyInfoPrev?.operationalStatus
                        ? companyInfoPrev?.operationalStatus
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={registrationStatus} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">Registration Status </p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {companyInfoPrev?.registrationStatus
                        ? companyInfoPrev?.registrationStatus
                        : ""}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usermail} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Email Address</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {companyInfoPrev?.emailAddress
                        ? companyInfoPrev?.emailAddress
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usercorporatefare1} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">CR Number </p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {companyInfoPrev?.commercialRegister
                        ? companyInfoPrev?.commercialRegister
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={percentate} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">VAT Number </p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {companyInfoPrev?.vatNumber
                        ? companyInfoPrev?.vatNumber
                        : ""}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usercall} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Phone number</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {companyInfoPrev?.phoneNum
                        ? companyInfoPrev?.phoneNum
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={webSite} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">Website </p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {companyInfoPrev?.webSite ? companyInfoPrev?.webSite : ""}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={comAdress} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Company Address </p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {companyInfoPrev?.companyAddress
                        ? companyInfoPrev?.companyAddress
                        : ""}
                    </p>
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={Vector8} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Twitter</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {companyInfoPrev?.twitterName
                        ? companyInfoPrev?.twitterName
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={linkedin} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">LinkedIn</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {companyInfoPrev?.linkedinName
                        ? companyInfoPrev?.linkedinName
                        : ""}
                    </p>
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <p className=" mt-3 rds-h3">Description</p>
                    </div>
                    {companyInfoPrev?.companyDescription ? (
                      <Input
                        type="textarea"
                        maxLength={700}
                        value={
                          companyInfoPrev?.companyDescription
                            ? companyInfoPrev?.companyDescription
                            : ""
                        }
                        disabled={true}
                      />
                    ) : (
                      ""
                    )}
                    {/* <p className='rds-h2 mx-4'>{profileData?.linkedinName}</p> */}
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <p className=" mt-3 rds-h3">Documents</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6}>

                    <p className="rds-h2 mx-2">
                      {selectedFiles1?.preview ? (
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={"pic"}
                          src={
                            // photoDataByApi1[0]
                            selectedFiles1?.preview
                              ? filePdfSolid : ""
                          }
                        />
                      ) : (
                        ""
                      )}
                    </p>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6}>

                    <p className="rds-h2 mx-2">
                      {selectedFiles?.preview ? (
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt="logo"
                          src={selectedFiles?.preview}
                        />
                      ) : (
                        ""
                      )}
                    </p>
                  </Col>
                </Row>
              </Card>
              <Card className="p-4 form-card mt-3">
                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Team Information </p>

                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      // Navigation("/membership/teamInfo");

                      localStorage.setItem("navActive", ["1", "2", "3"]);
                      navigate("/membership/teamInfo", {
                        state: {
                          profileData: profileData,
                          companyInfoPrev: companyInfoPrev,
                          teamArrPre: teamArrPre,
                          photoDataByApi: photoDataByApi,
                          photoDataByApi1: photoDataByApi1,
                          selectedFiles: selectedFiles,
                          selectedFiles1: selectedFiles1,
                          slotValuePre: slotValuePre,
                          dateValuePre: dateValuePre,
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>

                {teamArrPre?.map((ele, index) => {
                  return (
                    <div key={index + 998232}>
                      <div className="d-flex">
                        <span className="rds-h2  mt-2">Member {index + 1}</span>
                      </div>
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <div className="d-flex">
                            <img src={userlogo} alt="img" />{" "}
                            <p className="mx-2 mt-3 rds-h3">Name</p>
                          </div>
                          <p className="rds-h2 mx-4"> {ele?.name}</p>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <div className="d-flex">
                            <img src={usermail} alt="img" />
                            <p className="mx-2 mt-3 rds-h3">Email Address</p>
                          </div>
                          <p className="rds-h2 mx-4">{ele?.email}</p>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <div className="d-flex">
                            <img src={usercall} alt="img" />{" "}
                            <p className="mx-2 mt-3 rds-h3">Phone number</p>
                          </div>
                          <p className="rds-h2 mx-4">
                            <p> {ele?.phoneNumber}</p>
                          </p>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <div className="d-flex">
                            <img src={workTilte} alt="img" />{" "}
                            <p className="mx-2 mt-3 rds-h3">Work title</p>
                          </div>
                          <p className="rds-h2 mx-4">
                            <p>{ele?.workTitle}</p>
                          </p>
                        </Col>
                      </Row>
                      <hr className="mb-4" />
                    </div>
                  );
                })}
              </Card>

              <Card className="p-4 form-card mt-3">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex justify-content-between">
                      <p className="rds-h1">Schedule details</p>

                      <p
                        type="button"
                        className="rds-h1"
                        onClick={() => {
                          localStorage.setItem("navActive", [
                            "1",
                            "2",
                            "3",
                            "4",
                          ]);
                          navigate("/membership/scheduleMemberInfo", {
                            state: {
                              profileData: profileData,
                              companyInfoPrev: companyInfoPrev,
                              teamArrPre: teamArrPre,
                              photoDataByApi: photoDataByApi,
                              photoDataByApi1: photoDataByApi1,
                              slotValuePre: slotValuePre,
                              dateValuePre: dateValuePre,
                              selectedFiles: selectedFiles,
                              selectedFiles1: selectedFiles1
                            },
                          });
                        }}
                      >
                        Edit
                      </p>
                    </div>
                    <div className="d-flex">
                      <img src={calendr} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Start Date</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      <p>
                        {slotValuePre
                          ? slotValuePre
                          : moment(dateValuePre).format("DD MMMM YYYY")}
                      </p>
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="d-flex">
          <input
            style={{ height: 22, width: 22 }}
            className="mt-3 mx-2"
            type="checkbox"
            checked={privacyCheck}
            onChange={() => {
              setPrivacyCheck(!privacyCheck);
            }}
          ></input>
          <Link to={"/privacy-policy"}>

            <p
              className="mt-3 rds-h2 with-arrow"

            >
              I agree to the privacy policy of Fintech Hub.
            </p>
          </Link>
        </div>
        {/* <div className="d-flex">
          <input
          style={{height:23, width:23}}
            type="checkbox"
            checked={privacyCheck}
            onChange={() => {
              setPrivacyCheck(!privacyCheck);
            }}
          ></input>
        <Link to={"/privacy-policy"}>

            <p
              className="mt-3 mx-3 rds-h2 with-arrow"
              // onClick={() => setModelShow1(true)}
            >
              I agree to the privacy policy of Fintech Hub.
            </p>
          </Link>
        </div> */}
      </div>
      <Row className="mb-3 mx-2">
        <Col lg={8} md={8} sm={12} xs={12}></Col>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Button
            outline
            color="secondary"
            className="pButton w-100 mt-3 "
            onClick={() => {
              localStorage.setItem("navActive", ["1", "2", "3", "4"]);
              navigate("/membership/scheduleMemberInfo", {
                state: {
                  profileData: profileData,
                  companyInfoPrev: companyInfoPrev,
                  teamArrPre: teamArrPre,
                  photoDataByApi: photoDataByApi,
                  photoDataByApi1: photoDataByApi1,
                  slotValuePre: slotValuePre,
                  dateValuePre: dateValuePre,
                },
              });
            }}
          >
            Back
          </Button>
        </Col>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Button
            color="secondary"
            onClick={scheduledSubmitFunc}
            className="ebutton1 w-100 mt-3 "
            disabled={!privacyCheck}
          >
            Submit
          </Button>
        </Col>
      </Row>

      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />

      <PrivacyPolicyModel
        show={modelShow1}
        setModelShow={setModelShow1}
      />
    </div>
  );
};

export default Index;
