import axios from "axios";
// export const Suadi_fintech_URL = process.env.REACT_APP_BASE_URL;

const axiosClient = axios.create({
  // baseURL: "http://119.160.90.30:8000/api",
  // baseURL: "https://int-fintech-backend.devbeans.io",
  // baseURL: "http://192.168.101.155:3000",
  // baseURL: "https://hubportalapi.fintechsaudi.devbeans.io",
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export default axiosClient;
