import { Suadi_fintech_URL } from "../config/url";
import axios from "axios";

export const getTours = async () => {
  const res = await axios.get(`${Suadi_fintech_URL}/tours-availability`);

  const data = await res;
  return {
    data,
  };
};
