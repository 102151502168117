import React from "react";
import { Col, Row, Card, Button, Label } from "reactstrap";
import FormInput from "../../../components/molicules/formInputs";
import { UseScheduledInfo } from "./useSchedule";
import bottomPic from "../../../images/footers/footer-mobile.svg";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import ReplayIcon from "../../../images/icons/replay.svg";
import "./css/schdule.css";

const ScheduleMemberInfo = () => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const {
    datedisabled,
    setDatedisabled,
    slotValue,
    dateValue,
    modelShow,
    setModelShow,
    tog_static,
    scheduledSubmitFunc,
    dateInputFunc,
    backBtnFunc,
    resetButton,
  } = UseScheduledInfo();

  return (
    <div className='ebgcolor  p-4 '>
      <div className='allCardsPadding'>
        <Row className='mt-2'>
          <Col lg={10} md={10} sm={10} xs={10}>
            <span className='emainheading '>
              <div onClick={backBtnFunc}>
                {" "}
                <img
                  src={backArrow}
                  alt='arrow'
                  className='arrow_small_screen'
                />
              </div>
              Schedule
            </span>
            <p className='mailmainpheading2'>
              Please provide the information below
            </p>
          </Col>

          <Col lg={2} md={2} sm={2} xs={2}>
            <div className='icon-box'>
              <span onClick={() => setModelShow(true)}>
                <img src={CloseIcon} alt='logo' width={24} height={24} />
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card className='p-4 form-card'>
              <Row>
                <Col lg={10} md={10} sm={10} xs={10}>
                  <p className='emainheadingMobView my-2 '>Membership</p>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <p className='sheading'>Select your membership start date</p>
                  <FormInput
                    disabled={datedisabled}
                    name='fName'
                    onChange={(e) => {
                      dateInputFunc(e);
                    }}
                    type='date'
                    errorMessage='Required'
                    min={today.toISOString().substr(0, 10)}
                    // required={true}
                    value={dateValue}
                  />
                </Col>
                <Col className='mt-2' lg={2} md={6} sm={12} xs={12}>
                  <Label color='' className=' w-100 mt-5' onClick={resetButton}>
                    <img
                      src={ReplayIcon}
                      alt='replay'
                      className='mx-2'
                      style={{ height: 18 }}
                    />{" "}
                    Reset
                  </Label>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <p className='sheading'>OR</p>
                </Col>
              </Row>

              <Row className={`${dateValue ? "disable-div" : ""}`}>
                <Col key='1' lg={2} md={4} sm={12} xs={12}>
                  <Label
                    type='button'
                    className={`${
                      slotValue === "FLEXIBLE" ? "timeslotBtn2" : "timeslotBtn"
                    }`}
                    onClick={(e) => {
                      tog_static(`FLEXIBLE`);
                      setDatedisabled(true);
                    }}
                  >
                    FLEXIBLE{" "}
                  </Label>
                </Col>
                </Row>
                <Row className='mt-3'>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <p className='sheading'>OR</p>
                </Col>
              </Row>
                <Row  className={`${dateValue ? "disable-div" : ""}`}>

                <Col key='2' lg={2} md={4} sm={12} xs={12}>
                  <Label
                    type='button'
                    className={`${
                      slotValue === "ASAP" ? "timeslotBtn2" : "timeslotBtn"
                    }`}
                    onClick={(e) => {
                      tog_static(`ASAP`);
                      setDatedisabled(true);
                    }}
                  >
                    ASAP
                  </Label>
                </Col>
              </Row>

              <Row className='mt-4 mb-4'>
                <Col lg={6} md={6} sm={12} xs={12}></Col>
                <Col lg={3} md={3} sm={6} xs={6}>
                  <Button
                    outline
                    color='secondary'
                    className='pButton w-100 mt-3'
                    onClick={backBtnFunc}
                  >
                    Back
                  </Button>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <Button
                    onClick={scheduledSubmitFunc}
                    color='secondary'
                    className='ebutton1 w-100 mt-3'
                    disabled={slotValue || dateValue ? false : true}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <img src={bottomPic} alt='img' className='bottomImg' />
        </Col>
      </Row>

      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />
    </div>
  );
};

export default ScheduleMemberInfo;
