import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getSlots } from "../../../APIS/schduleInfo";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../../config/axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useEffect } from "react";

export const UseScheduledInfo = () => {
  const { state } = useLocation();
  const {
    profileData,
    companyInfoPrev,
    teamArrPre,
    photoDataByApi,
    photoDataByApi1,
    slotValuePre,
    dateValuePre,
    selectedFiles,
    selectedFiles1,

  } = state;
  const [modal_static, setModal_static] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [labelClass, setLabelClass] = useState("timeslotBtn");
  const [slotValue, setSlotValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [datedisabled, setDatedisabled] = useState(false);
  const navigate = useNavigate();

  useMemo(() => {
    if (slotValuePre) {
      setSlotValue(slotValuePre);
    }
  }, [slotValuePre]);

  useMemo(() => {
    if (dateValuePre) {
      setDateValue(dateValuePre);
    }
  }, [dateValuePre]);

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  const timeSlotFunc = (e) => {
    if (modal_static === true) {
      setLabelClass("timeslotBtn2");
    } else {
      setLabelClass("timeslotBtn");
    }
  };

  const tog_static = (value) => {
    setSlotValue(value);
    setModal_static(!modal_static);
    timeSlotFunc();
  };

  const resetButton = () => {
    setDateValue("");
    setSlotValue("");
    setDatedisabled(false);
  };

  const dateInputFunc = (e) => {
    var date = new Date();
    if (moment(e.target.value).toISOString() < moment(date).toISOString()) {
      return toast.error("Date should be greater then today date", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setDateValue(e.target.value);
    }
  };

  // ************Get Api OF  slots**********
  const getSlotsobj = useQuery(["getSlots"], () => getSlots(), {
    retry: 0,
  });
  const getSlotsobject = getSlotsobj?.data;
  // *****************  End***************

  // *****userpost data*******

  const scheduledSubmitFunc = () => {
    window.scrollTo(0, 0);

    localStorage.setItem("navActive", ["1", "2", "3", "4", "5"]);
    navigate("/membership/review-details", {
      state: {
        profileData: profileData,
        companyInfoPrev: companyInfoPrev,
        teamArrPre: teamArrPre,
        photoDataByApi: photoDataByApi,
        photoDataByApi1: photoDataByApi1,
        slotValuePre: slotValue,
        dateValuePre: dateValue,
        selectedFiles: selectedFiles,
        selectedFiles1: selectedFiles1,
      },
    });
  };

  const backBtnFunc = () => {
    localStorage.setItem("navActive", ["1", "2", "3"]);
    navigate("/membership/teamInfo", {
      state: {
        profileData: profileData,
        companyInfoPrev: companyInfoPrev,
        teamArrPre: teamArrPre,
        photoDataByApi: photoDataByApi,
        photoDataByApi1: photoDataByApi1,
        selectedFiles: selectedFiles,
        selectedFiles1: selectedFiles1,

        slotValuePre: slotValue,
        dateValuePre: dateValue,
      },
    });
  };

  return {
    datedisabled,
    setDatedisabled,
    labelClass,
    setLabelClass,
    modal_static,
    setModal_static,
    tog_static,
    modelShow,
    setModelShow,
    timeSlotFunc,
    getSlotsobject,
    scheduledSubmitFunc,
    dateInputFunc,
    dateValue,
    slotValue,
    backBtnFunc,
    resetButton,
  };
};
