import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { Input } from "reactstrap";
import { UseInfoEmail } from "./useInfoEmail";
import FormInput from "../../../components/molicules/formInputs";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import bottomPic from "../../../images/footers/footer-mobile.svg";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import { Link } from "react-router-dom";
import "./css/emailInfo.css";

const EmailInfoMembership = () => {
  const {
    emailFormate,
    sendButtonText,
    resendButtonEnabled,
    setResendButtonEnabled,
    emailInput,
    inputFunc,
    emailSubmitFunc,
    modelShow,
    setModelShow,
    verifyInputFunc,
    verifyInput,
    submitVerificationCodeFunc,
  } = UseInfoEmail();
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className='allCardsPadding'>
          <div className='ebgcolor p-4 '>
            <Row>
              <Col lg={10} md={10} sm={10} xs={10}>
                <span className='emainheading'>Email Information</span>
                <p className='mailmainpheading2'>
                  Please provide the information below
                </p>
              </Col>

              <Col lg={2} md={2} sm={2} xs={2}>
                <div className='icon-box'>
                  <span onClick={() => setModelShow(true)}>
                    <img src={CloseIcon} alt='logo' width={24} height={24} />
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={10} md={10} sm={10} xs={10}>
                <p className='emainheadingMobView my-2 '>Membership</p>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Card className='p-3 form-card'>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <span className='ecardheadings'>Step 1</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='mt-3'>
                      <span className='ecardheadingss'>Email address</span>
                      <p className='ecardheadings2'>
                        Please provide your email address
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <FormInput
                        name='fName'
                        placeholder='Enter Your Email'
                        onChange={(e) => {
                          inputFunc(e);
                        }}
                        errorMessage='Please enter email address'
                        required={true}
                        type='email'
                        validate='required'
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xl={2}
                      lg={2}
                      md={2}
                      sm={3}
                      xs={3}
                      className='mt-3'
                    ></Col>
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={3}
                      xs={3}
                      className='mt-3 pl-4'
                    >
                      {resendButtonEnabled ? (
                        <CountdownCircleTimer
                          isPlaying
                          duration={60}
                          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                          colorsTime={[7, 5, 2, 0]}
                          size={40}
                          strokeWidth={4}
                          strokeLinecap='butt'
                          // trailColor='black'
                          onComplete={() => {
                            setResendButtonEnabled(false);
                          }}
                        >
                          {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                      <Button
                        color='secondary'
                        className='ebutton1 w-100 mt-3 h-53 '
                        type='submit'
                        disabled={
                          !emailInput || resendButtonEnabled || emailFormate
                        }
                        onClick={emailSubmitFunc}
                        // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                      >
                        {sendButtonText}
                      </Button>
                    </Col>
                  </Row>

                  <div>
                    <Row className='mt-3'>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <span className='ecardheadings'>Step 2</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12} className='mt-3'>
                        <span className='ecardheadingss'>
                          Verification code
                        </span>
                        <p className='ecardheadings2'>
                          Please provide code sent to your email address
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Input
                          placeholder='Enter Verification Code'
                          onChange={(e) => verifyInputFunc(e)}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              submitVerificationCodeFunc();
                            }
                          }}
                          type='number'
                          min={0}
                          className='emailinputs'
                          // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                        />
                      </Col>
                    </Row>

                    <Row className='mb-5'>
                      <Col xl={3} lg={3} md={3} sm={6} xs={6}></Col>

                      <Col xl={3} lg={3} md={6} sm={6} xs={6} className='btn-p'>
                        <Button
                          color='secondary'
                          onClick={submitVerificationCodeFunc}
                          disabled={!verifyInput}
                          className='ebutton1 w-100 mt-3 h-53'
                        >
                          Proceed
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <img src={bottomPic} alt='img' className='bottomImg' />
          </Col>
        </Row>
      </Col>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />
    </Row>
  );
};

export default EmailInfoMembership;
// export {EmailAddress};
