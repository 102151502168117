import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "../../../APIS/profileInfo";
import { Suadi_fintech_URL } from "../../../config/url";
import { toast } from "react-toastify";
import axiosClient from "../../../config/axios";

const UseProfileInfo = () => {
  const navigate = useNavigate();
  const [modelShow, setModelShow] = useState(false);
  const state = useLocation();

  const profileDataFromNextPage = state?.state?.profileDataFromNextPage;
  const [profileInfo, setProfileInfo] = useState({
    linkedinName: "",
    twitterName: "",
  });

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  const email = toLowerCase(state?.state?.email);
  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
  };

  const linkedinNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, linkedinName: e.target.value });
  };
  const twitterNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, twitterName: e.target.value });
  };
  const phoneNumberFunc = (e) => {
    setProfileInfo({ ...profileInfo, phoneNumber: e });
  };
  const occupationNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, occupationName: e.target.value });
  };
  const employementStatusFunc = (e) => {
    setProfileInfo({ ...profileInfo, employmentStatus: e.value });
  };
  const pNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, phoneNum: e });
  };
  const lNameFunc = (e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^A-Za-z\s]/g, ""); // Filter out non-alphabetic characters except spaces
    setProfileInfo({ ...profileInfo, lastName: filteredInput });
  };
  const fNameFunc = (e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^A-Za-z\s]/g, ""); // Filter out non-alphabetic characters except spaces
    setProfileInfo({ ...profileInfo, firstName: filteredInput });
  };

  const setProfileData = (e) => {
    window.scrollTo(0, 0);

    const urlExpression = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );

    const twitterExpression = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?twitter\\.com\\/[A-Za-z0-9_]{1,15}$"
    );

    const linkedInExpression = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?linkedin\\.com\\/[A-Za-z0-9\\-]+(\\/[A-Za-z0-9\\-]+)?"
    );

    if (profileInfo?.firstName === undefined || profileInfo?.firstName === "") {
      return toast.error("First Name is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.lastName === undefined ||
      profileInfo?.lastName === ""
    ) {
      return toast.error("Last Name is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.phoneNum === undefined ||
      profileInfo?.phoneNum === "" ||
      profileInfo?.phoneNum?.length < 12
    ) {
      return toast.error("Invalid phone number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.employmentStatus === undefined ||
      profileInfo?.employmentStatus === ""
    ) {
      return toast.error("Employeement status is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.twitterName !== "" &&
      twitterExpression.test(profileInfo?.twitterName) === false
    ) {
      return toast.error("Twitter Link format is incorrect", {
        position: toast.POSITION.TOP_RIGHT,
      });

      // }
    } else if (
      profileInfo?.linkedinName !== "" &&
      linkedInExpression.test(profileInfo?.linkedinName) === false
    ) {
      return toast.error("LinkedIn Link format is incorrect", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      localStorage.setItem("navActive", ["1", "2"]);
      navigate("/day-pass/review-details", {
        state: {
          profileInfo: profileInfo,
          email: toLowerCase(email),
        },
      });
    }
  };

  // ************Get Api OF Pofile using email**********
  const { data: getProfileobj } = useQuery(
    ["profileInfoUsingEmail", email],
    () => getProfile(email),
    {
      retry: 0,
    }
  );
  const getProfileobject = getProfileobj?.res;

  // *****************  End***************

  useEffect(() => {
    !profileDataFromNextPage &&
      getProfileobject &&
      setProfileInfo({
        firstName: getProfileobject?.data?.firstName,
        lastName: getProfileobject?.data?.lastName,
        phoneNum: getProfileobject?.data?.phoneNumber,
        employmentStatus: getProfileobject?.data?.employmentStatus,
        occupationName:
          getProfileobject?.data?.occupation === undefined
            ? ""
            : getProfileobject?.data?.occupation,
        twitterName: getProfileobject?.data?.twitter
          ? getProfileobject?.data?.twitter
          : "",
        linkedinName: getProfileobject?.data?.linkedin
          ? getProfileobject?.data?.linkedin
          : "",
        userId: getProfileobject?.data?.id,
        userExists: getProfileobject?.data?.userExits,

        companyName: getProfileobject?.data?.companyName,
      });
  }, [getProfileobject]);

  useMemo(() => {
    profileDataFromNextPage &&
      setProfileInfo({
        firstName: profileDataFromNextPage?.firstName,
        lastName: profileDataFromNextPage?.lastName,
        phoneNum: profileDataFromNextPage?.phoneNum,
        employmentStatus: profileDataFromNextPage?.employmentStatus,
        occupationName:
          profileDataFromNextPage?.occupationName === undefined
            ? ""
            : profileDataFromNextPage?.occupationName,
        twitterName: profileDataFromNextPage?.twitterName
          ? profileDataFromNextPage?.twitterName
          : "",
        linkedinName: profileDataFromNextPage?.linkedinName
          ? profileDataFromNextPage?.linkedinName
          : "",
        userId: profileDataFromNextPage?.userId,
        userExists: profileDataFromNextPage?.userExists,

        companyName: profileDataFromNextPage?.companyName,
      });
  }, [profileDataFromNextPage]);

  return {
    phoneNumberFunc,
    fNameFunc,
    lNameFunc,

    pNameFunc,
    employementStatusFunc,
    occupationNameFunc,
    twitterNameFunc,
    linkedinNameFunc,
    handelSubmit,
    modelShow,
    setModelShow,
    profileInfo,
    setProfileInfo,
    setProfileData,
    getProfileobject,
    email,
  };
};

export { UseProfileInfo };
