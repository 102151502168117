import React from "react";
import { Row, Col, Button, Container } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import NavItem from "../../../images/tournav/tourNav.svg";
import "./css/tournav.css";

const MembershipNav = () => {
  const navActive = localStorage.getItem("navActive");
  const location = useLocation();

  return (
    <Row className='tr-maindiv '>
      <Col md={12}>
        <Container>
          <Row className='p-3'>
            <Col md={12}>
              <p className='ttitle'>MEMBERSHIP</p>
            </Col>
          </Row>
          <Row className='p-3'>
            <Col md={12}>
              <SimpleBar className='simple_bar_height'>
                <>
                  <div>
                    <span>
                      <Button
                        outline
                        disabled={true}
                        color='success'
                        className={`waves-effect btn-circle ${
                          location.pathname === "/membership/emailInfo"
                            ? "roundedCircleActive"
                            : "roundedCircle"
                        } ${navActive?.includes("1") && "activeNav"}`}
                      >
                        <span
                          className={`${
                            location.pathname === "/membership/emailInfo"
                              ? "rounded-btn-text-Active"
                              : "rounded-btn-text"
                          }`}
                        >
                          1
                        </span>
                      </Button>
                    </span>
                    <span>
                      <small
                        className={` ml-3 ${
                          location.pathname === "/membership/emailInfo"
                            ? "text-muted-colored"
                            : "text-muted"
                        } ${navActive?.includes("1") && "doneText"}`}
                      >
                        Enter Email
                      </small>
                    </span>
                  </div>

                  <div
                    className={`${
                      location.pathname === "/membership/emailInfo"
                        ? "dottedlinActive"
                        : "dottedlin"
                    } ${navActive?.includes("1") && "dottedlinDone"}`}
                  >
                    {" "}
                  </div>
                </>
                {/* } */}
                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/membership/profileInfo"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("2") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/membership/profileInfo"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        2
                      </span>
                    </Button>
                  </span>
                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/membership/profileInfo"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("2") && "doneText"}`}
                    >
                      Profile Information
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/membership/profileInfo"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("2") && "dottedlinDone"}`}
                >
                  {" "}
                </div>

                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/membership/companyInfo"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("3") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/membership/companyInfo"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        3
                      </span>
                    </Button>
                  </span>

                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/membership/companyInfo"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("3") && "doneText"}`}
                    >
                      Company Information
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/membership/companyInfo"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("3") && "dottedlinDone"}`}
                >
                  {" "}
                </div>
                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/membership/teamInfo"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("4") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/membership/teamInfo"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        4
                      </span>
                    </Button>
                  </span>

                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/membership/teamInfo"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("4") && "doneText"}`}
                    >
                      Team Information
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/membership/teamInfo"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("4") && "dottedlinDone"}`}
                >
                  {" "}
                </div>

                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/membership/scheduleMemberInfo"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("5") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/membership/scheduleMemberInfo"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        5
                      </span>
                    </Button>
                  </span>

                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/membership/scheduleMemberInfo"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("5") && "doneText"}`}
                    >
                      Schedule Membership
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/membership/scheduleMemberInfo"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("5") && "dottedlinDone"}`}
                >
                  {" "}
                </div>

                {/* ********** 6 for review starts *********  */}
                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/membership/review-details"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("6") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/membership/review-details"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        6
                      </span>
                    </Button>
                  </span>

                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/membership/review-details"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("6") && "doneText"}`}
                    >
                      Review Details
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/membership/review-details"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("6") && "dottedlinDone"}`}
                >
                  {" "}
                </div>
                {/* ********** 6 for review end *********  */}

                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/membership/applicationSubmitted"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("7") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname ===
                          "/membership/applicationSubmitted"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        7
                      </span>
                    </Button>
                  </span>

                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/membership/applicationSubmitted"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("6") && "doneText"}`}
                    >
                      Application Submitted
                    </small>
                  </span>
                </div>
              </SimpleBar>
            </Col>
          </Row>
        </Container>

        <div className='w-100'>
          <img src={NavItem} alt='img' className='member_Nav_Img' />
        </div>
      </Col>
    </Row>
  );
};

export default MembershipNav;
