import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Row } from "reactstrap";
import MembershipNav from "./membershipNav";
import "./css/tours.css";

const Membership = () => {
  return (
    <div className='scroller'>
      <Row className='mt-3'>
        <Col md={3} xl={3} lg={3}>
          <MembershipNav />
        </Col>
        <Col md={9} xl={9} lg={9} className='fullHight'>
          <div className='mainContainer'>
            <Outlet />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Membership;
