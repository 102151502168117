import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosClient from "../../../config/axios";

export const UseInfoEmail = () => {
  const navigate = useNavigate();

  const [resendButtonEnabled, setResendButtonEnabled] = useState(false);
  const [userId1, setUserId1] = useState("");
  const [sendButtonText, setSendButtonText] = useState("Send Code");
  const [emailInput, setEmailInput] = useState("");
  const [verifyInput, setVerifyInput] = useState("");
  const [emailFormate, setEmailFormate] = useState(true);
  const [modelShow, setModelShow] = useState(false);
  const [verificaitionEnablbe, setVerificaitionEnablbe] = useState(false);
  var errorTrue = false;

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(emailInput)) {
      setEmailFormate(true);
    } else {
      setEmailFormate(false);
    }
  }, [emailInput]);

  const inputFunc = (e) => {
    setEmailInput(e.target.value);
  };

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  const emailSubmitFunc = () => {
    setSendButtonText("Resend Code");
    setResendButtonEnabled(true);

    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(emailInput)) {
      return toast.error("Please Enter Correct format(abc@xyz.com)", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let data = JSON.stringify({
        email: toLowerCase(emailInput),
      });

      var config = {
        method: "put",
        url: "/users/create-otp",
        headers: {
          Access: "*/*",
          //   Content-Type : "Application/json"
          //   Authorization: ` ${bariertoken}`,
        },
        data: data,
      };

      axiosClient(config)
        .then(function (response) {
          if (response?.status === 200) {
            setUserId1(response?.data?.userId);
            return toast.success(
              "Verification Code is sent to your Email Address",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          } else {
            return toast.error(response?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          return toast.error(error?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  const verifyInputFunc = (e) => {
    setVerifyInput(e.target.value);
  };

  useEffect(() => {
    if (verifyInput.length >= 4) {
      setTimeout(() => {
        submitVerificationCodeFunc();
        // Make API call after 4 characters
      }, 500);
    }
  }, [verifyInput]);

  const submitVerificationCodeFunc = () => {
    window.scrollTo(0, 0);

    let data = JSON.stringify({
      otp: Number(verifyInput),
      id: `${userId1}`,
    });

    var config = {
      method: "post",
      url: "/users/otp-verify",
      headers: {
        Access: "*/*",
        //   Content-Type : "Application/json"
        //   Authorization: ` ${bariertoken}`,
      },
      data: data,
    };

    axiosClient(config)
      .then(function (response) {
        if (response?.status === 200) {
          errorTrue = false;

          localStorage.setItem("navActive", "1");
          localStorage.setItem("userEmail", emailInput);
          localStorage.setItem("userId", userId1);

          navigate("/membership/profileInfo", {
            state: { email: emailInput, userId: userId1 },
          });
        } else {
          if (errorTrue === false) {
            errorTrue = true;
            return toast.error(response?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      })

      .catch(function (error) {
        if (errorTrue === false) {
          errorTrue = true;
          return toast.error(error?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  return {
    emailInput,
    emailFormate,
    setEmailInput,
    inputFunc,
    emailSubmitFunc,
    verificaitionEnablbe,
    verifyInputFunc,
    verifyInput,
    modelShow,
    setModelShow,
    submitVerificationCodeFunc,
    resendButtonEnabled,
    sendButtonText,
    setSendButtonText,
    setResendButtonEnabled,
  };
};
