import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Button } from "reactstrap";
import { UseApplicationSubmit } from "./useApplicationSubmit";
import ApplicationSubmit from "../../.././images/icons/applicationSubmit.svg";
import bottomPic from "../../../images/footers/footer-mobile.svg";
import "./css/applicationSubmitted.css";

const ApplicationSubmitted = () => {
  const { applicationSubmit } = UseApplicationSubmit();

  return (
    <div className='ebgcolor p-4'>
      <div className='allCardsPadding'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card className='p-5 mt-3 form-card asCardAlign'>
              <Row className='mt-2 mb-2'>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                  <img src={ApplicationSubmit} alt='img' />
                </Col>
                <Col lg={8} md={8} sm={12} xs={12}>
                  <Row className='mt-0 mb-1'>
                    <Col lg={1} md={1} sm={2} xs={2}></Col>
                    <Col xl={11} lg={11} md={11} sm={11} xs={11} className=''>
                      <p className='asTitle'> Application Submitted</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={1} md={1} sm={2} xs={2}></Col>
                    <Col
                      xl={11}
                      lg={11}
                      md={11}
                      sm={11}
                      xs={11}
                      className='asSubTitle'
                    >
                      <p>Your tour request has been successfully submitted.</p>
                      <p className='asSubtitle23'>
                        The requests are processed in the order they are
                        received, you’ll be emailed as soon as there is a status
                        update to your application
                      </p>

                      <Row className='mt-5'>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <Link to='/tours/emailInfo'>
                            <Button
                              outline
                              color='secondary'
                              onClick={applicationSubmit}
                              className=' w-100 mt-3'
                            >
                              Book Another Tour
                            </Button>
                          </Link>
                        </Col>
                        <Col lg={5} md={5} sm={12} xs={12}>
                          <Link to='/wellcome'>
                            <Button
                              color='secondary'
                              onClick={applicationSubmit}
                              className='ehome w-100 mt-3'
                            >
                              Home
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className='mt-2 mb-2'>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  {/* <Link to='/tours/emailInfo'>
                    <Button
                      color='secondary'
                      onClick={applicationSubmit}
                      className='ebutton1 w-100 mt-3'
                    >
                      Book Another Tour
                    </Button>
                  </Link> */}
                </Col>
                <Col lg={4} md={4} sm={4} xs={4}></Col>
              </Row>
            </Card>
            {/* <img src={ApplicationSubmit} alt="img" /> */}
          </Col>
        </Row>
      </div>

      <footer>
        <img src={bottomPic} alt='img' className='bottomImg' />
      </footer>
    </div>
  );
};

export default ApplicationSubmitted;
