import { useEffect, useMemo } from "react";

export const UseApplicationSubmit = () => {
  const applicationSubmit = () => {
    localStorage.setItem("navActive", "");
  };

  useEffect(() => {
    // Prevent user from going back to previous page using the browser back button
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);
  return {
    applicationSubmit,
  };
};
