import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "../../../APIS/profileInfo";
import { Suadi_fintech_URL } from "../../../config/url";
import { toast } from "react-toastify";
import axiosClient from "../../../config/axios";

const UseProfileInfo = () => {
  const navigate = useNavigate();
  const state1 = useLocation();
  const state = useLocation();

  const {
    slotValuePre,
    dateValuePre,
    profileData,
    companyInfoPrev,
    photoDataByApiprev,
    photoDataByApi1prev,
    teamArrPre,
    selectedFiles,
    selectedFiles1
  } = state1?.state;

  const [modelShow, setModelShow] = useState(false);

  const [profileInfo, setProfileInfo] = useState({
    linkedinName: "",
    twitterName: "",
  });

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  const email = toLowerCase(state?.state?.email);

  const userId = localStorage.getItem("userId");

  const UserEmailNoFormat = localStorage.getItem("userEmail");
  const userEmail = toLowerCase(UserEmailNoFormat);

  useMemo(() => {
    setProfileInfo(profileData);
  }, []);

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
  };

  const linkedinNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, linkedinName: e.target.value });
  };
  const twitterNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, twitterName: e.target.value });
  };
  const occupationNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, occupationName: e.target.value });
  };
  const employementStatusFunc = (e) => {
    setProfileInfo({ ...profileInfo, employmentStatus: e.value });
  };
  const phoneNumberFunc = (e) => {
    setProfileInfo({ ...profileInfo, phoneNum: e });
  };
  const pNameFunc = (e) => {
    // setProfileInfo({ ...profileInfo, phoneNum: e });
  };
  const lNameFunc = (e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^A-Za-z\s]/g, ""); // Filter out non-alphabetic characters except spaces
    setProfileInfo({ ...profileInfo, lastName: filteredInput });
  };
  const fNameFunc = (e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^A-Za-z\s]/g, ""); // Filter out non-alphabetic characters except spaces
    setProfileInfo({ ...profileInfo, firstName: filteredInput });
  };

  const companyNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, companyName: e.target.value });
  };

  const employementWorkTitleFunc = (e) => {
    const input = e.target.value;
    const filteredInput = input.replace(/[^A-Za-z\s]/g, ""); // Filter out non-alphabetic characters except spaces
    setProfileInfo({ ...profileInfo, workTitle: filteredInput });
  };

  const setProfileData = (e) => {
    window.scrollTo(0, 0);

    const urlExpression = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );

    const twitterExpression = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?twitter\\.com\\/[A-Za-z0-9_]{1,15}$"
    );

    const linkedInExpression = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?linkedin\\.com\\/[A-Za-z0-9\\-]+(\\/[A-Za-z0-9\\-]+)?"
    );
    if (profileInfo?.firstName === undefined || profileInfo?.firstName === "") {
      return toast.error("First Name is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.lastName === undefined ||
      profileInfo?.lastName === ""
    ) {
      return toast.error("Last Name is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.phoneNum === undefined ||
      profileInfo?.phoneNum === "" ||
      profileInfo?.phoneNum?.length < 12
    ) {
      return toast.error("Invalid phone number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.employmentStatus === undefined ||
      profileInfo?.employmentStatus === ""
    ) {
      return toast.error("Employeement status is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.workTitle === undefined ||
      profileInfo?.workTitle === ""
    ) {
      return toast.error("Work Title is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // else if (
    //   profileInfo?.twitterName !== "" &&
    //   twitterExpression.test(profileInfo?.twitterName) === false
    // ) {
    //   return toast.error("Twitter Link format is incorrect", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });

    // } else if (
    //   profileInfo?.linkedinName !== "" &&
    //   linkedInExpression.test(profileInfo?.linkedinName) === false
    // ) {
    //   return toast.error("LinkedIn Link format is incorrect", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    else {
      localStorage.setItem("navActive", ["1", "2"]);

      var config = {
        method: "PATCH",
        url: `${Suadi_fintech_URL}/users/${userId}`,
        headers: {
          Access: "*/*",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: JSON.stringify({
          firstName: `${profileInfo?.firstName}`,
          lastName: `${profileInfo?.lastName}`,
          phoneNumber: `${profileInfo?.phoneNum}`,
          employmentStatus: `${profileInfo?.employmentStatus}`,
          // occupation: `${
          //   profileInfo?.occupationName === undefined || ""
          //     ? ""
          //     : profileInfo?.occupationName
          // }`,
          twitter: `${
            profileInfo?.twitterName ? profileInfo?.twitterName : ""
          }`,
          linkedin: `${
            profileInfo?.linkedinName ? profileInfo?.linkedinName : ""
          }`,
          workTitle: `${profileInfo?.workTitle}`,
        }),
      };

      axiosClient(config)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("companyId", profileInfo?.companyId);
            localStorage.setItem("navActive", ["1", "2"]);
            navigate("/membership/companyInfo", {
              state: {
                // profileData: profileInfo,
                // userEmail: email,
                userEmail: toLowerCase(email),

                profileData: profileInfo,
                companyInfoPrev: companyInfoPrev,
                teamArrPre: teamArrPre,
                photoDataByApiprev: photoDataByApiprev,
                photoDataByApi1prev: photoDataByApi1prev,
                selectedFiles: selectedFiles,
                selectedFilesPrev:selectedFiles,
                selectedFilesPrev1:selectedFiles1,
                slotValuePre: slotValuePre,
                dateValuePre: dateValuePre,
              },
            });
          } else {
            return toast.error(res?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
    // }
  };

  // ************Get Api OF Pofile using email**********
  const { data: getProfileobj } = useQuery(
    ["profileInfoUsingEmail", userEmail],
    () => getProfile(userEmail),
    {
      retry: 0,
    }
  );
  const getProfileobject = getProfileobj?.res;
  // *****************  End***************

  useEffect(() => {
    getProfileobject &&
      setProfileInfo({
        firstName: getProfileobject?.data?.firstName,
        lastName: getProfileobject?.data?.lastName,
        phoneNum: getProfileobject?.data?.phoneNumber,
        employmentStatus: getProfileobject?.data?.employmentStatus,
        // occupationName:
        //   getProfileobject?.data?.occupation === undefined
        //     ? ""
        //     : getProfileobject?.data?.occupation,
        twitterName: getProfileobject?.data?.twitter
          ? getProfileobject?.data?.twitter
          : "",
        linkedinName: getProfileobject?.data?.linkedin
          ? getProfileobject?.data?.linkedin
          : "",
        userExists: getProfileobject?.data?.userExits,
        companyName: getProfileobject?.data?.companyName,
        workTitle: getProfileobject?.data?.workTitle,
        companyId: getProfileobject?.data?.companyId,
      });
  }, [getProfileobject]);

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    phoneNumberFunc,
    fNameFunc,
    lNameFunc,
    pNameFunc,
    employementStatusFunc,
    occupationNameFunc,
    twitterNameFunc,
    linkedinNameFunc,
    modelShow,
    setModelShow,
    companyNameFunc,
    handelSubmit,
    profileInfo,
    setProfileInfo,
    setProfileData,
    getProfileobject,
    employementWorkTitleFunc,
    email,
  };
};

export { UseProfileInfo };
