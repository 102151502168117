import React from "react";
import { Row, Col, Button, Container } from "reactstrap";
import SimpleBar from "simplebar-react";
import NavItem from "../../../images/tournav/tourNav.svg";
import { useLocation } from "react-router-dom";
import "./css/tournav.css";

const DayPassNav = () => {
  const navActive = localStorage.getItem("navActive");
  const location = useLocation();

  return (
    <div className="tr-maindiv">
      <Container>
        <Row className="p-3">
          <Col md={12}>
            <p className="ttitle">DAY PASS</p>
          </Col>
        </Row>
        <Row className="p-3">
          <Col md={12}>
            <SimpleBar style={{ maxHeight: "330px" }}>
              <>
                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color="success"
                      className={`waves-effect btn-circle ${
                        location.pathname === "/day-pass/emailInfo"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("1") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/day-pass/emailInfo"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        1
                      </span>
                    </Button>
                  </span>
                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/day-pass/emailInfo"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("1") && "doneText"}`}
                    >
                      Enter Email
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/day-pass/emailInfo"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("1") && "dottedlinDone"}`}
                >
                  {" "}
                </div>
              </>
              {/* } */}
              <div>
                <span>
                  <Button
                    outline
                    disabled={true}
                    color="success"
                    className={`waves-effect btn-circle ${
                      location.pathname === "/day-pass/profileInfo"
                        ? "roundedCircleActive"
                        : "roundedCircle"
                    } ${navActive?.includes("2") && "activeNav"}`}
                  >
                    <span
                      className={`${
                        location.pathname === "/day-pass/profileInfo"
                          ? "rounded-btn-text-Active"
                          : "rounded-btn-text"
                      }`}
                    >
                      2
                    </span>
                  </Button>
                </span>
                <span>
                  <small
                    className={` ml-3 ${
                      location.pathname === "/day-pass/profileInfo"
                        ? "text-muted-colored"
                        : "text-muted"
                    } ${navActive?.includes("2") && "doneText"}`}
                  >
                    Profile Information
                  </small>
                </span>
              </div>

              <div
                className={`${
                  location.pathname === "/day-pass/profileInfo"
                    ? "dottedlinActive"
                    : "dottedlin"
                } ${navActive?.includes("2") && "dottedlinDone"}`}
              >
                {" "}
              </div>

              {/* ******** this 3 from review datail start **** */}

              <div>
                <span>
                  <Button
                    outline
                    disabled={true}
                    color="success"
                    className={`waves-effect btn-circle ${
                      location.pathname === "/day-pass/review-details"
                        ? "roundedCircleActive"
                        : "roundedCircle"
                    } ${navActive?.includes("3") && "activeNav"}`}
                  >
                    <span
                      className={`${
                        location.pathname === "/day-pass/review-details"
                          ? "rounded-btn-text-Active"
                          : "rounded-btn-text"
                      }`}
                    >
                      3
                    </span>
                  </Button>
                </span>
                <span>
                  <small
                    className={` ml-3 ${
                      location.pathname === "/day-pass/review-details"
                        ? "text-muted-colored"
                        : "text-muted"
                    } ${navActive?.includes("3") && "doneText"}`}
                  >
                    Review Details
                  </small>
                </span>
              </div>

              <div
                className={`${
                  location.pathname === "/day-pass/review-details"
                    ? "dottedlinActive"
                    : "dottedlin"
                } ${navActive?.includes("3") && "dottedlinDone"}`}
              >
                {" "}
              </div>

              {/* ******** this 3 from review datail end **** */}

              <div>
                <span>
                  <Button
                    outline
                    disabled={true}
                    color="success"
                    className={`waves-effect btn-circle ${
                      location.pathname === "/day-pass/applicationSubmitted"
                        ? "roundedCircleActive"
                        : "roundedCircle"
                    } ${navActive?.includes("4") && "activeNav"}`}
                  >
                    <span
                      className={`${
                        location.pathname === "/day-pass/applicationSubmitted"
                          ? "rounded-btn-text"
                          : "rounded-btn-text"
                      }`}
                    >
                      4
                    </span>
                  </Button>
                </span>

                <span>
                  <small
                    className={` ml-3 ${
                      location.pathname === "/day-pass/applicationSubmitted"
                        ? "text-muted-colored"
                        : "text-muted"
                    } ${navActive?.includes("4") && "doneText"}`}
                  >
                    Application Submitted
                  </small>
                </span>
              </div>
            </SimpleBar>
          </Col>
        </Row>
      </Container>

      <div>
        <img src={NavItem} alt="img" className="tourImg" />
      </div>
    </div>
  );
};

export default DayPassNav;
