import { useContext, useMemo } from "react";
import { useEffect } from "react";
import { NavContext } from "../../../context/NavContext";

export const UseApplicationSubmit = () => {
  // const { setNavActive } = useContext(NavContext);

  useEffect(() => {
    // Prevent user from going back to previous page using the browser back button
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  const applicationSubmit = () => {
    localStorage.setItem("navActive", "");
  };
  return {
    applicationSubmit,
  };
};
