import { useState } from "react";
import { Input } from "reactstrap";
import moment from "moment";
import "./css/formInput.css";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);

  const handelFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className='formInput'>
      <Input
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        required={props.required}
        onBlur={handelFocus}
        focused={focused.toString()}
        className='inputs forminputsize'
        type={props.type}
        value={props.value}
        maxLength={props.maxLength}
        allowInputToggle={true}
        disabled={props.disabled}
        minDate={new Date()}
        min={props.min}
      />

      <span className='inputerror'>{props.errorMessage}</span>
    </div>
  );
};

export default FormInput;
