import React from "react";
import { Button, Card, Col, Row, Form, Label, Progress } from "reactstrap";
import FormInput from "../../../components/molicules/formInputs";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import Select from "react-select";
import bottomPic from "../../../images/footers/footer-mobile.svg";
import Dropzone from "react-dropzone";
import { UseCompanyInfo } from "./useCompanyInfo";
import UploadLogo from "../../.././images/icons/img_upload.svg";
import UploadDocsLogo from "../../.././images/icons/docs_upload.svg";
import filePdfSolid from "../../.././images/icons/file-pdf-solid.svg";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import { Link } from "react-router-dom";
import "./css/profile.css";

const options = [
  { value: "Idea", label: "Idea" },
  { value: "Pre-commercial", label: "Pre-commercial" },
  { value: "Operational", label: "Operational" },
  { value: "Testing License", label: "Testing License" },
];

const optionsStatus = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

const Company = () => {
  const {
  
    uploadProgress1,
    uploadProgress,
    photoDataByApi,
    requireRegisAndVat,
    setCompanyInfo,
    operationalStatusFunc,
    registrationStatusFunc,
    webSiteFunc,
    companyDescriptionFunc,
    selectedFiles1,
    photoDataByApi1,
    operationalStatus,
    setSelectedFiles1,
    setSelectedFiles,
    setPhotoDataByApi1,
    setPhotoDataByApi,
    handleAcceptedFiles1,
    selectedFiles,
    handleAcceptedFiles,
    linkedin,
    pNameFunc,
    twitterNameFunc,
    linkedinNameFunc,
    companyNameFunc,
    handelSubmit,
    companyInfo,
    setModelShow,
    modelShow,
    emailAddressFunc,
    setCompanyDataFunc,
    backBtnFunc,
  } = UseCompanyInfo();

  return (
    <div className="ebgcolor  p-4">
      <div className="allCardsPadding">
        <Row>
          <Col lg={10} md={10} sm={10} xs={10}>
            <span className="emainheading">
              <Link to="/membership/profileInfo">
                {" "}
                <img
                  src={backArrow}
                  alt="arrow"
                  className="arrow_small_screen"
                />
              </Link>
              Company Information
            </span>
            <p className="mailmainpheading2">
              Please provide the information below
            </p>
          </Col>

          <Col lg={2} md={2} sm={2} xs={2}>
            <div className="icon-box">
              <span onClick={() => setModelShow(true)}>
                <img src={CloseIcon} alt="logo" width={24} height={24} />
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card className="p-4 form-card">
              <Row>
                <Col lg={10} md={10} sm={10} xs={10}>
                  <p className="emainheadingMobView my-2 ">Membership</p>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <span className="pcardheadings">Company Information</span>
                </Col>
              </Row>
              <form onSubmit={handelSubmit}>
                <Row className="mt-3">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Company Name <span className="pStaricColor">*</span>
                    </p>
                    <FormInput
                      value={companyInfo?.companyName}
                      name="fName"
                      onChange={(e) => {
                        companyNameFunc(e);
                      }}
                      errorMessage="Required"
                      required={true}
                      placeholder="e.g. DevBeans Technologies"
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Operational Status <span className="pStaricColor">*</span>
                    </p>

                    <Select
                      placeholder="Operational status"
                      value={operationalStatus}
                      options={options}
                      className="pi_Input"
                      isMulti={false}
                      onChange={(e) => {
                        operationalStatusFunc(e);
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Registration Status{" "}
                      <span className="pStaricColor">*</span>
                    </p>

                    <Select
                      placeholder="e.g. Registration Status"
                      value={{
                        value: companyInfo?.registrationStatus,
                        label: companyInfo?.registrationStatus,
                      }}
                      styles={{ height: "500px" }}
                      className="pi_Input"
                      options={optionsStatus}
                      isMulti={false}
                      onChange={(e) => {
                        registrationStatusFunc(e);
                      }}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Email Address <span className="pStaricColor">*</span>
                    </p>

                    <FormInput
                      value={companyInfo?.emailAddress}
                      placeholder="e.g. contact@devbeans.io"
                      name="pName"
                      onChange={(e) => {
                        emailAddressFunc(e);
                      }}
                      required={false}
                      type="email"
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Commercial Registration Number
                      {requireRegisAndVat ? (
                        <span className="pStaricColor">*</span>
                      ) : (
                        ""
                      )}
                    </p>

                    <FormInput
                      value={companyInfo?.commercialRegister}
                      placeholder="e.g. 67589409587"
                      name="commercialRegister"
                      onChange={(e) => {
                        setCompanyInfo({
                          ...companyInfo,
                          commercialRegister: e?.target?.value,
                        });
                      }}
                      min={0}
                      required={requireRegisAndVat}
                      errorMessage="Required"
                      type="number"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      VAT Number{" "}
                      {requireRegisAndVat ? (
                        <span className="pStaricColor">*</span>
                      ) : (
                        ""
                      )}
                    </p>
                    <FormInput
                      value={companyInfo.vatNumber}
                      placeholder="e.g. 45678543675"
                      name="VATNumber"
                      onChange={(e) => {
                        setCompanyInfo({
                          ...companyInfo,
                          vatNumber: e?.target?.value,
                        });
                      }}
                      min={0}
                      required={requireRegisAndVat}
                      errorMessage="Required"
                      type="number"
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <p className="pInputHeadings">Company Address</p>

                    <FormInput
                      value={companyInfo?.companyAddress}
                      placeholder="KAFD, Riyadh"
                      name="companyAddress"
                      onChange={(e) => {
                        setCompanyInfo({
                          ...companyInfo,
                          companyAddress: e?.target?.value,
                        });
                      }}
                      errorMessage="Required"
                      required={false}
                      // defaultValue={getProfileobject?.data?.phoneNumber}
                      // value={profileInfo?.phoneNum}
                      type="address"
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Company phone number{" "}
                      <span className="pStaricColor">*</span>
                    </p>

                    <PhoneInput
                      placeholder="e.g. +966 500 - 500 - 550"
                      // inputProps={{ maxLength: 20, readOnly: false }}
                      // placeholder="e.g. +966 500 - 500 - 550"
                      inputProps={{ maxLength: 16, readOnly: false }}
                      disableDropdown={true}
                      // countryCodeEditable={false}
                      country={"sa"}
                      name="pName"
                      containerClass="pi_PhoneInput"
                      inputStyle={{
                        // borderColor: "black",
                        borderBottomColor: "rgba(0, 0, 0, 0.5)",
                        borderTopColor: "rgba(0, 0, 0, 0.5)",
                        borderLeftColor: "rgba(0, 0, 0, 0.5)",
                        borderRightColor: "rgba(0, 0, 0, 0.5)",
                        width: "100%",
                        height: "56px",
                      }}
                      style={{ width: "100%", height: "56px" }}
                      value={companyInfo?.phoneNum}
                      onChange={(e) => {
                        pNameFunc(e);
                      }}
                      required={true}
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Website <span className="pStaricColor">*</span>
                    </p>
                    <FormInput
                      value={companyInfo?.webSite}
                      placeholder="e.g. http://devbeans.io"
                      name="pName"
                      onChange={(e) => {
                        webSiteFunc(e);
                      }}
                      errorMessage="Required"
                      required={true}
                      // defaultValue={getProfileobject?.data?.phoneNumber}
                      // value={profileInfo?.phoneNum}
                      type="text"
                    />
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <span className="pcardheadings">Socials</span>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}></Col>
                </Row>

                <Row className="mt-3">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">Twitter</p>
                    <FormInput
                      placeholder="twitter.com/username"
                      name="twitterName"
                      onChange={(e) => {
                        twitterNameFunc(e);
                      }}
                      required={false}
                      value={
                        companyInfo?.twitterName ? companyInfo?.twitterName : ""
                      }
                      //  defaultValue={getProfileobject?.data?.twitter}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">Linkedin</p>
                    <FormInput
                      placeholder="linkedin/in/username"
                      name="linkedinName"
                      onChange={(e) => {
                        linkedinNameFunc(e);
                      }}
                      required={false}
                      value={
                        companyInfo?.linkedinName
                          ? companyInfo?.linkedinName
                          : ""
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <span className="pcardheadings">Company description</span>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}></Col>
                </Row>

                <Row className="mt-3">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <FormInput
                      value={companyInfo?.companyDescription}
                      placeholder="e.g. Devbeans is an ode to entrepreneurs that dream big but are tired of the dull, uninspired and uneconomical product solutions that stand in the way of their goals. You’re here looking for a first-in-class web and app development partner. We’re here to tell you we’re the one."
                      name="companyInfo"
                      className="text-area"
                      onChange={(e) => {
                        companyDescriptionFunc(e);
                      }}
                      required={false}
                      type="textarea"
                      maxLength={700}

                      //  value={getProfileobject?.data?.linkedin}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form className="dropzone mt-5 ">
                      {
                      // photoDataByApi.length > 0 &&
                      // photoDataByApi.length !== 0 &&
                      // photoDataByApi[0].length !== 0
                      selectedFiles?.preview
                       ? (
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt="logo"
                                    src={selectedFiles?.preview}
                                  />
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                  <span className=" ml-5">
                                    {/* {"logo uploaded"} */}
                                  </span>
                                  <p className="mb-0"></p>
                                </Col>
                                <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                  <span
                                    onClick={() => {
                                      setSelectedFiles({});
                                      // setPhotoDataByApi([]);
                                    }}
                                  >
                                    <img
                                      src={CloseIcon}
                                      alt="logo"
                                      width={24}
                                      height={24}
                                    />
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </div>
                      ) : (
                        <Dropzone
                          accept={".png ,.jpeg,.jpg,.pdf"}
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div>
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />

                                <div className=" drop_logo_center">
                                  <img
                                    src={UploadLogo}
                                    alt="logo"
                                    width={55}
                                    height={68}
                                  />
                                </div>

                                <Label className=" drop_logo_center dropImg_h3 mt-3">
                                  Upload Logo
                                  {requireRegisAndVat ? (
                                    <span className="pStaricColor">*</span>
                                  ) : (
                                    ""
                                  )}
                                </Label>

                                <p className=" drop_logo_center dropImg_h1">
                                  You can drag and drop the files or
                                  <span
                                    className="dropImg_h11 mx-2"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Click Here
                                  </span>
                                  to browse
                                </p>
                                <p className=" drop_logo_center dropImg_h2">
                                  File formats: JPG, PDF, PNG
                                </p>
                                {uploadProgress > 0 && (
              <Progress
                value={uploadProgress}
                className="mt-3"
                animated
                color="primary"
                
                style={{ height: "13px" }} 
              >
                {uploadProgress.toFixed(2)}%
              </Progress>
            )}
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}
                    </Form>
                  </Col>

                  <Col md={6}>
                    <Form className="dropzone mt-5 ">
                      {
                    
                        // photoDataByApi1.length > 0 &&
                        // photoDataByApi1.length !== 0 &&
                      
                        // photoDataByApi1[0].length !== 0 
                        selectedFiles1?.preview
                        ? (
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={"pic"}
                                      src={
                                        // photoDataByApi1[0]
                                        selectedFiles1?.preview
                                         ? filePdfSolid : ""
                                      }
                                    />
                                  </Col>
                                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                    <span className=" ml-5 ">
                                      {/* {photoDataByApi1[0]
                                        ? "Uploaded Image"
                                        : ""} */}
                                    </span>
                                    <span className=" ml-5 "></span>
                                  </Col>
                                  <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                                    <span
                                      onClick={() => {
                                        setSelectedFiles1({});
                                        // setPhotoDataByApi1([]);
                                      }}
                                    >
                                      <img
                                        src={CloseIcon}
                                        alt="logo"
                                        width={24}
                                        height={24}
                                        className="mr-5"
                                      />
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </div>
                        ) : (
                          <Dropzone
                            // accept={".pdf"}
                            accept={".png ,.jpeg,.jpg"}
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles1(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div>
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />

                                  <div className=" drop_logo_center">
                                    <img
                                      src={UploadDocsLogo}
                                      alt="logo"
                                      width={55}
                                      height={68}
                                    />
                                  </div>

                                  <Label className=" drop_logo_center dropImg_h3 mt-3">
                                    Upload Supporting Documents
                                    {requireRegisAndVat ? (
                                      <span className="pStaricColor">*</span>
                                    ) : (
                                      ""
                                    )}
                                  </Label>
                                  <p className=" drop_logo_center dropImg_h1">
                                    You can drag and drop the files or
                                    <span
                                      className="dropImg_h11 mx-2 cursor-na"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Click Here
                                    </span>{" "}
                                    to browse
                                  </p>
                                  <p className=" drop_logo_center dropImg_h2">
                                    e.g. Company certificate, Proof of
                                    Incorporation
                                  </p>
                                  {uploadProgress1 > 0 && (      <Progress
                value={uploadProgress1}
                className="mt-3"
                animated
                color="primary"
                
                style={{ height: "13px" }} 
              >
                {uploadProgress1.toFixed(2)}%
              </Progress>)}
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        )
                      }
                    </Form>
                  </Col>
                </Row>

                <Row className="mt-4 mb-4">
                  <Col lg={6} md={6} sm={12} xs={12}></Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <Button
                      outline
                      color="secondary"
                      className="pButton w-100 mt-3 "
                      onClick={backBtnFunc}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col lg={3} md={3} sm={12} xs={12}>
                    <Button
                      // disabled={
                      //   !companyInfo?.companyName ||
                      //   !companyInfo?.emailAddress ||
                      //   !companyInfo?.phoneNum ||
                      //   !companyInfo?.webSite ||
                      //   (requireRegisAndVat
                      //     ? !companyInfo?.vatNumber ||
                      //       !companyInfo?.commercialRegister ||
                      //       photoDataByApi1[0].length !== 0 ||
                      //       photoDataByApi[0].length !== 0
                      //     : "")
                      // }
                      onClick={setCompanyDataFunc}
                      color="secondary"
                      className="ebutton1 w-100 mt-3"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <img src={bottomPic} alt="img" className="bottomImg" />
        </Col>
      </Row>
    </div>
  );
};

export default Company;
