import React from "react";
import { Button, Col, Row, Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";

import "./css/formInput.css";
import ModelImage from "../../images/icons/ModelImg.svg";

const CancelModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  <img src={ModelImage} alt='img' />
                </div>
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3>{props.header}</h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody'>
                  {" "}
                  <p>{props.body}</p>
                </div>{" "}
              </Col>
            </Row>
            <Row>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                <Button
                  className='w-100  modelBtn1'
                  onClick={() => props.setModelShow(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                {" "}
                {/* <Link to='/wellcome'> */}
                {props?.head === "feurop" ? (
                  <a href='/#/wellcome'>
                    <Button
                      onClick={() => {
                        props.setModelShow(false);
                        props.onClick();
                      }}
                      className='w-100  modelBtn2'
                    >
                      Yes
                    </Button>
                  </a>
                ) : (
                  <Link to={props.link}>
                    <Button
                      onClick={() => {
                        props.onClick();
                        props.setModelShow(false);
                      }}
                      className='w-100  modelBtn2'
                    >
                      Yes
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default CancelModel;
