import React, { createContext, useState } from "react";
import { ToastContainer } from "react-toastify";
// Tour
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  HashRouter,
} from "react-router-dom";
import Wellcome from "./wellcome/index.js";
import Header from "../components/verticalLayout/header/header.js";
import EmailInfo from "./tour/emailInfo/index.js";
import ProfileInfo from "./tour/profileInfo/index.js";
import ScheduleInfo from "./tour/scheduleTour/index.js";
import ApplicationSubmitted from "./tour/applicationSubmitted/index.js";
import Tours from "./tour/index.js";
import TourNav from "./tour/tourNav/index.js";
import { NavContext } from "../context/NavContext.js";
import ReviewDetailsTours from "./tour/reviewDetails/index.js";

// Day Pass Pages

import DayPass from "./dayPass/index.js";
import EmailInfoDayPass from "./dayPass/emailInfo/index.js";
import ProfileInfoDayPass from "./dayPass/profileInfo/index.js";
import ApplicationSubmittedDayPass from "./dayPass/applicationSubmitted/index.js";
import ReviewDetails from "./dayPass/reviewDetails/index.js";

// Membership Pages
import Membership from "./membership/index.js";
import EmailInfoMembership from "./membership/emailInfo/index.js";
import ProfileInfoMembership from "./membership/profileInfo/index.js";
import Company from "./membership/company/index.js";
import ScheduleMemberInfo from "./membership/scheduleMembership/index.js";
import ApplicationSubmittedMembership from "./membership/applicationSubmitted/index.js";
import TeamInfo from "./membership/team/index.js";
import ReschduledTour from "./tour/reschduledTour/index.js";
import RescheduleVerification from "./tour/reschduledTour/rescheduleVerification/index.js";
import ReviewDetailsMembership from "./membership/reviewDetails/index.js";

import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./privacyPolicyPage/index.js";

const EmailAddress1 = createContext();

const App = () => {
  const [navActive, setNavActive] = useState([]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Header />
      <EmailAddress1.Provider value={"9933"}>
        {/* <BrowserRouter> */}
        <HashRouter>
          <NavContext.Provider
            value={{
              navActive,
              setNavActive,
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate replace to="/wellcome" />} />
              <Route path="/wellcome" element={<Wellcome />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {/* {
    id: "10",
    path: "#/privacy-policy",
    element: PrivacyPolicy,
  } */}
              <Route path="/tours" element={<Tours />}>
                <Route
                  path="/tours/emailInfo"
                  element={
                    <EmailAddress1.Provider>
                      <EmailInfo />
                    </EmailAddress1.Provider>
                  }
                />

                <Route
                  path="/tours/review-details"
                  element={<ReviewDetailsTours />}
                />
                <Route path="/tours/profileInfo" element={<ProfileInfo />} />
                <Route path="/tours/scheduleInfo" element={<ScheduleInfo />} />
                <Route
                  path="/tours/applicationSubmitted"
                  element={<ApplicationSubmitted />}
                />
              </Route>
              <Route
                path="/reschduled-tour/:tourId"
                element={<ReschduledTour />}
              />
              <Route
                path="/reschedule-verification/:tourId/:userId"
                element={<RescheduleVerification />}
              />

              <Route path="/day-pass" element={<DayPass />}>
                <Route
                  path="/day-pass/review-details"
                  element={<ReviewDetails />}
                />
                <Route
                  path="/day-pass/emailInfo"
                  element={<EmailInfoDayPass />}
                />
                <Route
                  path="/day-pass/profileInfo"
                  element={<ProfileInfoDayPass />}
                />
                <Route
                  path="/day-pass/applicationSubmitted"
                  element={<ApplicationSubmittedDayPass />}
                />
              </Route>
              <Route path="/membership" element={<Membership />}>
                <Route
                  path="/membership/review-details"
                  element={<ReviewDetailsMembership />}
                />
                <Route
                  path="/membership/emailInfo"
                  element={<EmailInfoMembership />}
                />
                <Route
                  path="/membership/profileInfo"
                  element={<ProfileInfoMembership />}
                />
                <Route path="/membership/companyInfo" element={<Company />} />
                <Route
                  path="/membership/scheduleMemberInfo"
                  element={<ScheduleMemberInfo />}
                />
                <Route
                  path="/membership/applicationSubmitted"
                  element={<ApplicationSubmittedMembership />}
                />
                <Route path="/membership/TeamInfo" element={<TeamInfo />} />
                {/* <Route path="/day-pass/applicationSubmitted" element={<ApplicationSubmittedDayPass/>} /> */}
              </Route>
              <Route path="/dayPassNav" element={<TourNav />} />
              <Route path="/tourNav" element={<TourNav />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            </Routes>
          </NavContext.Provider>
        </HashRouter>
        {/* </BrowserRouter> */}
      </EmailAddress1.Provider>
    </React.Fragment>
  );
};

export default App;

export { EmailAddress1 };
