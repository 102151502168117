import React from "react";
import { Row, Col } from "reactstrap";
import { Navbar, Nav } from "reactstrap";
import Logo1 from "../../../images/icons/newNavbarLogo.svg";
import "./css/header.css";
import CancelModel from "../../molicules/cancelApplicationModel";
import { useState } from "react";

const Header = () => {
  const [modelShow, setModelShow] = useState(false);
  return (
    <Navbar
      style={{ zIndex: "1" }}
      variant='light'
      className='navbarcolor navbar'
      fixed='top'
    >
      <Row>
        <Col lg={1} md={1} sm={1} xs={1}></Col>
        <Col lg={11} md={11} sm={11} xs={11}>
          <Nav className='mr-auto' navbar href='#'>
            {/* <a href='/'> */}
            <div onClick={() => setModelShow(true)}>
              <img
                src={Logo1}
                alt='logo'
                className='logo_height'
                type='button'
              />
            </div>
            {/* </a> */}
          </Nav>
        </Col>
      </Row>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
        head='feurop'
      />
    </Navbar>
  );
};

export default Header;
