import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./css/formInput.css";
import ModelImage from "../../images/icons/ModelImg.svg";

const ReschduleModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  <img src={ModelImage} alt='Img' />
                </div>
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3>{props.header}</h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody'>
                  <p>{props.body}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                <Button
                  className='w-100  modelBtn1'
                  onClick={() => props.setModelShow(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                {/* <Link to='/wellcome'> */}
                <Link to={props.link}>
                  <Button onClick={props.onClick} className='w-100  modelBtn2'>
                    Reschedule
                  </Button>
                </Link>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default ReschduleModel;
