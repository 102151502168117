import { Suadi_fintech_URL } from "../config/url";
import axios from "axios";
// ***********fetch*************

// export const getProfile = async (email) => {

//   if (email) {
//     const res = await fetch(`${Suadi_fintech_URL}/users/${email}`, {
//       method: "GET",
//       redirect: "follow",
//     });
//     if (!res.ok) {
//       const resJson = await res.json();
//       throw new Error(resJson.error.message);
//     }
//     const data = await res.json();

//     return {
//       data,
//     };
//   }
// };

// **************axois**********

export const getProfile = async (email) => {
  if (email) {
    const res = await axios.get(`${Suadi_fintech_URL}/users/${email}`);
    // if (!res.ok) {
    //   const resJson = await res.json();
    //   throw new Error(resJson.error.message);
    // }
    // const data = await res.json();

    return {
      res,
    };
  }
};

// *********** reschdule user data

export const getProfileDataOfReschduleUser = async (TourId) => {
  if (TourId) {
    const res = await axios.get(`${Suadi_fintech_URL}/get-tours/${TourId}`);
    // if (!res.ok) {
    //   const resJson = await res.json();
    //   throw new Error(resJson.error.message);
    // }
    // const data = await res.json();

    return {
      res,
    };
  }
};
