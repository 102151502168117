import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Button, Spinner } from "reactstrap";
import FormInput from "../../../components/molicules/formInputs";
import { UseScheduledInfo } from "./useSchedule";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import bottomPic from "../../../images/footers/footer-mobile.svg";
import Calendar from "react-calendar";
import "./css/schdule.css";
import "react-calendar/dist/Calendar.css";

const ScheduleInfo = () => {
  const {
    navigate,
    profiledata,
    modelShow,
    setModelShow,
    tog_static,
    getSlotsobject,
    scheduledSubmitFunc,
    tourId,
    isPastDate,
    isDayBooked,
    isDateBooked,
    availableSlotsLoading,
    availableSlotsDays,
    dateValue,
    slotValue,
    dateInputFunc,
    reschduleTourFunc,
    dateForShow,
  } = UseScheduledInfo();

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0: Sunday, 6: Saturday
  };

  return (
    <>
      <div className='ebgcolor  p-4 '>
        <div className='allCardsPadding'>
          <Row className='mt-2'>
            <Col lg={10} md={10} sm={10} xs={10}>
              <span className='emainheading '>
                <div
                  onClick={() => {
                    navigate("/tours/profileInfo", {
                      state: { profileDataFromNextPage: profiledata },
                    });
                  }}
                >
                  {" "}
                  <img
                    src={backArrow}
                    alt='arrow'
                    className='arrow_small_screen'
                  />
                </div>
                Schedule
              </span>
              <p className='mailmainpheading2'>
                Please provide the information below
              </p>
            </Col>

            <Col lg={2} md={2} sm={2} xs={2}>
              <div className='icon-box'>
                <span onClick={() => setModelShow(true)}>
                  <img src={CloseIcon} alt='logo' width={24} height={24} />
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Card className='p-4 form-card'>
                <Row className='mt-2'>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className='sheading'>Select Tour Date</p>

                    {!availableSlotsLoading ? (
                      <Calendar
                        tileDisabled={({ date }) =>
                          isDateBooked(date, availableSlotsDays?.bookedDates) ||
                          isDayBooked(
                            date,
                            availableSlotsDays?.availableDays
                          ) ||
                          isPastDate(date)
                        }
                        className={"w-100 dayPassCalenderStyle"}
                        type='date'
                        value={dateForShow}
                        name='fName'
                        onChange={(e) => {
                          dateInputFunc(e);
                        }}
                        errorMessage='Required'
                        required={true}
                        minDate={new Date()}
                      />
                    ) : (
                      // <div className='d-flex justify-content-center align-items-center w-100 py-5 '>
                      //   <div>
                      //     <Spinner />
                      //   </div>
                      // </div>
                      ""
                    )}

                    {/* <FormInput
                      type='date'
                      value={dateForShow}
                      name='fName'
                      placeholder='Enter Your First Name'
                      onChange={(e) => {
                        dateInputFunc(e);
                      }}
                      errorMessage='Required'
                      required={true}
                      minValue={new Date()}
                      // min={new Date().toISOString().substr(0, 10)}
                    /> */}
                    {/* <p>Formatted date: {dateForShow}</p> */}
                  </Col>
                </Row>

                <Row className='mt-5'>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    {getSlotsobject?.data?.length < 1 ? (
                      <p className='sheading3'>No time slots available</p>
                    ) : getSlotsobject ? (
                      <p className='sheading'>Slots Available</p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                <Row>
                  {getSlotsobject &&
                    getSlotsobject?.data?.map((element, index) => {
                      return (
                        <Col key={element.id} lg={3} md={3} sm={12} xs={12}>
                          <div className='InputGroup'>
                            <input
                              type='radio'
                              name='size'
                              id={index}
                              value={element.id}
                              checked={tourId === element.id ? true : false}
                              onClick={(e) => tog_static(e, index)}
                            />
                            <label for={index} className='checkLabel'>
                              {element?.startTime} - {element?.endTime}
                            </label>
                          </div>
                          {/* <Label
                            type='button'
                            className={labelClass}
                            onClick={(e) =>
                              tog_static(
                                `${element?.startTime}-${element?.endTime}`
                              )
                            }
                          >
                            {element?.startTime} - {element?.endTime}
                          </Label> */}
                        </Col>
                      );
                    })}
                </Row>

                <Row className='mt-4 mb-4'>
                  <Col lg={6} md={6} sm={12} xs={12}></Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <div
                      onClick={() => {
                        navigate("/tours/profileInfo", {
                          state: {
                            profileDataFromNextPage: profiledata,
                            slotValueFromNext: slotValue,
                            tourIdFromNext: tourId,
                            dateValueFromNext: dateValue,
                          },
                        });
                      }}
                    >
                      {" "}
                      <Button
                        outline
                        color='secondary'
                        className='pButton w-100 mt-3'
                        onClick={() => {
                          localStorage.setItem("navActive", "1");
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Col>

                  <Col lg={3} md={3} sm={12} xs={12}>
                    <Button
                      color='secondary'
                      onClick={scheduledSubmitFunc}
                      className='ebutton1 w-100 mt-3'
                      disabled={slotValue?.id ? false : true}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>

        <footer>
          <img src={bottomPic} alt='img' className='bottomImg' />
        </footer>
      </div>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />
    </>
  );
};

export default ScheduleInfo;
