import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getTours } from "../../APIS/tours";

const UseWellcome = () => {
  const [tourAvalibilty, setTourAvalibilty] = useState(false);

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  // ************Get Api OF  available tours**********
  const getTourobj = useQuery(
    ["getTours"],

    () => getTours(),
    {
      retry: 0,

      onError: (err) => {
        if (err?.status === 400) {
          setTourAvalibilty(false);
        }
        return err?.message;
      },
    }
  );
  const getTourobject = getTourobj?.data?.data?.data?.statusCode;

  // *****************  End***************

  useEffect(() => {
    if (getTourobject === 200) {
      setTourAvalibilty(true);
    } else {
      setTourAvalibilty(false);
    }
  }, [getTourobject]);

  return { tourAvalibilty, getTourobject };
};

export default UseWellcome;
