import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getSlots, getAvailableSlots } from "../../../APIS/schduleInfo";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";

export const UseScheduledInfo = () => {
  const navigate = useNavigate();
  const profiledataa = useLocation();
  const profiledata = profiledataa?.state?.Email
    ? profiledataa
    : profiledataa?.state?.profiledata;
  const state = useLocation();
  const dataFromNextPage = state?.state;

  const [modelShow, setModelShow] = useState(false);
  const [modal_static, setModal_static] = useState(false);
  const [labelClass, setLabelClass] = useState("timeslotBtn");
  const [slotValue, setSlotValue] = useState({});
  const [tourId, settourId] = useState();
  const [dateValue, setDateValue] = useState();
  const [dateForShow, setDateForShow] = useState("");

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  const tog_static = (value, index) => {
    settourId(value.target.value);
    setSlotValue({
      id: getSlotsobject.data[index].id,
      startTime: getSlotsobject.data[index].startTime,
      endTime: getSlotsobject.data[index].endTime,
      tourDay: getSlotsobject.data[index].tourDay,
    });
  };

  // from next page set slots
  useMemo(() => {
    if (dataFromNextPage?.slotValueFromNext) {
      setSlotValue(dataFromNextPage?.slotValueFromNext);
    }
    if (dataFromNextPage?.tourIdFromNext) {
      settourId(dataFromNextPage?.tourIdFromNext);
    }
  }, [dataFromNextPage?.slotValueFromNext]);

  const dateInputFunc = (e) => {
    var date = new Date();
    if (moment(e).toISOString() < moment(date).toISOString()) {
      return toast.error("Date should be greater then today date", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setDateValue(moment(e).format("YYYY-MM-DD"));

      // FOR SHOW IN INPUT ONLY
      setDateForShow(moment(e).format("YYYY-MM-DD"));
    }
  };

  // from next page set date
  useMemo(() => {
    if (dataFromNextPage?.dateValueFromNext) {
      setDateValue(dataFromNextPage?.dateValueFromNext);
      setDateForShow(
        moment(dataFromNextPage?.dateValueFromNext).format("YYYY-MM-DD")
      );
    }
  }, [dataFromNextPage?.dateValueFromNext]);

  // ************Get Api OF  slots**********
  const getSlotsobj = useQuery(
    ["getSlots", dateValue],

    () => getSlots(dateValue),
    {
      retry: 0,
    }
  );
  const getSlotsobject = getSlotsobj?.data;
  // *****************  End***************

  // ************Get Api OF  slots**********
  const { data: availableSlotsDays, isLoading: availableSlotsLoading } =
    useQuery(
      ["getSlots"],

      () => getAvailableSlots(),
      {
        retry: 0,
      }
    );

  // *****************  End***************

  // *****userpost data*******
  const scheduledSubmitFunc = () => {
    window.scrollTo(0, 0);

    localStorage.setItem("navActive", ["1", "2", "3"]);
    navigate("/tours/review-details", {
      state: {
        profiledata: profiledata,
        tourId: tourId,
        slotValue: slotValue,
        dateValue: dateValue,
      },
    });
  };

  const isDateBooked = (date, bookedDates) => {
    return bookedDates?.some((bookedDate, index) => {
      if (
        moment(date)
          .subtract(moment().utcOffset(), "minutes")
          .isSame(
            moment(bookedDate).subtract(moment().utcOffset(), "minutes"),
            "day"
          )
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  const isDayBooked = (date, bookedDays) => {
    const allDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const existingDays = bookedDays?.map((day) => day?.tourDay);
    const nonExistingDays = allDays?.filter(
      (day) => !existingDays?.includes(day)
    );

    return nonExistingDays?.some((bookedDay, index) => {
      if (moment(date).format("dddd") === bookedDay) {
        return true;
      } else {
        return false;
      }
    });
  };

  const isPastDate = (date) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return date.getTime() < tomorrow.getTime();
  };

  return {
    navigate,
    dateForShow,
    profiledata,
    labelClass,
    setLabelClass,
    modal_static,
    setModal_static,
    tog_static,
    tourId,
    isPastDate,
    availableSlotsLoading,
    availableSlotsDays,
    isDateBooked,
    isDayBooked,
    getSlotsobject,
    modelShow,
    setModelShow,
    scheduledSubmitFunc,
    dateInputFunc,
    slotValue,
    dateValue,
    dateForShow,

    // reschduleTourFunc,
  };
};
