import React from "react";
import { Col, Row, Card, Input, Button } from "reactstrap";
import UseRescheduleVerification from "./useRescheduleVerification";
import bottomPic from "../../../../images/footers/footer-mobile.svg";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./css/rescheduleverification.css";

const RescheduleVerification = () => {
  const {
    getEmailobject,
    emailSubmitFunc,
    resendButtonEnabled,
    setResendButtonEnabled,
    sendButtonText,
    verifyInputFunc,
    submitVerificationCodeFunc,
  } = UseRescheduleVerification();

  return (
    <div className='mt-5'>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
            <Col xs={12} sm={12} md={5} lg={5} xl={5}>
              <p className='rv_card_title mt-3 mb-3'>
                User Verification for Reschedule Tour
              </p>
              <Card className='rv_card p-4'>
                <p className='rv_card_user '>User Email</p>

                <Row className='mt-3'>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className='vr_email_font '>
                      {getEmailobject?.userEmail}
                    </p>
                    {/* <Button
                      className='rv_send_code ebutton1 '
                      color='secondary'
                      onClick={emailSubmitFunc}
                    >
                      Send Code
                    </Button> */}
                  </Col>
                </Row>
                <Row className=''>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}></Col>

                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Button
                      color='secondary'
                      className='ebutton1 w-100 mt-3 h-53'
                      type='submit'
                      disabled={resendButtonEnabled}
                      onClick={emailSubmitFunc}
                      // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                    >
                      {/* Send Code */}
                      <span className='rv_btn_inline'>
                        {resendButtonEnabled ? (
                          <CountdownCircleTimer
                            isPlaying
                            duration={60}
                            colors={[
                              "#004777",
                              "#F7B801",
                              "#A30000",
                              "#A30000",
                            ]}
                            colorsTime={[7, 5, 2, 0]}
                            size={32}
                            strokeWidth={4}
                            strokeLinecap='butt'
                            // trailColor='black'
                            onComplete={() => {
                              setResendButtonEnabled(false);
                            }}
                          >
                            {({ remainingTime }) => remainingTime}
                          </CountdownCircleTimer>
                        ) : (
                          ""
                        )}

                        <span className='rv_btn_text_space'>
                          {" "}
                          {sendButtonText}
                        </span>
                      </span>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Input
                      type='text'
                      onChange={verifyInputFunc}
                      className='mt-3 mb-3'
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={6} xl={6}></Col>
                  <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Button
                      className='rv_send_code ebutton1 w-100'
                      color='secondary'
                      onClick={submitVerificationCodeFunc}
                    >
                      Verify Code
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
          </Row>
        </Col>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <img src={bottomPic} alt='img' className='bottomImg' />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default RescheduleVerification;
