import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import FormInput from "../../../components/molicules/formInputs";
import Switch from "react-switch";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import bottomPic from "../../../images/footers/footer-mobile.svg";
import binPic from "../../../images/icons/binIcon.svg";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import { UseTeamInfo } from "./useTeamInfo";
import PhoneInput from "react-phone-input-2";
import "./css/team.css";

const TeamInfo = () => {
  const {
    teamArr,
    modelShow,
    setModelShow,
    phoneHandle,
    addMember,
    teamsCreateFunc,
    payingMember,
    administratorFunc,
    usersWhichAreAlreadyExist,
    delObjectFunc,
    handleChange,
    backBtnFunc,
  } = UseTeamInfo();

  return (
    <div className='ebgcolor  p-4'>
      <div className='allCardsPadding'>
        <Row>
          <Col lg={10} md={10} sm={10} xs={10}>
            <span className='emainheading'>
              <div onClick={backBtnFunc}>
                <img
                  src={backArrow}
                  alt='arrow'
                  className='arrow_small_screen'
                />
              </div>
              Team Information
            </span>
            <p className='mailmainpheading2'>
              Please provide the information below
            </p>
          </Col>

          <Col lg={2} md={2} sm={2} xs={2}>
            <div className='icon-box'>
              <span onClick={() => setModelShow(true)}>
                <img src={CloseIcon} alt='logo' width={24} height={24} />
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={10} md={10} sm={10} xs={10}>
            <p className='emainheadingMobView my-2 '>Membership</p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card className='p-4 form-card'>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <span className='pcardheadings'>Team Information</span>
                  <p className='mailmainpheading3'>
                    You can have one administrator and one paying member for
                    your team, both of these roles can be assigned to one member
                    or different members. However, there cannot be more than one
                    administrator or paying member. You may change this later
                  </p>

                  {usersWhichAreAlreadyExist?.length > 0 ? (
                    <Card className='px-2 already_exist_card py-2'>
                      <p className='already_exist_user_text mt-2 '>
                        These Users are already part of another team.
                      </p>

                      {usersWhichAreAlreadyExist?.map((e) => {
                        return (
                          <li className='already_exist_user_text1'> {e}</li>
                        );
                      })}
                    </Card>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              {teamArr.map((member, index) => {
                return (
                  <div style={{ marginTop: "20px" }} key={index}>
                    <Row className='mt-4'>
                      <Col lg={11} md={11} sm={11} xs={11}>
                        <span className='pcardheadings'>
                          Member {index + 1}
                        </span>
                      </Col>
                      <Col lg={1} md={1} sm={1} xs={1}>
                        {index < 1 ? (
                          ""
                        ) : (
                          <div className='del-box'>
                            <span onClick={() => delObjectFunc(member?.id)}>
                              <img
                                className='bin_icon_align'
                                src={binPic}
                                alt='logo'
                                width={18}
                                height={18}
                              />
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className='mt-2'>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className='pInputHeadings mt-3'>
                          Administrator <span className='pStaricColor'>*</span>
                        </p>

                        <Switch
                          checked={member?.isAdministrator}
                          onChange={(e) => {
                            administratorFunc(e, member.id, index);
                          }}
                          // onHandleColor='#16B185'
                          width={40}
                          offHandleColor='#000000'
                          uncheckedIcon={false}
                          checkedIcon={false}
                          checkedHandleIcon={false}
                          // offColor='white'
                          height={20}
                          onColor='#0129FA'
                          className='mr-1   '
                        />
                      </Col>

                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className='pInputHeadings mt-3'>
                          Paying Member <span className='pStaricColor'>*</span>
                        </p>

                        <Switch
                          checked={member?.isPayingMember}
                          onChange={(e) => {
                            payingMember(e, member.id, index);
                          }}
                          // onHandleColor='#16B185'

                          width={40}
                          offHandleColor='#000000'
                          uncheckedIcon={false}
                          checkedIcon={false}
                          checkedHandleIcon={false}
                          // offColor='white'
                          height={20}
                          onColor='#0129FA'
                          className='mr-1 mt-1  '
                        />
                      </Col>
                    </Row>

                    <Row className='mt-2'>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <p className='pInputHeadings'>
                          Name <span className='pStaricColor'>*</span>
                        </p>

                        <FormInput
                          value={member?.name}
                          name='name'
                          maxLength={30}
                          min={3}
                          onChange={(e) => {
                            // NameFunc(e);
                            handleChange(e, member.id, index);
                          }}
                          errorMessage='Required'
                          required={true}
                        />
                      </Col>

                      <Col lg={6} md={6} sm={12} xs={12}>
                        <p className='pInputHeadings'>
                          Email Address <span className='pStaricColor'>*</span>
                        </p>
                        <FormInput
                          name='email'
                          value={member?.email}
                          onChange={(e) => {
                            handleChange(e, member.id, index);
                          }}
                          required={true}
                          type='email'
                        />
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <p className='pInputHeadings'>
                          Phone Number <span className='pStaricColor'>*</span>
                        </p>

                        <PhoneInput
                          inputProps={{ maxLength: 16, readOnly: false }}
                          disableDropdown={true}
                          // countryCodeEditable={false}
                          country={"sa"}
                          name='pName'
                          containerClass='pi_PhoneInput'
                          inputStyle={{
                            // borderColor: "black",
                            borderBottomColor: "rgba(0, 0, 0, 0.5)",
                            borderTopColor: "rgba(0, 0, 0, 0.5)",
                            borderLeftColor: "rgba(0, 0, 0, 0.5)",
                            borderRightColor: "rgba(0, 0, 0, 0.5)",
                            width: "100%",
                            height: "56px",
                          }}
                          style={{ width: "100%", height: "56px" }}
                          value={member?.phoneNumber}
                          // value={member?.pName}
                          onChange={(e) => {
                            phoneHandle(e, member.id, index);
                          }}
                          required={true}
                        />
                      </Col>

                      <Col lg={6} md={6} sm={12} xs={12}>
                        <p className='pInputHeadings'>
                          Work Title <span className='pStaricColor'>*</span>
                        </p>
                        <FormInput
                          // value={member?.title}
                          value={member?.workTitle}
                          name='workTitle'
                          onChange={(e) => {
                            handleChange(e, member.id, index);
                          }}
                          errorMessage='Required'
                          required={true}
                          type='text'
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}

              <Row>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <Button
                    style={{
                      backgroundColor: "#fff",
                      color: "#0129FA",
                      marginTop: "30px",
                    }}
                    onClick={addMember}
                    disabled={
                      !teamArr.every((member) => {
                        return (
                          member?.name &&
                          member?.email &&
                          member?.phoneNumber &&
                          member?.workTitle
                        );
                      })
                    }
                  >
                    + Add Member
                  </Button>
                </Col>
              </Row>

              <Row className='mt-4 mb-4'>
                <Col lg={6} md={6} sm={12} xs={12}></Col>
                <Col lg={3} md={3} sm={6} xs={6}>
                  <Button
                    outline
                    color='secondary'
                    className='pButton w-100 mt-3 '
                    onClick={backBtnFunc}
                  >
                    Back
                  </Button>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <Button
                    onClick={teamsCreateFunc}
                    color='secondary'
                    className='ebutton1 w-100 mt-3'
                    disabled={
                      
                      !teamArr.every((member) => {
                        return (
                          member?.name &&
                          member?.email &&
                          member?.phoneNumber &&
                          member?.workTitle
                        );
                      })
                    }
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <img src={bottomPic} alt='img' className='bottomImg' />
        </Col>
      </Row>
    </div>
  );
};

export default TeamInfo;
