import { Row, Col, Button, Container } from "reactstrap";
import SimpleBar from "simplebar-react";
import bgimg from "../../../images//tournav/tourNav.svg";
import { useLocation } from "react-router-dom";
import "./css/tournav.css";

const TourNav = () => {
  const navActive = localStorage.getItem("navActive");
  const location = useLocation();
  return (
    <Row className='tr-maindiv '>
      <Col md={12}>
        <Container>
          <Row className='p-3'>
            <Col md={12}>
              <p className='ttitle'>Tour</p>
            </Col>
          </Row>
          <Row className='p-3 '>
            <Col md={12}>
              <SimpleBar className='simple_bar_height'>
                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      // color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/tours/emailInfo"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("1") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/tours/emailInfo"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        1
                      </span>
                    </Button>
                  </span>
                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/tours/emailInfo"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("1") && "doneText"}`}
                    >
                      Enter Email
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/tours/emailInfo"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("1") && "dottedlinDone"}`}
                ></div>

                {/* } */}
                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/tours/profileInfo"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("2") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/tours/profileInfo"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        2
                      </span>
                    </Button>
                  </span>
                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/tours/profileInfo"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("2") && "doneText"}`}
                    >
                      Profile Information
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/tours/profileInfo"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("2") && "dottedlinDone"}`}
                >
                  {" "}
                </div>

                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/tours/scheduleInfo/"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("3") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/tours/scheduleInfo/"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        3
                      </span>
                    </Button>
                  </span>
                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/tours/scheduleInfo/"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("3") && "doneText"}`}
                    >
                      Schedule Tour
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/tours/scheduleInfo/"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("3") && "dottedlinDone"}`}
                >
                  {" "}
                </div>

                {/* ******* 4 start *********** */}

                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/tours/review-details"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("4") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/tours/review-details"
                            ? "rounded-btn-text-Active"
                            : "rounded-btn-text"
                        }`}
                      >
                        4
                      </span>
                    </Button>
                  </span>
                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/tours/review-details"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("4") && "doneText"}`}
                    >
                      Review Details
                    </small>
                  </span>
                </div>

                <div
                  className={`${
                    location.pathname === "/tours/review-details"
                      ? "dottedlinActive"
                      : "dottedlin"
                  } ${navActive?.includes("4") && "dottedlinDone"}`}
                >
                  {" "}
                </div>

                {/* ********4 end **********  */}

                <div>
                  <span>
                    <Button
                      outline
                      disabled={true}
                      color='success'
                      className={`waves-effect btn-circle ${
                        location.pathname === "/tours/applicationSubmitted"
                          ? "roundedCircleActive"
                          : "roundedCircle"
                      } ${navActive?.includes("5") && "activeNav"}`}
                    >
                      <span
                        className={`${
                          location.pathname === "/tours/applicationSubmitted"
                            ? "rounded-btn-text"
                            : "rounded-btn-text"
                        }`}
                      >
                        5
                      </span>
                    </Button>
                  </span>
                  <span>
                    <small
                      className={` ml-3 ${
                        location.pathname === "/tours/applicationSubmitted"
                          ? "text-muted-colored"
                          : "text-muted"
                      } ${navActive?.includes("5") && "doneText"}`}
                    >
                      Application Submitted
                    </small>
                  </span>
                </div>
              </SimpleBar>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col md={12} lg={12} sm={12} xs={12}>
            <img src={bgimg} alt='img' className='tourImg ' />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TourNav;
