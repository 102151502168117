import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "../../../APIS/profileInfo";
import { toast } from "react-toastify";

const UseProfileInfo = () => {
  const state = useLocation();
  const profileDataFromNextPage =
    state?.state?.profileDataFromNextPage?.state?.ProfileData;

  const slotValue = state?.state?.slotValueFromNext
    ? state?.state?.slotValueFromNext
    : false;

  const dateValueFromNext = state?.state?.dateValueFromNext
    ? state?.state?.dateValueFromNext
    : "";

  const tourId = state?.state?.tourIdFromNext
    ? state?.state?.tourIdFromNext
    : false;

  const [modelShow, setModelShow] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    linkedinName: "",
    twitterName: "",
  });
  const navigate = useNavigate();

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  const UserEmailNoFormat = localStorage.getItem("userEmail");
  const userEmail = toLowerCase(UserEmailNoFormat);

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
  };

  const linkedinNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, linkedinName: e.target.value });
  };
  const phoneNumberFunc = (e) => {
    setProfileInfo({ ...profileInfo, phoneNumber: e });
  };
  const twitterNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, twitterName: e.target.value });
  };
  const occupationNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, occupationName: e.target.value });
  };
  const employementStatusFunc = (e) => {
    setProfileInfo({ ...profileInfo, employmentStatus: e.value });
  };
  const pNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, phoneNum: e });
  };
  const lNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, lastName: e.target.value });
  };
  const fNameFunc = (e) => {
    setProfileInfo({ ...profileInfo, firstName: e.target.value });
  };

  const setProfileData = (e) => {
    window.scrollTo(0, 0);

    const urlExpression = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );

    const twitterExpression = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?twitter\\.com\\/[A-Za-z0-9_]{1,15}$"
    );

    const linkedInExpression = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?linkedin\\.com\\/[A-Za-z0-9\\-]+(\\/[A-Za-z0-9\\-]+)?"
    );

    if (
      profileInfo?.twitterName !== "" &&
      twitterExpression.test(profileInfo?.twitterName) === false
    ) {
      return toast.error("Twitter Link format is incorrect", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.linkedinName !== "" &&
      linkedInExpression.test(profileInfo?.linkedinName) === false
    ) {
      return toast.error("LinkedIn Link format is incorrect", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (profileInfo?.firstName === undefined || profileInfo?.firstName === "") {
      return toast.error("First Name is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.lastName === undefined ||
      profileInfo?.lastName === ""
    ) {
      return toast.error("Last Name is  Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      profileInfo?.phoneNum === undefined ||
      profileInfo?.phoneNum === "" ||
      profileInfo?.phoneNum?.length < 12
    ) {
      return toast.error("Invalid phone number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      localStorage.setItem("navActive", ["1", "2"]);

      navigate("/tours/scheduleInfo/", {
        state: {
          ProfileData: profileInfo,
          Email: toLowerCase(userEmail),
          slotValueFromNext: slotValue,
          tourIdFromNext: tourId,
          dateValueFromNext: dateValueFromNext,
        },
      });
    }
  };

  // ************Get Api OF Pofile using email**********
  const { data: getProfileobj } = useQuery(
    ["TourprofileEmail", userEmail],
    () => getProfile(userEmail),
    {
      retry: 0,
    }
  );

  const getProfileobject = getProfileobj?.res;

  // *****************  End***************

  useEffect(() => {
    !profileDataFromNextPage &&
      getProfileobject &&
      setProfileInfo({
        firstName: getProfileobject?.data?.firstName,
        lastName: getProfileobject?.data?.lastName,
        phoneNum: getProfileobject?.data?.phoneNumber,
        employmentStatus: getProfileobject?.data?.employmentStatus,
        occupationName:
          getProfileobject?.data?.occupation === undefined
            ? ""
            : getProfileobject?.data?.occupation,
        twitterName: getProfileobject?.data?.twitter
          ? getProfileobject?.data?.twitter
          : "",
        linkedinName: getProfileobject?.data?.linkedin
          ? getProfileobject?.data?.linkedin
          : "",
        userId: getProfileobject?.data?.id,
        userExists: getProfileobject?.data?.userExits,
        companyName: getProfileobject?.data?.companyName,
      });
  }, [getProfileobject]);

  useMemo(() => {
    profileDataFromNextPage &&
      setProfileInfo({
        firstName: profileDataFromNextPage?.firstName,
        lastName: profileDataFromNextPage?.lastName,
        phoneNum: profileDataFromNextPage?.phoneNum,
        employmentStatus: profileDataFromNextPage?.employmentStatus,
        occupationName:
          profileDataFromNextPage?.occupationName === undefined
            ? ""
            : profileDataFromNextPage?.occupationName,
        twitterName: profileDataFromNextPage?.twitterName
          ? profileDataFromNextPage?.twitterName
          : "",
        linkedinName: profileDataFromNextPage?.linkedinName
          ? profileDataFromNextPage?.linkedinName
          : "",
        userId: profileDataFromNextPage?.userId,
        userExists: profileDataFromNextPage?.userExists,

        companyName: profileDataFromNextPage?.companyName,
      });
  }, [profileDataFromNextPage]);

  return {
    modelShow,
    profileInfo,
    getProfileobject,
    userEmail,
    fNameFunc,
    lNameFunc,
    pNameFunc,
    phoneNumberFunc,
    employementStatusFunc,
    occupationNameFunc,
    twitterNameFunc,
    setModelShow,
    linkedinNameFunc,
    handelSubmit,
    setProfileInfo,
    setProfileData,
  };
};

export { UseProfileInfo };
