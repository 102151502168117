import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Container, Row, Table } from 'reactstrap'

const PrivacyPolicy = () => {
 const navigate = useNavigate();
  return (
   <div className='ft-wc-main-box px-2 pp_text'>
   <div className='p-3 mb-4 mt-4'>
     <Container>
     <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='d-flex justify-content-center p-2'>
               <h3>Confidential</h3> 
                </div>
              </Col>
              {/* <Col md={1} xl={1} lg={1} sm={1} xs={1}> */}
              {/* <Button

        className=" w-100"
        onClick={() => {
          navigate(-1);
        }}
      >
        Close
      </Button> */}
      {/* </Col> */}
            </Row>

            <Row>
            <Table bordered responsive>
  <thead>
    <tr>
     
      <th>
      Privacy Notice
      </th>
      <th className="d-flex justify-content-end">
      إشعار الخصوصية
      </th>
    
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">
      INTRODUCTION
      </th>
      <th className="d-flex justify-content-end">
      مقدمة
      </th>
     
    </tr>
    <tr>
      <td scope="row">
      A short brief about the Fintech Saudi (“Fintech Saudi”,
“we”, “us” and/or “our”) or the related service, Fintech
Saudi was launched by the Saudi Central Bank in
partnership with the Capital Market Authority in April
2018 to act as a catalyst for the development of the
financial services technology (fintech) industry in Saudi
Arabia.
● Believing in the importance of data and its
confidentiality, the management of co-working space
management system (“System”)’s website,
hub.fintechsaudi.com (“Site”) and our mobile
applications (“App”), ("Administration") aims to
provide the best levels of service with a commitment
to maintaining the confidentiality and privacy of
users' data, so it is keen to maintain and ensure the
confidentiality and privacy of the entered or
collected data for any of the System. ("User" or
"Users") where the privacy policy has been
formulated in accordance with the Personal Data
Protection Law, Regulations, and Controls in the
Kingdom of Saudi Arabia for the purpose of helping
the User understand the nature of the data we
collect from him and ho
      </td>
      <td className="d-flex justify-content-end">
      نبذة عن فنتك السعودية أو الخدمة ذات العالقة، فنتك السعودية )"فنتك السعودية"
و / أو "نحن" و / أو "الخاصة بنا"( هي مبادرة أطلقها البنك المركزي السعودي
بالشراكة مع هيئة السوق المالية في إبريل 2018م كخطوة تحفيزية لتطوير
مجال التقنية المالية في المملكة العربية السعودية وتعزيز دوره في التنمية
االجتماعية االقتصادية الوطنية.
بأهمية البيانات وسريها فإن إدارة نظام إدارة مساحات العمل
● إيماناً
المشتركة )"النظام"( من خالل الموقع اإللكتروني
com.fintechsaudi.hub"( الموقع"( والتطبيق الخاصة بنا
)"التطبيق"(، )"اإلدارة" أو "إدارة"( تهدف إلى تقديم أفضل مستويات
الخدمة مع االلتزام بالمحافظة على سرية وخصوصية بيانات المستخدمين،
لذا فهي تحرص على إبقاء وضمان سرية وخصوصية البيانات المدخلة أو
المجموعة عن أي من مستخدمي نظام إدارة مساحات العمل المشتركة
قا
)"المستخدم" أو "المستخدمين"( حيث تمت صياغة سياسة الخصوصية وفً
لقانون ولوائح وضوابط حماية البيانات الشخصية في المملكة العربية
السعودية وذلك لغرض مساعدة المستخدم على فهم طبيعة البيانات التي
نجمعها منه وكيف سيتم التعامل مع هذه البيانات من قبل "اإلدارة".

      </td>
     
    </tr>
    <tr>
      <th scope="row">
      Collection of Data 
      </th>
      <th className="d-flex justify-content-end">
       جمع البيانات
      </th>
     
    </tr>

    <tr>
     <td scope="row">
     The Administration will be collecting the User’s data
entered for the purpose of registration and using
personal or commercial data, or that the User provides
for that purpose to the Administration through phone
calls or E-mails. This Data includes – but is not limited to
– the following:
1. Identification Data, including User’s name, first and
last name
2. Contact Information, including address (city, country,
postal code), phone number and Email
3. Entity’s Information, including name, activities,
Commercial Registration number, License number
4. Data obtained through integration with the
databases of Fintech Saudi if you are pervious user
     </td>
     <td className="d-flex justify-content-end">تجمع اإلدارة البيانات الشخصية التي يقوم المستخدم بإدخالها بغرض التسجيل
واستخدام البيانات الشخصية أو التجارية، أو التي يقوم بتزويدها لإلدارة عبر
الهاتف أو البريد اإللكتروني لذات الغرض، وتتضمن هذه البيانات – على سبيل
المثال ال الحصر - ما يلي:
.1 بيانات الهوية الخاصة باألفراد، بما يتضمن االسم األول واألخير
.2 معلومات التواصل: بما يتضمن العنوان، ورقم الهاتف، البريد
االلكتروني
.3 معلومات المنشآت، بما يتضمن اسم المنشاة، نوع المنشاة، رقم السجل
التجاري، التراخيص ذات العالقة وأرقامها،
.4 بيانات متحصلة عبر الربط اإللكتروني مع قواعد بيانات اخرى لدى
فنتك السعودية إذا كنت مستخدم سابق</td>
    </tr>

    <tr>
     <th scope="row">
     THE LEGAL BASIS FOR COLLECTING AND
PROCESSING PERSONAL DATA

     </th>
     <th className="d-flex justify-content-end">
     لساس النظامي لجمع ومعالجة البيانات الشخصية
     </th>
    </tr>

    <tr>
     <td scope="row">
     ● Contractual—to fulfill our services obligations to you.
● Consent for other activities such as information,
sales, and marketing.


     </td>
     <td className="d-flex justify-content-end">
     ● تعاقدي: للوفاء بالتزامات الخدمات تجاهك
● الموافقة: لألنشطة األخرى مثل المعلومات والمبيعات والتسويق
     </td>
    </tr>

    <tr>
     <th scope="row">
     THE PURPOSE(S) OF COLLECTING PERSONAL
DATA
     </th>
     <th className="d-flex justify-content-end">
     الغرض/ لألغراض من جمع البيانات الشخصية
     </th>
    </tr>

    <tr>
     <td scope="row">
     - Provide, manage and enhance the services offered
through the Fintech Saudi, which include – but are
not limited to – the following:
1. Booking a private office, dedicated office and/or
meeting room
2. Requesting Hot desk, one-day pass products and
additional services that maybe offered in the future
(collectively, the “Services”)

     </td>
     <td className="d-flex justify-content-end">
     - تقديم، وإدارة، وتحسين الخدمات المقدمة عبر النظام ، بما فيها – على
سبيل المثال ال الحصر - ما يلي:
.1 حجز مكتب خاص، مكتب عام، و / أو غرفة االجتماعات
.2 طلب المكتب المرن وتصريح اليوم الواحد وقد نقوم بتقديم خدمات
ومنتجات إضافية في المستقبل )"الخدمات" مجتمعة( المقدمة من فنتك
السعودية.
     </td>
    </tr>

    <tr>
     <td scope="row">
     Communication with System Users and interact with
them for various purposes, including but not limited
to, in the event of a request for assistance, or
respond to any inquiries or complaints or
communication with the System administration
     </td>
     <td className="d-flex justify-content-end">
     التواصل مع مستخدمي النظام والتفاعل معهم ألغراض مختلفة على سبيل
المثال ال الحصر، في حالة طلب المساعدة، أو االستجابة ألي استفسارات،
أو شكاوى، أو التواصل مع اإلدارة النظام.
     </td>
    </tr>

    <tr>
     <td scope="row">
     For the purposes of marketing certain products and
services to users via various means of
communication, including telephone, email, or
notifications
     </td>
     <td className="d-flex justify-content-end">
     لغراض تسويق بعض الخدمات للمستخدمين عبر وسائل االتصال
المختلفة، بما في ذلك الهاتف أو البريد اإللكتروني أو اإلخطارات.
     </td>
    </tr>
    <tr>
     <td scope="row">
     Provide the user with the latest updates about
current or new services and products or other
events provided by the System‘s administration or
its partners.

     </td>
     <td className="d-flex justify-content-end">
     تزويد المستخدم بآخر المستجدات حول الخدمات والمنتجات الحالية أو
الجديدة أو غيرها من األحداث التي تقدمها إدارة النظام أو شركاؤها.
     </td>
    </tr>

    <tr>
     <td scope="row">
     Monitoring and flagging any breach to this Policy or
the System’s Terms of Use, or any case of misuse.

     </td>
     <td className="d-flex justify-content-end">
     - مراقبة وكشف مخالفات شروط االستخدام والسياسات الخاصة بالنظام،
وكذلك حاالت سوء االستخدام المحتملة األخرى.
     </td>
    </tr>

    <tr>
     <td scope="row">
     Maintaining and enhancing the performance and
security of System, and the Administration’s
programs, systems, and networks.

     </td>
     <td className="d-flex justify-content-end">
     صيانة ورفع مستوى أداء وأمن النظام، وغيرها من خدمات، وإدارة النظم
والشبكات الخاصة بها.
     </td>
    </tr>

    <tr>
     <td scope="row">
     Share with Technical partner, Manage System, and
its internal operations, including but not limited to
troubleshooting, data analysis, testing and
surveying.

     </td>
     <td className="d-flex justify-content-end">
     مشاركة الشريك التقني وإدارة النظام وتنفيذ العمليات الداخلية به بما في
ذلك اكتشاف األعطال وتحليل المعلومات، واالختبار والبحث واألغراض
اإلحصائية وأغراض الدراسات المسحية الشاملة.
     </td>
     
    </tr>

    <tr>
     <td scope="row">
     Any use described throughout this Policy, or further
consented by the User.

     </td>
     <td className="d-flex justify-content-end">
     لألغراض الوارد وصفها في موضع آخر من سياسة الخصوصية، أو
حصلت على موافقة المستخدم
     </td>
    </tr>

    <tr>
     <td scope="row">
     Data that does not contain any personal information
or identifiers and cannot be used to identify you may
be used within cumulative statistical data and
indicators and shared with government agencies
and/or relevant third parties, as deemed by the
System’s administration
     </td>
     <td className="d-flex justify-content-end">
     البيانات التي ال تحتوي على أي معلومات شخصية أو معرفات وال يمكن
استخدامها لتحديد هويتك، يجوز استخدام ضمن بيانات ومؤشرات إحصائية
تراكمية ومشاركتها مع الجهات الحكومية و/ أو أطراف ثالثة ذات العالقة
حسب ما تراه إدارة النظام
     </td>
    </tr>

    <tr>
     <td scope="row">
   Data Collection Methods
     </td>
     <td className="d-flex justify-content-end">
     طرق جمع البيانات
     </td>
    </tr>

    <tr>
     <td scope="row">
     The data that the owner of the personal data
provides to the Site or App is collected through
registration form, no personal data is collected from
any other party.

     </td>
     <td className="d-flex justify-content-end">
     يتم جمع البيانات التي يقوم صاحب البيانات الشخصية بتزويد الموقع أو
التطبيق بها عن طريق نموذج التسجيل، وال يتم جمع بيانات شخصية من
أي جهات أخرى.
     </td>
    </tr>

    <tr>
     <td scope="row">
     The Administration will collect the User’s data
through requesting it to fill some forms or by contacting it directly via approved communication
channels.

     </td>
     <td className="d-flex justify-content-end">
     ستجمع اإلدارة البيانات عبر طلب تعبئة بعض النماذج أو بالتواصل معك
مباشرة عبر القنوات المعتمدة للتواصل.
     </td>
    </tr>

    <tr>
     <th>
     USE OF PERSONAL DATA
     </th>
     <th className="d-flex justify-content-end">
     استخدام البياناتالشخصية
     </th>
    </tr>
    <tr>
     <td>
     Personal data is used for the purposes of
completing the registration process, improving the
beneficiary's experience and doing internal analysis
for the Services provided by Fintech Saudi.
     </td>
     <td className="d-flex justify-content-end">
     يتم استخدام البيانات الشخصية ألغراض إتمام عملية التسجيل وتحسين
تجربة المستفيد والتحليالت الداخلية لتقديم الخدمات من فنتك السعودية
     </td>
    </tr>
    <tr>
     <th>
     THE COLLECTION OF PERSONAL DATA OF
CHILDREN OR THEIR EQUIVALENTS
     </th>
     <th className="d-flex justify-content-end">
     جمع البيانات الشخصية لألطفال أو من في حكمهم
     </th>
    </tr>
    <tr>
     <td>
     If a user provides personal data about someone
under the age of eighteen (18) or someone who is
mentally incompetent mentally incapacitated, he
must acknowledge that he is the legal guardian and
agrees to the use or processing of personal data.

     </td>
     <td className="d-flex justify-content-end">نعم، نجمع من المستخدم بيانات شخصية ألطفال دون سن الثامنة عشر
)18( أو من في حكمهم كناقصي األهلية أو فاقديها، فيجب عليه اإلقرار
بأنه الولي الشرعي له وموافقته على استخدام أو معالجة البيانات الشخصية
لالستخدام خدمة زيارة اليوم الواحد.</td>
    </tr>
    <tr>
     <th>
     Personal Data Storage And Deletion
     </th>
     <th className="d-flex justify-content-end">
     البيانات تخزين  الشخصية وإتالفها
     </th>
    </tr>

    <tr>
     <td>
     The personal data is stored on servers of the
service provider that are specified within the
Kingdom of Saudi Arabia. Fintech Saudi makes sure
that these servers are protected from hacking and
unauthorized access according to the rules. The
user's personal data will be kept for as long as it
takes to reach the goals for which it was collected or
as long as it takes to meet legal, regulatory,
accounting, or reporting requirements. These
periods may vary depending on the circumstances
and requirements, and the data storage periods are
subject to regular periodic review to ensure that
personal data is not stored for longer than
necessary, and destroyed in accordance with the
Fintech Saudi policies and procedures for data
destruction.

     </td>
     <td className="d-flex justify-content-end">
     يتم تخزين البيانات الشخصية في خوادم مزود الخدمة داخل المملكة العربية
السعودية، وتحرص فنتك السعودية على أن تكون هذه الخوادم محمية من
االختراق، والدخول غير المصرح وفق المعايير المتبعة، وسيتم تخزين
بيانات المستخدم الشخصية طالما كانت ضرورية، أو وفقا لفترات االحتفاظ
المحددة ما دام ذلك ضروريا لتحقيق األغراض التي جمعت من أجلها، أو
لتلبية متطلبات قانونية، أو تنظيمية، أو محاسبية، أو إعداد التقارير. وقد
تختلف هذه المدد حسب الظروف والمتطلبات، كما أن مدد تخزين البيانات
هي محل مراجعة دورية منتظمة للتأكد من أن البيانات الشخصية ال تخزن
لفترة أطول من الالزم، وإتالفها وفقا لسياسات واجراءات اإلتالف البيانات
المتبعة في فنتك السعودية.
     </td>
    </tr>

    <tr>
     <th>
     PROTECTION DATA PERSONAL
     </th>
     <th className="d-flex justify-content-end">
     حماية البيانات الشخصية
     </th>
    </tr>
    
    <tr>
     <td>
     The System’s administration is taking personal data
protection very seriously and it’s committed to using
the organizational, administrative, and technical
procedures and means necessary to protect the
personal data from unauthorized access, use,
alteration, or destruction, such as conducting
internal and external audits, using data encryption
and security tools, and trains employees on data
privacy.
- The right to access personal data is limited to
authorized personnel based on the purposes and
uses determined by the policies adopted by Fintech
Saudi
     </td>
     <td className="d-flex justify-content-end">
     إدارة النظام تأخذ حماية البيانات الشخصية على محمل الجد وتلتزم
باستخدام اإلجراءات والوسائل التنظيمية واإلدارية والتقنية الالزمة لحماية
البيانات الشخصية من أي وصول، أو استخدام، أو تغيير، أو إتالف غير
مصـرح به، بما في ذلك إجراء التدقيق الداخلي والخارجي وتشفير البيانات،
وتدريب الموظفين على خصوصية البيانات.
- يقتصر حق االطالع على البيانات الشخصية على العاملين المصرح لهم
بناء على لألغراض، واالستخدامات التي تحددها السياسات المعتمدة لدى
فنتك السعودية.
     </td>
    </tr>
    <tr>
     <th>
    PERSONAL SHARING /DISCLOSURE DATA 
     </th>
     <th className="d-flex justify-content-end">
     اإلفصاح /مشاركة البيانات الشخصية
     </th>
    </tr>
    <tr>
     <td>
     1. Non-identifying data may be used within cumulative
statistical data and indicators, and shared with all
relevant authorities as deemed appropriate by
Fintech Saudi.
2. Personal Data may only be disclosed or shared
when it complies with the laws and regulations in
force in the Kingdom of Saudi Arabia or has been
requested by law, or when management deems
such action necessary or desirable to provide
products, services or technical support.
- The administration will disclose / share personal
data in activities related to the services provided via
System. With its partners and related parties; to
provide the user with information about the services
he benefits from.
     </td>
     <td className="d-flex justify-content-end">
     1 يجوز استخدام البيانات غير المحددة للهوية ضمن بيانات ومؤشرات
إحصائية تراكمية، ومشاركتها مع الجهات ذات العالقة كافة حسب ما تراه
فنتك السعودية .
مناسباً
.2 ال يجوز اإلفصاح عن هذه البيانات أو مشاركتها إال عند توافقها مع األنظمة
والتشريعات المعمول بها في المملكة العربية السعودية أو تم طلبها بموجب
القانون، أو عندما تراء اإلدارة أن هذا اإلجراء ضروري أو مرغوب فيه
لتوفير المنتجات والخدمات أو الدعم الفني.
.3 ستقوم اإلدارة باإلفصاح /مشاركة عن البيانات الشخصية في األنشطة ذات
العالقة بالخدمات المقدمة عبر النظام مع شركائها والجهات ذات العالقة
بها؛ لتزويد المستخدم بمعلومات حول الخدمات التي يستفيد منها.
     </td>
    </tr>
    <tr>
     <td >
     A) Disclosure to Third Parties:
The Administration will not disclose or share any of the
personal data to third parties, except for the following
cases:
- Disclosure to a government entity, if that disclosure
is in accordance with the applicable laws and
regulations implemented in the Kingdom of Saudi
Arabia, or any order issued by the competent
authorities therein.
- In the event the Administration resorts to external
parties who are proven to be trusted and reputable
establishments, and who shall adhere to the
standards of confidentiality that are upheld by the
Administration.
- Disclosure to government authorities that are related
to the electronic services that are requested by the
User, as well as disclosure to non-government
entities that are authorized by the government
entities to receive, solve, transfer, or pass
government service requests or to provide services
whenever completion of the services requires
observing or storing or processing User’s data by
any entity government.
- Disclosure to other parties subject to User’s consent
     </td>
     <td className="d-flex justify-content-end">
     1( اإلفصاح ألطراف ثالثة:
لن تقوم اإلدارة باإلفصاح عن البيانات الشخصية أو مشاركتها مع أي أطراف
أخرى، ويستثنى من ذلك:
لما تطلبه اللوائح واألنظمة المعمول بها في
- اإلفصاح للجهات الحكومية وفقاً
المملكة العربية السعودية أو بأوامر من الجهات المختصة.
- في حال استعانت اإلدارة بمساندة جهة خارجية، إذ ستقوم باالستعانة
بمؤسسات موثوقة وذات مرجعية، وتطلب منها وتؤكد على التقّيد بمعايير
السرية المعتمدة لدى اإلدارة.
- اإلفصاح للجهات الحكومية المختصة والمتعلقة بالخدمات التي يطلباها
المستخدم، وكذلك اإلفصاح للجهات غير الحكومية المصرح لها من الجهات
المختصة بالقيام باستقبال، أو معالجة، أو نقل، أو تمرير طلبات تلك
الخدمات أو تقديم الخدمات متى كان تنفيذ الخدمة يتطلب االطالع أو تخزين
ومعالجة واستخدام تلك البيانات من قبل أي من تلك الجهات
- اإلفصاح ألطراف أخرى بعد الحصول على موافقة المستخدم.
     </td>
    </tr>
    <tr>
     <td>
     B) Disclosure to entities outside the Kingdom of Saudi
Arabia:
Personal data will not be disclosed or processed outside
the Kingdom of Saudi Arabia, except based on the
applicable laws and regulations, in the event of
necessity or to achieve the intended purposes of
collecting and processing it in the first place
     </td>
     <td className="d-flex justify-content-end">
     2( اإلفصاح لجهات خارج المملكة العربية السعودية:
لن يتم اإلفصاح عن البيانات الشخصية أو معالجتها خارج حدود المملكة العربية
السعودية، اال بنا ًء على األنظمة والضوابط المعمولة بها، وذلك في حال
الضرورة أو لتحقيق األغراض المستهدفة من جمعها ومعالجتها .
أساساً
     </td>
    </tr>

    <tr>
     <th>
     LINKS EXTERNAL
     </th>
     <th className="d-flex justify-content-end">
     استخدام روابط خارجية
     </th>
    </tr>
    <tr>
     <td>
     3. System, it may contain links to third party websites
or services, which are subject to separate privacy
policies. Please note that these links are beyond the
control of the administration, and the administration is not responsible for how other parties collect or
use personal information. The user is fully
responsible for his data and should review the
privacy policies of this links.

     </td>
     <td className="d-flex justify-content-end">
     قد يحتوي النظام على روابط لمواقع أو خدمات أطراف أخرى، والتي تخضع
لسياسات خصوصية مستقلة. ويرجى مالحظة أن هذه الروابط خارجة عن
سيطرة اإلدارة، واإلدارة ليست مسؤولة عن كيفية جمع األطراف األخرى، أو 
استخدامها للمعلومات الشخصية. ويتحمل المستخدم المسؤولية الكاملة عن بياناته.
ويجب عليه مراجعة سياسات الخصوصية الخاصة بهذا الرابط.
     </td>
    </tr>
    <tr>
     <th>
     PERSONAL DATA SUBJECT (OWNER) RIGHTS
     </th>
     <th className="d-flex justify-content-end">
     حقوق صاحب البيانات الشخصية
     </th>
    </tr>

    <tr>
     <td>
     4. The personal data subject (Owner) has the right to
benefit from the rights stipulated in the Personal
Data Protection Law. You can contact us at
hub@fintechsaudi.com, noting that the
Administration may request additional information
in order to implement the request or verify identity.
     </td>
     <td className="d-flex justify-content-end">
     يحق لصاحب البيانات الشخصية االستفادة من حقوقه المنصوص عليها في نظام
حماية البيانات الشخصية، باإلمكان التواصل معنا على
بأن اإلدارة قد تطلب معلومات إضافية
ًعلما ،hub@fintechsaudi.com
بهدف تنفيذ الطلب أو التحقق من الهوية.
     </td>
    </tr>

    <tr>
     <th>USER’S RESPONSIBILITY FOR PRIVACY
PROTECTION</th>
<th className="d-flex justify-content-end">
مسؤولية المستخدم تجاه حماية الخصوصية
</th>
    </tr>

    <tr>
     <td>
     ● The user is obliged to contact the Administration
immediately when it is believed that someone has
been able to access his data or access his account.
● The user is obligated not to give any confidential
information over the phone or the Internet unless the
identity of the person or party receiving the
information is known.
● The user is obligated not to share login information
(username, password) with others, and not to allow
others to use the user account in System.
● The user is obligated to use a secure browser when
completing online transactions with unused
applications closed on the network, and ensure that
your antivirus software is always up to date.
The user is obligated to update the contact information
used to identify in System, if replaced.

     </td>
     <td className="d-flex justify-content-end">
     ● يلتزم المستخدم بالتواصل مع اإلدارة فورا عند االعتقاد بأن شخص ما
استطاع الوصول إلى بياناته أو الدخول إلى حسابه.
● يلتزم المستخدم بعدم إعطاء أي معلومات سرية عبر الهاتف أو شبكة
اإلنترنت ما لم تعرف هوية الشخص أو الطرف المستقبل للمعلومة.
● يلتزم المستخدم بعدم مشاركة معلومات الدخول )اسم المستخدم، وكلمة
المرور( مع اآلخرين، وعدم السماح لآلخرين باستخدام حساب المستخدم
في النظام.
● يلتزم المستخدم باستخدام متصفح آمن عند القيام بإنجاز المعامالت عبر
اإلنترنت مع إغالق التطبيقات غير المستخدمة على الشبكة، والتأكد من أن
برنامج الحماية من الفيروسات محدث على الدوام.
يلتزم المستخدم بتحديث معلومات التواصل المستخدمة للتعريف على النظام ، في
حال استبدالها.
     </td>
    </tr>

    <tr>
     <th>
     PERSONAL DATA PROTECTION OFFICER
     </th>

     <th className="d-flex justify-content-end">
     مسؤول حماية البيانات الشخصية
     </th>
    </tr>

    <tr>
     <td>
     The System’s Administration at Fintech Saudi is
obligated to protect personal and contact data also you
reach out to hub@fintechsaudi.com

     </td>
     <td className="d-flex justify-content-end">
     دارة النظام ملزمة بحماية البيانات الشخصية والتواصل ويمكنك المراسلة عبر
.hub@fintechsaudi.com اإللكتروني البري
     </td>
    </tr>

    <tr>
     <th>
     PROVISIONS GENERAL
     </th>
     <th className="d-flex justify-content-end">
     أحكام عامة
     </th>
    </tr>

    <tr>
     <td>
     The Administration reserves the right to amend this
Privacy Policy, and it shall notify the User to obtain
User’s acceptance on such amendment. The User’s
account may be subject to suspension or cancellation in
case the User refuses to accept the new version on this
Privacy Policy.
Issue number: 10010
Update date: 17/5/2023

     </td>
     <td className="d-flex justify-content-end">
     تحتفظ اإلدارة بحقها في تعديل سياسة الخصوصية هذه، وسيتم إخطار المستخدم
بذلك للحصول على موافقته عليها، ولإلدارة تعليق أو إيقاف حساب المستخدم في
حال عدم قبول اإلصدار الجديد من سياسة الخصوصية.
رقم اإلصدار: 10010 
تاريخ التحديث: 2023/5/17 
     </td>
    </tr>
  </tbody>
</Table>
            </Row>

     <button
        className="go-back-btn w-100"
        onClick={() => {
          navigate(-1);
        }}
      >
        Close
      </button>
     </Container>
    </div>
    </div>
  )
}

export default PrivacyPolicy