import { Suadi_fintech_URL } from "../config/url";
import axios from "axios";

export const getSlots = async (date) => {
  if (date) {
    const res = await axios.post(`${Suadi_fintech_URL}/tour-slots-date`, {
      date: date,
    });

    const data = await res?.data?.slots;
    return {
      data,
    };
  }
};
export const getAvailableSlots = async () => {
  const res = await axios.get(`${Suadi_fintech_URL}/all-slots-availble`);
  // if (!res.ok) {
  //   const resJson = await res.json();
  //   throw new Error(resJson.error.message);
  // }
  // const data = await res.json();

  return res?.data;
};

// **************tour Details*****************

export const getTourDetails = async (tourId) => {
  if (tourId) {
    const res = await axios.get(`${Suadi_fintech_URL}/get-tours/${tourId}`);
    // if (!res.ok) {
    //   const resJson = await res.json();
    //   throw new Error(resJson.error.message);
    // }
    // const data = await res.json();

    return {
      res,
    };
  }
};
