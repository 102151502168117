import { useState } from "react";
import axiosClient from "../../../config/axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Suadi_fintech_URL } from "../../../config/url";

const UseReviewDetails = () => {
  const [  modelShow1,
    setModelShow1] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const { profileInfo, email } = state;
  const [modelShow, setModelShow] = useState(false);
  var errorTrue = false;

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  const submitData = () => {
    window.scrollTo(0, 0);

    var config = {
      method: "PATCH",
      url: `${Suadi_fintech_URL}/users/${profileInfo?.userId}`,
      headers: {
        Access: "*/*",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({
        email: toLowerCase(email),
        firstName: `${profileInfo?.firstName}`,
        lastName: `${profileInfo?.lastName}`,
        phoneNumber: `${profileInfo?.phoneNum}`,
        employmentStatus: `${profileInfo?.employmentStatus}`,
        occupation: `${
          profileInfo?.occupationName === undefined
            ? ""
            : profileInfo?.occupationName
        }`,
        twitter: `${profileInfo?.twitterName ? profileInfo?.twitterName : ""}`,
        linkedin: `${
          profileInfo?.linkedinName ? profileInfo?.linkedinName : ""
        }`,
      }),
    };

    axiosClient(config)
      .then((resuser) => {
        if (resuser?.status === 200) {
          errorTrue = false;
          var config = {
            method: "POST",
            url: `${Suadi_fintech_URL}/passes`,
            headers: {
              Access: "*/*",
              Accept: "application/json, text/plain",
              "Content-Type": "application/json;charset=UTF-8",
            },
            data: JSON.stringify({
              userId: `${profileInfo.userId}`,
              userName: `${profileInfo?.firstName}`,
              status: "pending",
            }),
          };

          axiosClient(config)
            .then((respTour) => {
              if (resuser?.status === 200) {
                errorTrue = false;
                localStorage.setItem("navActive", ["1", "2", "3"]);
                navigate("/day-pass/applicationSubmitted");
                // navigate("/day-pass/review-details");
              } else {
                if (errorTrue === false) {
                  errorTrue = true;
                  return toast.error(respTour?.error?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }
            })
            .catch((error) => {
              if (errorTrue === false) {
                errorTrue = true;
                return toast.error(error?.response?.data?.error?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            });
        }
      })
      .catch((error) => {
        return toast.error(error?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  function openPrivacyPolicy() {
    const url =
      "https://hubportalapi.fintechsaudi.com/uploads/images/Fintech%20Saudi_Bilingual%20Privacy%20Policy_v2.2.pdf";
    window.open(url, "_blank");
  }

  return {
    modelShow1,
    setModelShow1,
    openPrivacyPolicy,
    privacyCheck,
    setPrivacyCheck,
    state,
    submitData,
    profileInfo,
    email,
    modelShow,
    setModelShow,
    navigate,
  };
};

export default UseReviewDetails;
