import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import wcImage from "../../images/footers/wcfooter1.svg";
import errorTour from "../../images/icons/errorTour.svg";
import tourImage from "../../images/icons/tourCardImage.svg";
import passImage from "../../images/icons/DayPassCardImage.svg";
import membershipImage from "../../images/icons/membershipCardImage.svg";
import UseWellcome from "./useWellcome";
import "./css/wellcome.css";

const Wellcome = () => {
  const { tourAvalibilty } = UseWellcome();

  return (
    <>
      <div className='ft-wc-main-box px-2'>
        <div className='p-3 mb-4 mt-4'>
          <Container fluid>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <span className='wctitle'>Hub by Fintech Saudi</span>
                <p className='wcsubtitle'>
                  Transforming Saudi Arabia into an innovative fintech hub with
                  a thriving and responsible fintech ecosystem.
                </p>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-2'>
                {tourAvalibilty ? (
                  <Card className='  wecard  p-3 h-100'>
                    <Link
                      to='/tours/emailInfo'
                      className='link '
                      onClick={() => {
                        localStorage.setItem("navActive", "");
                      }}
                    >
                      <Row>
                        <Col
                          xl={3}
                          lg={3}
                          md={12}
                          sm={3}
                          xs={3}
                          className='wcImageCol'
                        >
                          <img src={tourImage} alt='img' className='wcImage' />
                        </Col>
                        <Col xl={9} lg={9} md={12} sm={9} xs={9}>
                          <p className='wccardtitle'>Tour</p>
                          <p className='wccardsubtitle'>
                            Apply for a tour and get a chance to explore the
                            fintech hub space.
                          </p>
                        </Col>
                      </Row>
                    </Link>
                  </Card>
                ) : (
                  <Card className='  wecard  p-3  h-100'>
                    <Row>
                      <Col
                        xl={3}
                        lg={3}
                        md={12}
                        sm={3}
                        xs={3}
                        className='wcImageCol'
                      >
                        <img
                          src={tourImage}
                          alt='img'
                          className='wcImagedisabled'
                        />
                      </Col>
                      <Col xl={9} lg={9} md={12} sm={9} xs={9}>
                        <p className='disabled-card-h1'>Tour</p>
                        <p className='disabled-card-h2 '>
                          Apply for a tour and get a chance to explore the
                          fintech hub space.
                        </p>
                        <div className='d-flex'>
                          <img src={errorTour} alt='error icon' />
                          <span className='mx-2 wc-unavalibale-Message'>
                            Tours are currently unavailable
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-2 '>
                <Card className='  wecard  p-3  h-100'>
                  <Link
                    to='/day-pass/emailInfo'
                    className='link '
                    onClick={() => {
                      localStorage.setItem("navActive", "");
                    }}
                  >
                    <Row>
                      <Col
                        xl={3}
                        lg={3}
                        md={12}
                        sm={3}
                        xs={3}
                        className='wcImageCol'
                      >
                        <img src={passImage} alt='img' className='wcImage' />
                      </Col>
                      <Col xl={9} lg={9} md={12} sm={9} xs={9}>
                        <p className='wccardtitle'>Day Pass</p>
                        <p className='wccardsubtitle'>
                          Work on any day as required, without any long term
                          commitment.
                        </p>
                      </Col>
                    </Row>
                  </Link>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-2'>
                <Card className=' wecard h-100 p-3  h-100'>
                  <Link
                    to='/membership/emailInfo'
                    className='link '
                    onClick={() => {
                      localStorage.setItem("navActive", "");
                    }}
                  >
                    <Row>
                      <Col
                        xl={3}
                        lg={3}
                        md={12}
                        sm={3}
                        xs={3}
                        className='wcImageCol'
                      >
                        {" "}
                        <img
                          src={membershipImage}
                          alt='img'
                          className='wcImage'
                        />
                      </Col>
                      <Col xl={9} lg={9} md={12} sm={9} xs={9}>
                        <p className='wccardtitle'>Team Membership</p>
                        <p className='wccardsubtitle'>
                          Build your next idea at hub, enjoy dedicated space for
                          you team.
                        </p>
                      </Col>
                    </Row>
                  </Link>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <div className=' wcimgdiv2' style={{ height: "10vh" }}>
          <img src={wcImage} img='img' className='wc-bottom-img2' />
        </div>
      </div>
      <div className=' ftCss d-flex justify-content-center pt-3 '>
        <p className='ftCss1'>
          فنتك السعودية - جميع الحقوق محفوظة | Fintech Saudi - All Rights
          Reserved <span>&reg;</span>
        </p>
      </div>
    </>
  );
};

export default Wellcome;
