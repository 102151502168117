import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import FormInput from "../../../components/molicules/formInputs";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import Select from "react-select";
import { Link } from "react-router-dom";
import { UseProfileInfo } from "./useProfileInfo";
import PhoneInput from "react-phone-input-2";
import bottomPic from "../../../images/footers/footer-mobile.svg";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import "react-phone-input-2/lib/style.css";
import "./css/profile.css";

const options = [
  { value: "Employed", label: "Employed" },
  { value: "Self-Employed", label: "Self-Employed" },
  { value: "Not-Employed", label: "Not-Employed" },
];

const ProfileInfoDayPass = () => {
  const {
    fNameFunc,
    lNameFunc,
    pNameFunc,
    employementStatusFunc,
    occupationNameFunc,
    modelShow,
    setModelShow,
    twitterNameFunc,
    linkedinNameFunc,
    handelSubmit,
    profileInfo,
    setProfileData,
  } = UseProfileInfo();

  const isDisabled = () => {
    if (
      !profileInfo.firstName ||
      !profileInfo.lastName ||
      !profileInfo.phoneNum ||
      !profileInfo.employmentStatus
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="ebgcolor  p-4">
      <div className="allCardsPadding">
        <Row>
          <Col lg={10} md={10} sm={10} xs={10}>
            <span className="emainheading">
              <span
                onClick={() => {
                  setModelShow(true);
                }}
              >
                {/* <Link to='/day-pass/emailInfo'> */}{" "}
                <img
                  src={backArrow}
                  alt="arrow"
                  className="arrow_small_screen"
                />
                {/* </Link> */}
              </span>
              Profile Information
            </span>
            <p className="mailmainpheading2">
              Please provide the information below
            </p>
          </Col>

          <Col lg={2} md={2} sm={2} xs={2}>
            <div className="icon-box">
              <span onClick={() => setModelShow(true)}>
                <img src={CloseIcon} alt="logo" width={24} height={24} />
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={10} md={10} sm={10} xs={10}>
            <p className="emainheadingMobView my-2 ">Day Pass</p>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card className="p-4 form-card">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <span className="pcardheadings">Personal Information</span>
                </Col>
              </Row>
              <form onSubmit={handelSubmit}>
                <Row className="mt-2">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      First Name <span className="pStaricColor">*</span>
                    </p>
                    <FormInput
                      name="fName"
                      placeholder="Enter Your First Name"
                      onChange={(e) => {
                        fNameFunc(e);
                      }}
                      errorMessage="Required"
                      required={true}
                      maxLength={15}
                      min={3}
                      value={profileInfo?.firstName}
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Last Name <span className="pStaricColor">*</span>
                    </p>
                    <FormInput
                      name="lName"
                      placeholder="Enter Your Last Name"
                      onChange={(e) => {
                        lNameFunc(e);
                      }}
                      maxLength={15}
                      errorMessage="Required"
                      required={true}
                      min={3}
                      value={profileInfo?.lastName}
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Phone Number <span className="pStaricColor">*</span>
                    </p>
                    <PhoneInput
                      inputProps={{ maxLength: 16, readOnly: false }}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      country={"sa"}
                      placeholder="+966 500-500-550"
                      inputStyle={{
                        // borderColor: "black",
                        borderBottomColor: "rgba(0, 0, 0, 0.5)",
                        borderTopColor: "rgba(0, 0, 0, 0.5)",
                        borderLeftColor: "rgba(0, 0, 0, 0.5)",
                        borderRightColor: "rgba(0, 0, 0, 0.5)",
                        width: "100%",
                        height: "56px",
                      }}
                      style={{ width: "100%", height: "56px" }}
                      value={profileInfo?.phoneNum}
                      onChange={(e) => {
                        pNameFunc(e);
                      }}
                      required={true}
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <span className="pcardheadings">
                      Employment Information
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">
                      Employment Status <span className="pStaricColor">*</span>
                    </p>

                    <Select
                      placeholder="Employment Status"
                      value={{
                        label: profileInfo?.employmentStatus,
                        value: profileInfo?.employmentStatus,
                      }}
                      className="pi_Input"
                      options={options}
                      isMulti={false}
                      onChange={(e) => {
                        employementStatusFunc(e);
                      }}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className="pInputHeadings">Occupation</p>
                    <FormInput
                      name="Ocucupationname"
                      onChange={(e) => {
                        occupationNameFunc(e);
                      }}
                      required={false}
                      value={profileInfo?.occupationName}
                    />
                  </Col>
                </Row>

                {/* socials are comented  */}

                {/* <Row className='mt-4'>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <span className='pcardheadings'>Socials</span>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}></Col>
                </Row>

                <Row className='mt-2'>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className='pInputHeadings'>Twitter</p>
                    <FormInput
                      placeholder='twitter.com/username'
                      name='twitterName'
                      onChange={(e) => {
                        twitterNameFunc(e);
                      }}
                      required={false}
                      value={
                        profileInfo?.twitterName ? profileInfo?.twitterName : ""
                      }
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <p className='pInputHeadings'>Linkedin</p>
                    <FormInput
                      placeholder='linkedin/in/username'
                      name='linkedinName'
                      onChange={(e) => {
                        linkedinNameFunc(e);
                      }}
                      required={false}
                      value={
                        profileInfo?.linkedinName
                          ? profileInfo?.linkedinName
                          : ""
                      }
                    />
                  </Col>
                </Row> */}

                <Row className="mt-4 mb-4">
                  <Col lg={6} md={6} sm={12} xs={12}></Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    {/* <Link to='/day-pass/emailInfo'> */}{" "}
                    <Button
                      outline
                      color="secondary"
                      className="pButton w-100 mt-3 "
                      onClick={() => {
                        setModelShow(true);
                        // localStorage.setItem("navActive", "");
                      }}
                    >
                      Back
                    </Button>
                    {/* </Link> */}
                  </Col>
                  <Col lg={3} md={3} sm={12} xs={12}>
                    {/* {isDisabled() === true ? (
                      <Button
                        color='secondary'
                        onClick={setProfileData}
                        className='ebutton1 w-100 mt-3'
                        disabled={true}
                      >
                        Submit
                      </Button>
                    ) : ( */}
                    <Button
                      color="secondary"
                      onClick={setProfileData}
                      className="ebutton1 w-100 mt-3"
                      disabled={false}
                    >
                      Submit
                    </Button>
                    {/* )} */}
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <img src={bottomPic} alt="img" className="bottomImg" />
        </Col>
      </Row>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />
    </div>
  );
};

export default ProfileInfoDayPass;
