import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosClient from "../../../config/axios";

const UseCompanyInfo = () => {
  const { state } = useLocation();
  const {
    profileData,
    userEmail,
    companyInfoPrev,
    photoDataByApi1prev,
    photoDataByApiprev,
    teamArrPre,
    slotValuePre,
    dateValuePre,
    selectedFilesPrev,
    selectedFilesPrev1,
    teamArr,

    dateValue,
  } = state;

  var errorTrue = false;
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgress1, setUploadProgress1] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [modelShow, setModelShow] = useState(false);
  const [selectedFiles1, setSelectedFiles1] = useState({});
  const [photoDataByApi, setPhotoDataByApi] = useState([]);
  const [photoDataByApi1, setPhotoDataByApi1] = useState([]);
  const [requireRegisAndVat, setRequireRegisAndVat] = useState(false);
  const [operationalStatus, setoperationalStatus] = useState();

  const [companyInfo, setCompanyInfo] = useState({
    linkedinName: "",
    twitterName: "",
  });

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }
  const email = toLowerCase(userEmail);

  const navigate = useNavigate();

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  useMemo(() => {
    if (companyInfoPrev) {
      setCompanyInfo(companyInfoPrev);
      setoperationalStatus({
        value: companyInfoPrev?.operationalStatus,
        label: companyInfoPrev?.operationalStatus,
      });
    }
    if (photoDataByApi1prev) {
      setPhotoDataByApi1([photoDataByApi1prev]);
    }
    if (photoDataByApiprev) {
      setPhotoDataByApi([photoDataByApiprev]);
    }
    if(selectedFilesPrev){
    setSelectedFiles(selectedFilesPrev)
    }
    if(selectedFilesPrev1){
      setSelectedFiles1(selectedFilesPrev1)
      }
  }, []);

  // useMemo(
  //   () => {
  //     if (email) {
  //       setCompanyInfo({ ...companyInfo, emailAddress: email });
  //     }
  //     if (profileData?.phoneNum) {
  //       setCompanyInfo({ ...companyInfo, phoneNum: profileData?.phoneNum });
  //     }
  //   },
  //   600,
  //   []
  // );

  useMemo(() => {
    if (email) {
      setCompanyInfo((prevCompanyInfo) => ({
        ...prevCompanyInfo,
        emailAddress: toLowerCase(email),
      }));
    }
    if (profileData?.phoneNum) {
      setCompanyInfo((prevCompanyInfo) => ({
        ...prevCompanyInfo,
        phoneNum: profileData.phoneNum,
      }));
    }
  }, []);

  useEffect(() => {
    companyInfo?.registrationStatus === "Yes"
      ? setRequireRegisAndVat(true)
      : setRequireRegisAndVat(false);
  }, [companyInfo?.registrationStatus]);

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
  };

  const companyNameFunc = (e) => {
    var regex = /^[a-zA-Z0-9\s]*$/;;
    if (!regex.test(e.target.value)) {
        e.preventDefault();
        e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    }
    else{
    setCompanyInfo({ ...companyInfo, companyName: e.target.value });
    }
  };
  const emailAddressFunc = (e) => {
    setCompanyInfo({ ...companyInfo, emailAddress: e.target.value });
  };
  const operationalStatusFunc = (e) => {
    setoperationalStatus(e);
    setCompanyInfo({ ...companyInfo, operationalStatus: e?.value });
  };
  const registrationStatusFunc = (e) => {
    setCompanyInfo({ ...companyInfo, registrationStatus: e?.label });
  };

  const pNameFunc = (e) => {
    setCompanyInfo({ ...companyInfo, phoneNum: e });
  };

  const webSiteFunc = (e) => {
    setCompanyInfo({ ...companyInfo, webSite: e.target.value });
  };
  const twitterNameFunc = (e) => {
    setCompanyInfo({ ...companyInfo, twitterName: e.target.value });
  };

  const linkedinNameFunc = (e) => {
    setCompanyInfo({ ...companyInfo, linkedinName: e.target.value });
  };

  const companyDescriptionFunc = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "");

    setCompanyInfo({ ...companyInfo, companyDescription: sanitizedValue });
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };



 

  const handleAcceptedFiles = (files) => {

    // this is for uploading progress bar

    const simulateUpload = () => {
      const totalSize = files.reduce(
        (acc, file) => acc + file.size,
        0
      );
      let currentUploaded = 0;

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          // Simulating file upload progress
          currentUploaded += file.size;
          const progress = (currentUploaded / totalSize) * 100;
          setUploadProgress(progress);

          // After reaching 100%, you can reset the progress
          if (progress === 100) {
            setTimeout(() => {
              setUploadProgress(0);
            }, 1000);
          }
        };
        reader.readAsDataURL(file);
      });
    };

    simulateUpload();




    // end




    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    // setSelectedFiles(files);
    
    setSelectedFiles({
      htmlFormattedSize:files[0].htmlFormattedSize,
      path:files[0].path,
      lastModified:files[0].lastModified,
      lastModifiedDate:files[0].lastModifiedDate,
      name:files[0].name,
      size:files[0].size,
      type:files[0].type,
      webkitRelativePath:files[0].webkitRelativePath,
      preview:files[0].preview
  })
    // setPhotoDataByApi([files[0]?.preview]);
   

    //logo upload Api
    // var formdata = new FormData();
    // formdata.append("file", files[0]);

    // var config = {
    //   method: "post",
    //   url: `files`,
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     // Accept: "application/json, text/plain",
    //   },
    //   data: formdata,
    // };

    // axiosClient(config)
    //   .then(function (response) {
    //     if (response?.status === 200) {
    //       setPhotoDataByApi([response?.data?.files[0]?.originalname]);
    //     } else {
    //       toast.error("something went wrong to upload photo", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   })
    //   .catch(function (err) {
    //     toast.error(err?.response?.data?.error?.message, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //   });
  };

  const handleAcceptedFiles1 = (files) => {



     // this is for uploading progress bar

     const simulateUpload = () => {
      const totalSize = files.reduce(
        (acc, file) => acc + file.size,
        0
      );
      let currentUploaded = 0;

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          // Simulating file upload progress
          currentUploaded += file.size;
          const progress = (currentUploaded / totalSize) * 100;
          setUploadProgress1(progress);

          // After reaching 100%, you can reset the progress
          if (progress === 100) {
            setTimeout(() => {
              setUploadProgress1(0);
            }, 1000);
          }
        };
        reader.readAsDataURL(file);
      });
    };

    simulateUpload();




    // end


    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    // setSelectedFiles1(files);
    setSelectedFiles1({
      htmlFormattedSize:files[0].htmlFormattedSize,
      path:files[0].path,
      lastModified:files[0].lastModified,
      lastModifiedDate:files[0].lastModifiedDate,
      name:files[0].name,
      size:files[0].size,
      type:files[0].type,
      webkitRelativePath:files[0].webkitRelativePath,
      preview:files[0].preview
  })

    // upload Api
    // var formdata = new FormData();
    // formdata.append("file", files[0]);

    // var config = {
    //   method: "post",
    //   url: `files`,
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     // Accept: "application/json, text/plain",
    //   },
    //   data: formdata,
    // };

    // axiosClient(config)
    //   .then(function (response) {
    //     if (response?.status === 200) {
    //       setPhotoDataByApi1([response?.data?.files[0]?.originalname]);
    //     } else {
    //       toast.error("something went wrong to upload photo", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   })
    //   .catch(function (err) {
    //     toast.error(err?.response?.data?.error?.message, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //   });
  };

  const setCompanyDataFunc = () => {
    window.scrollTo(0, 0);

    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const urlExpression = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );

    const twitterExpression = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?twitter\\.com\\/[A-Za-z0-9_]{1,15}$"
    );
    const linkedInExpression = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?linkedin\\.com\\/[A-Za-z0-9\\-]+(\\/[A-Za-z0-9\\-]+)?"
    );
    if (
      companyInfo.companyName === undefined ||
      companyInfo.companyName === ""
    ) {
      return toast.error("Company Name Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      companyInfo.emailAddress === undefined ||
      companyInfo.emailAddress === ""
    ) {
      return toast.error("Email Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!regEmail.test(companyInfo.emailAddress)) {
      return toast.error("Please Enter  Correct format(abc@xyz.com)", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      companyInfo?.twitterName !== "" &&
      twitterExpression.test(companyInfo?.twitterName) === false
    ) {
      return toast.error("Twitter Link format is incorrect", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      companyInfo?.linkedinName !== "" &&
      linkedInExpression.test(companyInfo?.linkedinName) === false
    ) {
      return toast.error("LinkedIn Link format is incorrect", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      companyInfo.operationalStatus === undefined ||
      companyInfo.operationalStatus === ""
    ) {
      return toast.error("operational Status Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      companyInfo.registrationStatus === undefined ||
      companyInfo.registrationStatus === ""
    ) {
      return toast.error("Registration Status Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      companyInfo.phoneNum === undefined ||
      companyInfo.phoneNum === ""
    ) {
      return toast.error("Phone Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      requireRegisAndVat === true &&
      companyInfo.commercialRegister === undefined
    ) {
      return toast.error("Commercial Registration Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      requireRegisAndVat === true &&
      companyInfo.vatNumber === undefined
    ) {
      return toast.error("VAT Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    // for image required
    else if (
      requireRegisAndVat === true && (!selectedFiles ||
      selectedFiles?.preview === undefined)
    ) {
      return toast.error("Documents are required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      requireRegisAndVat === true && (!selectedFiles1 ||
      selectedFiles1?.preview === undefined)
    ) {
      return toast.error("Logo Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    //end
    else if (companyInfo.webSite === undefined || companyInfo.webSite === "") {
      return toast.error("WebSite Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (urlExpression.test(companyInfo.webSite) === false) {
      return toast.error("Please Enter website  Correct format(www.abc.com)", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let data = JSON.stringify({
        name: `${companyInfo?.companyName}`,
      });

      var config = {
        method: "post",
        url: "/memberships-verify",
        headers: {
          Access: "*/*",
          //   Content-Type : "Application/json"
          //   Authorization: ` ${bariertoken}`,
        },
        data: data,
      };

      axiosClient(config)
        .then(function (response) {
         
          if (response?.status === 200) {
            localStorage.setItem("navActive", ["1", "2", "3"]);
            errorTrue = false;

            navigate("/membership/teamInfo", {
              state: {
                userEmail: toLowerCase(email),
                profileData: profileData,
                companyInfoPrev: companyInfo,
                teamArrPre: teamArrPre,
                photoDataByApi: photoDataByApi,
                photoDataByApi1: photoDataByApi1,
                selectedFiles: selectedFiles,
                selectedFiles1: selectedFiles1,

                slotValuePre: slotValuePre,
                dateValuePre: dateValuePre,
              },
            });
          } else {
            if (errorTrue === false) {
              errorTrue = true;
              return toast.error(response?.error?.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        })
        .catch(function (error) {
          if (errorTrue === false) {
            errorTrue = true;
            return toast.error(error?.response?.data?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  };

  const backBtnFunc = () => {
    // setNavActive([...navActive, "2"]);
    localStorage.setItem("navActive", "1");
    navigate("/membership/profileInfo", {
      state: {
        userEmail: toLowerCase(email),
        profileData: profileData,
        selectedFiles: selectedFiles,
        selectedFiles1: selectedFiles1,
        teamArrPre: teamArrPre,
        slotValuePre: slotValuePre,
        dateValuePre: dateValuePre,
        companyInfoPrev: companyInfo,
        photoDataByApi1prev: photoDataByApi1,
        photoDataByApiprev: photoDataByApi,
      },
    });
  };
  return {
    uploadProgress1,
    uploadProgress,
    photoDataByApi,
    selectedFiles,
    pNameFunc,
    operationalStatusFunc,
    registrationStatusFunc,
    webSiteFunc,
    companyDescriptionFunc,
    twitterNameFunc,
    linkedinNameFunc,
    companyNameFunc,
    handelSubmit,
    companyInfo,
    setCompanyInfo,
    setCompanyDataFunc,
    // getProfileobject,
    operationalStatus,
    email,
    emailAddressFunc,
    handleAcceptedFiles,
    handleAcceptedFiles1,
    selectedFiles1,
    setSelectedFiles1,
    setSelectedFiles,
    photoDataByApi1,
    modelShow,
    setModelShow,
    backBtnFunc,
    setPhotoDataByApi1,
    setPhotoDataByApi,
    requireRegisAndVat,
  };
};

export { UseCompanyInfo };
// export { UsecompanyInfo };
