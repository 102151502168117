import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import userlogo from "./../../../images/icons/userlogo.svg";
import usercall from "./../../../images/icons/call.svg";
import usercorporatefare from "./../../../images/icons/corporate_fare.svg";
import usermail from "./../../../images/icons/mail.svg";
import Vector7 from "./../../../images/icons/Vector7.svg";
import Vector8 from "./../../../images/icons/Vector8.svg";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import calendr from "../../../images/icons/calendr.svg";
import linkedin from "./../../../images/icons/linkedin.svg";
import badge_fill from "./../../../images/icons/badge_fill.svg";
import "./css/styles.css";
import UseReviewDetails from "./useReviewDetails";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import PrivacyPolicyModel from "../../../components/molicules/privacyPolicyModel";

const Index = () => {
  const Navigation = useNavigate();
  const {
    modelShow1,
    setModelShow1,
    openPrivacyPolicy,
    privacyCheck,
    setPrivacyCheck,
    getProfileobject1,
    dateValue,
    slotValue,
    profiledata,
    navigate,
    reschduleData,
    scheduledSubmitFunc,
    reschduleTourFunc,
    profileInfo,
    email,
    modelShow,
    setModelShow,

    tourId,
  } = UseReviewDetails();

  return (
    <div>
      <div className="ebgcolor p-4">
        <div className="allCardsPadding">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <span className="emainheading">
                <img
                  src={backArrow}
                  alt="arrow"
                  className="arrow_small_screen"
                  onClick={() => {
                    localStorage.setItem("navActive", ["1", "2"]);
                    navigate("/tours/scheduleInfo", {
                      state: {
                        slotValueFromNext: slotValue,
                        tourIdFromNext: tourId,
                        profiledata: reschduleData
                          ? getProfileobject1?.user
                          : profiledata,
                        dateValueFromNext: dateValue,
                      },
                    });
                  }}
                />
                Review Details
              </span>
              <p className="mailmainpheading2">
                Please confirm the details below
              </p>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              <div className="icon-box">
                <span onClick={() => setModelShow(true)}>
                  <img src={CloseIcon} alt="logo" width={24} height={24} />
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <p className="emainheadingMobView my-2 ">Tour</p>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Card className="p-4 form-card">
                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Personal details </p>
                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      localStorage.setItem("navActive", "1");
                      Navigation("/tours/profileInfo", {
                        state: {
                          slotValueFromNext: slotValue,
                          tourIdFromNext: tourId,
                          profileDataFromNextPage: profiledata,
                          dateValueFromNext: dateValue,
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>
                <div className="d-flex">
                  <img src={userlogo} alt="img" />
                  <span className="rds-h2 mx-2">
                    {reschduleData ? getProfileobject1?.user?.fullName : ""}{" "}
                    {profiledata
                      ? profiledata?.state?.ProfileData?.firstName
                      : ""}{" "}
                    {profiledata
                      ? profiledata?.state?.ProfileData?.lastName
                      : ""}
                  </span>
                </div>
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usermail} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Email Address</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {reschduleData ? getProfileobject1?.user?.email : ""}
                      {profiledata ? profiledata?.state?.Email : ""}
                    </p>
                  </Col>

                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usercall} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Phone number</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      <p>
                        {" "}
                        {reschduleData
                          ? getProfileobject1?.user?.phoneNumber
                          : ""}
                        {profiledata
                          ? profiledata?.state?.ProfileData?.phoneNum
                          : ""}
                      </p>
                    </p>
                  </Col>
                </Row>
                <hr />

                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Employment information </p>
                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      localStorage.setItem("navActive", "1");
                      Navigation("/tours/profileInfo", {
                        state: {
                          slotValueFromNext: slotValue,
                          tourIdFromNext: tourId,
                          profileDataFromNextPage: profiledata,
                          dateValueFromNext: dateValue,
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={badge_fill} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Employment status</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {reschduleData
                        ? getProfileobject1?.user?.employmentStatus
                        : ""}
                      {profiledata
                        ? profiledata?.state?.ProfileData?.employmentStatus
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={Vector7} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">Occupation</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {reschduleData ? getProfileobject1?.user?.occupation : ""}
                      {profiledata
                        ? profiledata?.state?.ProfileData?.occupationName
                        : ""}
                    </p>
                  </Col>
                </Row>
                {/* socials are comented */}

                {/* <hr />

                <p className='rds-h1'>Socials </p>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className='d-flex'>
                      <img src={Vector8} alt='img' />{" "}
                      <p className='mx-2 mt-3 rds-h3'>Twitter</p>
                    </div>
                    <p className='rds-h2 mx-4'>
                      {" "}
                      {reschduleData ? getProfileobject1?.user?.twitter : ""}
                      {profiledata
                        ? profiledata?.state?.ProfileData?.twitterName
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className='d-flex'>
                      <img src={linkedin} alt='img' />
                      <p className='mx-2 mt-3 rds-h3'>LinkedIn</p>
                    </div>
                    <p className='rds-h2 mx-4'>
                      {reschduleData ? getProfileobject1?.user?.linkedin : ""}
                      {profiledata
                        ? profiledata?.state?.ProfileData?.linkedinName
                        : ""}
                    </p>
                  </Col>
                </Row> */}

                <hr />

                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Schedule details </p>

                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      localStorage.setItem("navActive", "2");
                      Navigation("/tours/scheduleInfo/", {
                        state: {
                          slotValueFromNext: slotValue,
                          tourIdFromNext: tourId,
                          profiledata: profiledata,
                          dateValueFromNext: dateValue,
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={calendr} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h2">
                        {moment(dateValue).format("DD MMMM YYYY")}
                      </p>
                    </div>
                    <p className=" rds-h3 mx-4">
                      {" "}
                      {slotValue?.startTime}
                      {" - "} {slotValue?.endTime}
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="d-flex">
          <input
            type="checkbox"
            style={{ height: 22, width: 22 }}
            checked={privacyCheck}
            className="mt-3 mx-2"
            onChange={() => {
              setPrivacyCheck(!privacyCheck);
            }}
          ></input>
          <Link to={"/privacy-policy"}>
            <p
              className="mt-3 mx-3 rds-h2 with-arrow"
              // onClick={() => setModelShow1(true)}
            >
              I agree to the privacy policy of Fintech Hub.
            </p>
          </Link>
        </div>
      </div>

      <Row className="mb-3 mx-2">
        <Col lg={8} md={8} sm={12} xs={12}></Col>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Button
            outline
            color="secondary"
            className="pButton w-100 mt-3 "
            onClick={() => {
              localStorage.setItem("navActive", ["1", "2"]);
              navigate("/tours/scheduleInfo/", {
                state: {
                  slotValueFromNext: slotValue,
                  tourIdFromNext: tourId,
                  profiledata: profiledata,
                  dateValueFromNext: dateValue,
                },
              });
            }}
          >
            Back
          </Button>
        </Col>
        <Col lg={2} md={2} sm={12} xs={12}>
          <Button
            color="secondary"
            onClick={
              reschduleData === "true" ? reschduleTourFunc : scheduledSubmitFunc
            }
            className="ebutton1 w-100 mt-3"
            disabled={!privacyCheck}
          >
            Submit
          </Button>
        </Col>
      </Row>

      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />

<PrivacyPolicyModel
       show={modelShow1}
       setModelShow={setModelShow1}
       />
    </div>
  );
};

export default Index;
