import { useState } from "react";
import axiosClient from "../../../config/axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Suadi_fintech_URL } from "../../../config/url";
import moment from "moment";
import {
  getProfile,
  getProfileDataOfReschduleUser,
} from "../../../APIS/profileInfo";
import { useQuery } from "react-query";

const UseReviewDetails = () => {
  const navigate = useNavigate();
  const [modelShow1,
    setModelShow1] = useState(false);
  const { state } = useLocation();
  const [privacyCheck, setPrivacyCheck] = useState(false);

  const { slotValue, tourId, dateValue, profiledata } = state;

  const [modelShow, setModelShow] = useState(false);
  const reschduleData = localStorage.getItem("reschduleFlow");
  const reschduleFlowTourId = localStorage.getItem("reschduleFlowTourId");
  var errorTrue = false;

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  // ************Get Api OF user data if it is reschdule**********
  const { data: getProfileobjOfReschdule } = useQuery(
    ["reschdulesUserData", reschduleFlowTourId],
    () => getProfileDataOfReschduleUser(reschduleFlowTourId),
    {
      retry: 0,
    }
  );
  const getProfileobject1 = getProfileobjOfReschdule?.res?.data;

  // *****************  End***************

  const scheduledSubmitFunc = () => {
    window.scrollTo(0, 0);

    var config = {
      method: "PATCH",
      url: `${Suadi_fintech_URL}/users/${profiledata?.state?.ProfileData?.userId}`,
      headers: {
        Access: "*/*",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({
        email: toLowerCase(profiledata?.state?.Email),
        firstName: `${profiledata?.state?.ProfileData?.firstName}`,
        lastName: `${profiledata?.state?.ProfileData?.lastName}`,
        fullName:
          `${profiledata?.state?.ProfileData?.firstName} ` +
          `${profiledata?.state?.ProfileData?.lastName}`,
        phoneNumber: `${profiledata?.state?.ProfileData?.phoneNum}`,
        employmentStatus: `${profiledata?.state?.ProfileData?.employmentStatus}`,
        occupation: `${profiledata?.state?.ProfileData?.occupationName}`,
        twitter: `${profiledata?.state?.ProfileData?.twitterName}`,
        linkedin: `${profiledata?.state?.ProfileData?.linkedinName}`,
        portfolioLink: `${profiledata?.state?.ProfileData?.portfolioLink}`,
      }),
    };

    axiosClient(config)
      .then((res) => {
        if (res.status === 200) {
          errorTrue = false;
          var config = {
            method: "POST",
            url: `${Suadi_fintech_URL}/tours/${tourId}`,
            headers: {
              Access: "*/*",
              Accept: "application/json, text/plain",
              "Content-Type": "application/json;charset=UTF-8",
            },
            data: JSON.stringify({
              userId: `${profiledata?.state?.ProfileData?.userId}`,
              userName: `${profiledata?.state?.ProfileData?.firstName}`,
              slot: slotValue,
              date: moment(dateValue),
            }),
          };

          axiosClient(config)
            .then((respTour) => {
              if (respTour.status === 200) {
                errorTrue = false;
                localStorage.setItem("navActive", ["1", "2", "3", "4"]);
                navigate("/tours/applicationSubmitted");
              } else {
                if (errorTrue === false) {
                  errorTrue = true;
                  return toast.error(respTour?.error?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }
            })
            .catch((error) => {
              return toast.error(error?.response?.data?.error?.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        }
      })
      .catch((error) => {
        if (errorTrue === false) {
          errorTrue = true;
          return toast.error(error?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const reschduleTourFunc = () => {
    window.scrollTo(0, 0);

    let data = JSON.stringify({
      status: "rescheduled",
      date: moment(dateValue),
    });

    var config = {
      method: "patch",
      url: `/reschedule-tours/${reschduleFlowTourId}?slotId=${slotValue?.id}`,
      headers: {
        Access: "*/*",
        //   Content-Type : "Application/json"
        //   Authorization: ` ${bariertoken}`,
      },
      // param: { slotId: slotValue?.id },
      data: data,
    };

    axiosClient(config)
      .then(function (response) {
        if (response?.data?.statusCode === 200) {
          errorTrue = false;
          setModelShow(false);

          localStorage.setItem("navActive", ["1", "2", "3", "4", "5"]);
          navigate("/tours/applicationSubmitted");
        } else {
          if (errorTrue === false) {
            errorTrue = true;
            setModelShow(false);
            return toast.error(response?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      })
      .catch(function (error) {
        if (errorTrue === false) {
          errorTrue = true;
          return toast.error(error?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  function openPrivacyPolicy() {
    const url =
      "https://hubportalapi.fintechsaudi.com/uploads/images/Fintech%20Saudi_Bilingual%20Privacy%20Policy_v2.2.pdf";
    window.open(url, "_blank");
  }

  return {
    openPrivacyPolicy,
    profiledata,
    modelShow,
    setModelShow,
    reschduleTourFunc,
    scheduledSubmitFunc,
    reschduleTourFunc,
    reschduleData,
    navigate,
    slotValue,
    dateValue,
    reschduleFlowTourId,
    tourId,
    getProfileobject1,
    privacyCheck,
    setPrivacyCheck,
    modelShow1,
    setModelShow1,
  };
};

export default UseReviewDetails;
