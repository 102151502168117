import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import userlogo from "./../../../images/icons/userlogo.svg";
import usercall from "./../../../images/icons/call.svg";
import usercorporatefare from "./../../../images/icons/corporate_fare.svg";
import backArrow from "../../../images/icons/arrow_back_ios.svg";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import usermail from "./../../../images/icons/mail.svg";
import Vector7 from "./../../../images/icons/Vector7.svg";
import Vector8 from "./../../../images/icons/Vector8.svg";
import linkedin from "./../../../images/icons/linkedin.svg";
import badge_fill from "./../../../images/icons/badge_fill.svg";
import "./css/styles.css";
import { Link, useNavigate } from "react-router-dom";
import UseReviewDetails from "./useReviewDetails";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import PrivacyPolicyModel from "../../../components/molicules/privacyPolicyModel";

const Index = () => {
  const Navigation = useNavigate();
  const {
    modelShow1,
    setModelShow1,
    privacyCheck,
    setPrivacyCheck,
    navigate,
    submitData,
    profileInfo,
    email,
    modelShow,
    setModelShow,
  } = UseReviewDetails();

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  return (
    <div>
      <div className="ebgcolor p-4">
        <div className="allCardsPadding">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <span className="emainheading">
                {" "}
                <img
                  src={backArrow}
                  alt="arrow"
                  className="arrow_small_screen"
                  onClick={() => {
                    localStorage.setItem("navActive", ["1"]);
                    navigate("/day-pass/profileInfo", {
                      state: {
                        profileDataFromNextPage: profileInfo,
                        email: toLowerCase(email),
                      },
                    });
                  }}
                />
                Review Details
              </span>
              <p className="mailmainpheading2">
                Please confirm the details below
              </p>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              <div className="icon-box">
                <span onClick={() => setModelShow(true)}>
                  <img src={CloseIcon} alt="logo" width={24} height={24} />
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <p className="emainheadingMobView my-2 ">Day Pass</p>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Card className="p-4 form-card">
                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Personal details </p>

                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      localStorage.setItem("navActive", ["1"]);
                      Navigation("/day-pass/profileInfo", {
                        state: {
                          profileDataFromNextPage: profileInfo,
                          email: toLowerCase(email),
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>

                <div className="d-flex">
                  <img src={userlogo} alt="img" />{" "}
                  <span className="rds-h2 mx-2">
                    {profileInfo?.firstName} {profileInfo?.lastName}
                  </span>
                </div>
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usermail} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Email Address</p>
                    </div>
                    <p className="rds-h2 mx-4"> {email}</p>
                  </Col>
                  {/* <Col lg={4} md={4} sm={12} xs={12}>
                    <div className='d-flex'>
                      <img src={usercorporatefare} alt='img' />
                      <p className='mx-2 mt-3 rds-h3'>Company name</p>
                    </div>
                    <p className='rds-h2 mx-4'>
                      {" "}
                      {profileInfo?.companyName ? profileInfo?.companyName : ""}
                    </p>
                  </Col> */}
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={usercall} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Phone number</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      <p>{profileInfo?.phoneNum}</p>
                    </p>
                  </Col>
                </Row>
                <hr />

                <div className="d-flex justify-content-between">
                  <p className="rds-h1">Employment information </p>

                  <p
                    type="button"
                    className="rds-h1"
                    onClick={() => {
                      localStorage.setItem("navActive", ["1"]);
                      Navigation("/day-pass/profileInfo", {
                        state: {
                          profileDataFromNextPage: profileInfo,
                          email: toLowerCase(email),
                        },
                      });
                    }}
                  >
                    Edit
                  </p>
                </div>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={badge_fill} alt="img" />{" "}
                      <p className="mx-2 mt-3 rds-h3">Employment status</p>
                    </div>
                    <p className="rds-h2 mx-4">
                      {" "}
                      {profileInfo?.employmentStatus}
                    </p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className="d-flex">
                      <img src={Vector7} alt="img" />
                      <p className="mx-2 mt-3 rds-h3">Occupation</p>
                    </div>
                    <p className="rds-h2 mx-4">{profileInfo?.occupationName}</p>
                  </Col>
                </Row>

                {/* socials are commented  */}

                {/* <hr />

                <p className='rds-h1'>Socials </p>

                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className='d-flex'>
                      <img src={Vector8} alt='img' />{" "}
                      <p className='mx-2 mt-3 rds-h3'>Twitter</p>
                    </div>
                    <p className='rds-h2 mx-4'>{profileInfo?.twitterName}</p>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <div className='d-flex'>
                      <img src={linkedin} alt='img' />
                      <p className='mx-2 mt-3 rds-h3'>LinkedIn</p>
                    </div>
                    <p className='rds-h2 mx-4'>{profileInfo?.linkedinName}</p>
                  </Col>
                </Row> */}
              </Card>
              <div className="d-flex">
                <input
                 style={{ height: 22, width: 22 }}
                  type="checkbox"
                  className="mt-3 mx-2"
                  checked={privacyCheck}
                  onChange={() => {
                    setPrivacyCheck(!privacyCheck);
                  }}
                ></input>
                 <Link to={"/privacy-policy"}>
                  <p
                    className="mt-3 mx-3 rds-h2 with-arrow"
                    // onClick={() => setModelShow1(true)}
                  >
                    I agree to the privacy policy of Fintech Hub.
                  </p>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col lg={8} md={8} sm={12} xs={12}></Col>
            <Col lg={2} md={2} sm={6} xs={6}>
              <Button
                outline
                color="secondary"
                className="pButton w-100 mt-3 "
                onClick={() => {
                  localStorage.setItem("navActive", ["1"]);
                  navigate("/day-pass/profileInfo", {
                    state: {
                      profileDataFromNextPage: profileInfo,
                      email: toLowerCase(email),
                    },
                  });
                }}
              >
                Back
              </Button>
            </Col>
            <Col lg={2} md={2} sm={12} xs={12}>
              <Button
                color="secondary"
                onClick={submitData}
                className="ebutton1 w-100 mt-3"
                disabled={!privacyCheck}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />


<PrivacyPolicyModel
       show={modelShow1}
       setModelShow={setModelShow1}
       />
    </div>
  );
};

export default Index;
