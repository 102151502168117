import { useState } from "react";
import { useQuery } from "react-query";
import axiosClient from "../../../../config/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getUserEmail } from "../../../../APIS/reschedule";

const UseRescheduleVerification = () => {
  const navigate = useNavigate();
  const [verifyInput, setVerifyInput] = useState("");
  const [sendButtonText, setSendButtonText] = useState("Send Code");
  const [resendButtonEnabled, setResendButtonEnabled] = useState(false);

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  // const history = useHistory();
  const { userId, tourId } = useParams();
  const verifyInputFunc = (e) => {
    setVerifyInput(e.target.value);
  };

  // ************Get Api OF email**********
  const { data: getEmailobj } = useQuery(
    ["tourrescheduleEmail"],
    () => getUserEmail(userId),
    {
      retry: 0,
    }
  );
  const getEmailobject = getEmailobj?.res?.data;
  const emailInput = toLowerCase(getEmailobject?.userEmail);
  // *****************  End***************

  const emailSubmitFunc = () => {
    setSendButtonText("Resend Code");
    setResendButtonEnabled(true);
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(emailInput)) {
      return toast.error("Please Enter Correct format(abc@xyz.com)", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // setVerificaitionEnablbe(true)

      let data = JSON.stringify({
        email: toLowerCase(emailInput),
      });

      var config = {
        method: "put",
        url: "/users/request-create-otp",
        headers: {
          Access: "*/*",
          //   Content-Type : "Application/json"
          //   Authorization: ` ${bariertoken}`,
        },
        data: data,
      };

      axiosClient(config)
        .then(function (response) {
          if (response?.statusText === "OK") {
            // setUserId1(response?.data?.userId);
            return toast.error(
              "Verification Code is sent to you Email Address",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          } else {
            return toast.error("something went wrong", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {});
    }
  };

  const submitVerificationCodeFunc = () => {
    let data = JSON.stringify({
      otp: Number(verifyInput),
      id: `${userId}`,
    });

    var config = {
      method: "post",
      url: "/users/otp-verify",
      headers: {
        Access: "*/*",
        //   Content-Type : "Application/json"
        //   Authorization: ` ${bariertoken}`,
      },
      data: data,
    };

    axiosClient(config)
      .then(function (response) {
        if (response?.statusText === "OK") {
          navigate(`/reschduled-tour/${tourId}`);
        } else {
          return toast.error("Please Enter Correct Code", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {});

    // }
  };
  return {
    getEmailobject,
    emailSubmitFunc,
    resendButtonEnabled,
    setResendButtonEnabled,
    sendButtonText,
    verifyInputFunc,
    submitVerificationCodeFunc,
  };
};

export default UseRescheduleVerification;
