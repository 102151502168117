import { Suadi_fintech_URL } from "../config/url";
import axios from "axios";

// **************axois**********

export const getUserEmail = async (email) => {
  if (email) {
    const res = await axios.get(`${Suadi_fintech_URL}/users/email/${email}`);
    // if (!res.ok) {
    //   const resJson = await res.json();
    //   throw new Error(resJson.error.message);
    // }
    // const data = await res.json();

    return {
      res,
    };
  }
};
