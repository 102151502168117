import moment from "moment";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import Reschedule from "../../.././images/icons/reschdule.svg";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import ReschduleModel from "../../../components/molicules/rescheduleApplicationModel";
import UseReschduleTour from "./useReschduleTour";
import "./css/reschdule.css";

const ReschduledTour = () => {
  const {
    getTourDetailsobject,
    modelShow,
    modelShow1,
    setModelShow,
    cancelTourFunc,
    reSchduleTourFunc,
    setModelShow1,
    toCamelCase,
  } = UseReschduleTour();

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className='mt-3  rs_left_card'
          >
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <p className='rs_card_h3 mt-3 '> Tour details</p>
                </Col>
              </Row>
              <div className='rs_main_div  pt-4 px-3 '>
                <Row>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    <p className='rs_card_h1'>Schedule</p>
                    <p className='rs_card_h2'>
                      {moment(getTourDetailsobject?.date).format("DD/MM/YYYY")}
                    </p>
                    <p className='rs_card_h2'>{`${
                      getTourDetailsobject?.slot?.startTime
                    }${" - "}${getTourDetailsobject?.slot?.endTime}  `}</p>
                  </Col>
                  <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                    <p className='rs_card_h1 rs_card_h11'>Status</p>

                    <p className='rs_card_h4'>
                      {toCamelCase(getTourDetailsobject?.status) ===
                      "Rescheduled"
                        ? " Confirmed"
                        : toCamelCase(getTourDetailsobject?.status)}
                    </p>
                  </Col>
                </Row>
                {getTourDetailsobject?.status === "deny" ||
                toCamelCase(getTourDetailsobject?.status) === "Rescheduled" ? (
                  ""
                ) : (
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className='rs_justify'
                    >
                      <label
                        className='rs_button_label1 mr-3'
                        onClick={() => {
                          setModelShow1(true);
                        }}
                      >
                        Reschedule
                      </label>

                      <label
                        className='rs_card_h11 rs_button_label1'
                        onClick={() => setModelShow(true)}
                      >
                        Cancel
                      </label>
                    </Col>
                  </Row>
                )}
              </div>
            </Container>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className='p-4'>
            <img src={Reschedule} alt='icon' className='rs_img_h' />
          </Col>
        </Row>
      </Col>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Tour`}
        body={`Are you sure you want to cancel this Tour?`}
        link=''
        onClick={cancelTourFunc}
      />

      <ReschduleModel
        show={modelShow1}
        setModelShow={setModelShow1}
        header={`Reschedule Tour`}
        body={`Are you sure you want to reschedule your tour?`}
        link='/tours/scheduleInfo/'
        onClick={reSchduleTourFunc}
      />
    </Row>
  );
};

export default ReschduledTour;
