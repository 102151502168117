import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Row } from "reactstrap";
import TourNav from "./tourNav";
import "./css/tours.css";

const Tours = () => {
  return (
    <div className="scroller" >
      <Row className='mt-3 '>
        <Col md={3} xl={3} lg={3}>
          <TourNav  />
        </Col>
        <Col md={9} xl={9} lg={9} className='fullHight '>
          <div className='mainContainer'>
            <Outlet className='' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Tours;
