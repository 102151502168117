import { useContext, useEffect, useMemo } from "react";
import uuid from "react-uuid";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NavContext } from "../../../context/NavContext";
import axiosClient from "../../../config/axios";

const UseTeamInfo = () => {
  const uniqueId = uuid();
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    dateValue,
    profileData,
    companyInfoPrev,
    companyInfo,
    photoDataByApi,
    photoDataByApi1,
    teamArrPre,
    selectedFiles,
    selectedFiles1,
    slotValuePre,
    dateValuePre,
    userEmail,
  } = state;

  const { navActive, setNavActive } = useContext(NavContext);
  const [memberCount, setMemberCount] = useState(1);
  const [modelShow, setModelShow] = useState(false);
  const [teamArr, setTeamArr] = useState([]);
  const [teamInfo, setTeamInfo] = useState({});
  const [fullfill, setFullfill] = useState(false);
  const [usersWhichAreAlreadyExist, setUsersWhichAreAlreadyExist] = useState(
    []
  );

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);
  // comenting for testing
  // useEffect(() => {
  //   setTeamArr([...teamArr, { id: uniqueId }]);
  // }, []);

  // coding below as test

  useMemo(() => {
    if (teamArrPre) {
      setTeamArr(teamArrPre);
    }
  }, []);

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  useMemo(() => {
    if (!teamArrPre) {
      setTeamArr([
        ...teamArr,
        {
          id: uniqueId,
          name: profileData?.firstName + " " + profileData?.lastName,
          // email: companyInfo?.emailAddress,
          email: toLowerCase(userEmail),
          phoneNumber: profileData?.phoneNum,
          // title: profileData?.workTitle,
          // pName: profileData?.phoneNum,
          workTitle: profileData?.workTitle,
        },
      ]);
    }
  }, []);

  const addMember = () => {
    teamArr.push({ id: uniqueId });
    setTeamInfo();
    setMemberCount(memberCount + 1);
  };

  const setTeamDataFunc = (e) => {};

  // this function checks the emails are unique or not

  function checkUniqueEmails(array) {
    const emailSet = new Set();

    for (let i = 0; i < array.length; i++) {
      const emailObj = array[i];
      const { email } = emailObj;

      if (emailSet.has(email)) {
        // Email is repeated
        return false;
      }

      emailSet.add(toLowerCase(email));
    }

    // All emails are unique
    return true;
  }

  // this function checks the phone numbers are unique or not

  function checkUniquephoneNumber(array) {
    const phoneSet = new Set();

    for (let i = 0; i < array.length; i++) {
      const phoneObj = array[i];
      const { phoneNumber } = phoneObj;

      if (phoneSet.has(phoneNumber)) {
        // phoneNumber is repeated
        return false;
      }

      phoneSet.add(phoneNumber);
    }

    // All phones Numbers are unique
    return true;
  }

  const teamsCreateFunc = (e) => {
    // first of all loop is for check admin

    var isAdmin = false;
    var isPaying = false;

    for (let i = 0; i < teamArr.length; i++) {
      if (teamArr[i].isAdministrator === true) {
        isAdmin = true;
        break;
      }
    }

    for (let i = 0; i < teamArr.length; i++) {
      if (teamArr[i].isPayingMember === true) {
        isPaying = true;
        break;
      }
    }

    if (isAdmin === false) {
      return toast.error("please make Administrator of team", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (isPaying === false) {
      return toast.error("please make Paying Member", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (checkUniqueEmails(teamArr) === false) {
      return toast.error("Emails are Repeating", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (checkUniquephoneNumber(teamArr) === false) {
      return toast.error("phone number  Repeating", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      
      // my working code is below

      let regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let isAnyError=true
// ******************hizb
teamArr?.forEach((e) => {
  if (e.email === undefined || e.email === "") {
    setFullfill(false);
    isAnyError=false
    return toast.error("Email Required", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if (!regEmail.test(e.email)) {
    setFullfill(false);
    isAnyError=false

    return toast.error("Please Enter Correct format(abc@xyz.com)", {
      position: toast.POSITION.TOP_RIGHT,
    });
    
  } else if (e.name === undefined || e.name === "") {
    setFullfill(false);
    isAnyError=false

    return toast.error("Member Name is Required", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if (e.phoneNumber === undefined || e.phoneNumber === "") {
    setFullfill(false);
    isAnyError=false

    return toast.error("Phone Number is Requied", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if (e.workTitle === undefined || e.workTitle === "") {
    setFullfill(false);
    isAnyError=false

    return toast.error("Work Title is Required", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
});
// ******************
if(!isAnyError){
  return
}

      teamArr?.forEach((e) => {
      
          //this Api checks the emil of members already in anyother team or not

          var config = {
            method: "post",
            url: "/users/verify-nexudus",
            headers: {
              Access: "*/*",
              //   Content-Type : "Application/json"
              //   Authorization: ` ${bariertoken}`,
            },
            data: JSON.stringify({ members: teamArr }),
          };

          axiosClient(config)
            .then(function (response) {
              if (
                response?.status === 200 &&
                response?.data?.isNexudusExists === false
              ) {
                // if the state below will be true then we will move on next page
                setFullfill(true);
              } else if (response?.data?.users?.length > 0) {
                setUsersWhichAreAlreadyExist(response?.data?.users);
                return toast.error("users already exist in another team", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch(function (error) {
              return toast.error(error?.response?.data?.error?.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
            
        
      });
    }
  };

  useEffect(() => {
    if (fullfill === true) {
      localStorage.setItem("navActive", ["1", "2", "3", "4"]);
      navigate("/membership/scheduleMemberInfo", {
        state: {
          profileData: profileData,
          companyInfoPrev: companyInfoPrev,
          teamArrPre: teamArr,
          photoDataByApi: photoDataByApi,
          photoDataByApi1: photoDataByApi1,
          selectedFiles: selectedFiles,
          selectedFiles1: selectedFiles1,
          slotValuePre: slotValuePre,
          dateValuePre: dateValuePre,
        },
      });
    }
    return () => {
      setFullfill(false);
    };
  }, [fullfill]);

  const payingMember = (e, id, index) => {
    const newTeam = teamArr.map((team) => {
      return { ...team, isPayingMember: false };
    });

    setTeamInfo({ ...teamInfo, isPayingMember: e });
    const object = newTeam.find((e) => e.id === id);
    const newArr = [...newTeam];
    const newObject = { ...object, isPayingMember: e };
    newArr[index] = newObject;
    setTeamArr(newArr);
  };

  const administratorFunc = (e, id, index) => {
    const newTeam = teamArr.map((team) => {
      return { ...team, isAdministrator: false };
    });

    const object = newTeam.find((e) => e.id === id);
    const newArr = [...newTeam];
    const newObject = { ...object, isAdministrator: e };
    newArr[index] = newObject;
    setTeamArr(newArr);
  };

  const phoneHandle = (e, id, index) => {
    const object = teamArr.find((e) => e.id === id);
    const value = e;
    const newArr = [...teamArr];
    const newObject = { ...object, phoneNumber: e };
    newArr[index] = newObject;
    setTeamArr(newArr);
  };

  const handleChange = (e, id, index) => {
    const object = teamArr.find((e) => e.id === id);
    const value = e.target.value;
    const newArr = [...teamArr];

    if (e.target.name === "name" || e.target.name === "workTitle") {
      const input = e.target.value;
      const filteredInput = input.replace(/[^A-Za-z\s]/g, ""); // Filter out non-alphabetic characters except spaces
      const newObject = {
        ...object,
        [e.target.name]: filteredInput,
      };
      newArr[index] = newObject;
      setTeamArr(newArr);
    } else {
      const newObject = {
        ...object,
        [e.target.name]: toLowerCase(e.target.value),
      };
      newArr[index] = newObject;
      setTeamArr(newArr);
    }
  };

  const backBtnFunc = () => {
    localStorage.setItem("navActive", ["1", "2"]);
    navigate("/membership/companyInfo", {
      state: {
        profileData: profileData,
        teamArrPre: teamArr,
        slotValuePre: slotValuePre,
        dateValuePre: dateValuePre,
        // companyInfoPrev: companyInfoPrev,
        companyInfoPrev: companyInfoPrev,
        selectedFilesPrev: selectedFiles,
        selectedFilesPrev1: selectedFiles1,
        photoDataByApi1prev: photoDataByApi1,
        photoDataByApiprev: photoDataByApi,
      },
    });
  };

  const delObjectFunc = (e) => {
    const newArr = teamArr?.filter((event) => {
      return event?.id?.toString() != e;
    });
    setTeamArr(newArr);
  };

  return {
    usersWhichAreAlreadyExist,
    setTeamDataFunc,
    addMember,
    memberCount,
    setMemberCount,
    modelShow,
    setModelShow,
    navActive,
    setNavActive,
    teamsCreateFunc,
    teamInfo,
    payingMember,
    delObjectFunc,
    administratorFunc,
    teamArr,
    handleChange,
    phoneHandle,
    backBtnFunc,
  };
};

export { UseTeamInfo };
