import React from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { Input } from "reactstrap";
import { UseInfoEmail } from "./useInfoEmail";
import FormInput from "../../../components/molicules/formInputs";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CloseIcon from "../../../images/icons/icons8-close-48.svg";
import bottomPic from "../../../images/footers/footer-mobile.svg";
import CancelModel from "../../../components/molicules/cancelApplicationModel";
import "./css/emailInfo.css";

const EmailInfo = () => {
  // const email = useContext(EmailAddress1);

  const {
    setVerifyInput,
    emailFormate,
    resendButtonEnabled,
    setResendButtonEnabled,
    emailInput,
    sendButtonText,
    modelShow,
    inputFunc,
    emailSubmitFunc,
    setModelShow,
    verifyInputFunc,
    verifyInput,
    submitVerificationCodeFunc,
  } = UseInfoEmail();
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="allCardsPadding">
          <div className="ebgcolor p-4 ">
            <Row>
              <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                <span className="emainheading">Email Information</span>
                <p className="mailmainpheading2">
                  Please provide the information below
                </p>
              </Col>

              <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                <div className="icon-box ">
                  {/* <Link to='/wellcome'> */}
                  <span onClick={() => setModelShow(true)}>
                    <img src={CloseIcon} alt="logo" width={24} height={24} />
                  </span>
                  {/* </Link> */}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={10} md={10} sm={10} xs={10}>
                <p className="emainheadingMobView my-2 ">Tour</p>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card className="p-4 form-card">
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <span className="ecardheadings">Step 1</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="mt-3"
                    >
                      <span className="ecardheadingss ">Email address</span>
                      <p className="ecardheadings2">
                        Please provide your email address
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormInput
                        className=""
                        name="fName"
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          inputFunc(e);
                        }}
                        errorMessage="Please enter email address"
                        required={true}
                        type="email"
                        validate="required"
                      />
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                    <p className='mt-3 eresendlink'>Resend code</p>
                  </Col> */}
                    <Col
                      xl={2}
                      lg={2}
                      md={2}
                      sm={3}
                      xs={3}
                      className="mt-3"
                    ></Col>
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      sm={3}
                      xs={3}
                      className="mt-3 pl-4"
                    >
                      {resendButtonEnabled ? (
                        <CountdownCircleTimer
                          isPlaying
                          duration={60}
                          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                          colorsTime={[7, 5, 2, 0]}
                          size={40}
                          strokeWidth={4}
                          strokeLinecap="butt"
                          // trailColor='black'
                          onComplete={() => {
                            setResendButtonEnabled(false);
                          }}
                        >
                          {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                      <Button
                        color="secondary"
                        className="ebutton1 w-100 mt-3 h-53 "
                        type="submit"
                        disabled={
                          !emailInput || resendButtonEnabled || emailFormate
                        }
                        onClick={emailSubmitFunc}
                        // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                      >
                        {/* Send Code */}
                        {sendButtonText}
                      </Button>
                    </Col>
                  </Row>

                  {/* { verificaitionEnablbe? */}
                  <div>
                    <Row className="mt-3">
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <span className="ecardheadings">Step 2</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="mt-3"
                      >
                        <span className="ecardheadingss">
                          Verification code
                        </span>
                        <p className="ecardheadings2">
                          Please provide code sent to your email address
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Input
                          placeholder="Enter Verification code"
                          onChange={(e) => {
                            verifyInputFunc(e);
                          }}
                          type="number"
                          min={0}
                          className="emailinputs"
                          // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-5">
                      <Col xl={3} lg={3} md={3} sm={6} xs={6}></Col>
                      {/* <Col lg={1}  ></Col> */}
                      <Col xl={3} lg={3} md={6} sm={6} xs={6} className="btn-p">
                        {/* <Link to="/tours/profileInfo">  */}
                        {/* <EmailAddress.Provider value={"haider@gmail.com"}> */}
                        <Button
                          color="secondary"
                          onClick={submitVerificationCodeFunc}
                          disabled={!verifyInput}
                          className="ebutton1 w-100 mt-3 h-53"
                        >
                          {/* <p class='ebuttons'> */}
                          Proceed
                          {/* </p> */}
                        </Button>
                        {/* </EmailAddress.Provider > */}
                        {/* </Link> */}
                      </Col>
                    </Row>
                  </div>
                  {/* : ""} */}
                </Card>
              </Col>
            </Row>
            {/* <div className='mobile-background'></div> */}
          </div>
        </div>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <img src={bottomPic} alt="img" className="bottomImg" />
          </Col>
        </Row>
      </Col>
      <CancelModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Cancel Application`}
        body={`Are you sure you want to cancel this application any saved progress will be lost?`}
        link={`/wellcome`}
      />
    </Row>
  );
};

export default EmailInfo;
// export {EmailAddress};
