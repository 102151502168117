import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { getTourDetails } from "../../../APIS/schduleInfo";
import axiosClient from "../../../config/axios";
import { toast } from "react-toastify";

const UseReschduleTour = () => {
  const { tourId } = useParams();
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const navigate = useNavigate();

  // ************Get Api OF tour details**********
  const { data: getTourDetailsobj } = useQuery(
    ["tourdetails", tourId],
    () => getTourDetails(tourId),
    {
      retry: 0,
    }
  );
  const getTourDetailsobject = getTourDetailsobj?.res?.data;

  // *****************  End***************

  const cancelTourFunc = () => {
    window.scrollTo(0, 0);

    let data = JSON.stringify({
      status: "cancelled",
      declineReason: "User cancel his tour",
    });

    var config = {
      method: "patch",
      url: `/reschedule-tours/${tourId}`,
      headers: {
        Access: "*/*",
        //   Content-Type : "Application/json"
        //   Authorization: ` ${bariertoken}`,
      },
      data: data,
    };

    axiosClient(config)
      .then(function (response) {
        if (response?.data?.statusCode === 200) {
          setModelShow(false);
          navigate("/wellcome");
          toast.success(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        return toast.error(error?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const reSchduleTourFunc = () => {
    window.scrollTo(0, 0);

    localStorage.setItem("reschduleFlow", true);
    localStorage.setItem("reschduleFlowTourId", `${tourId}`);
  };

  function toCamelCase(name) {
    const words = name?.split(" ");
    const capitalizedWords = words?.map((word) => {
      if (word.length > 0) {
        return word?.charAt(0).toUpperCase() + word?.slice(1);
      }
      return word;
    });
    return capitalizedWords?.join(" ");
  }

  return {
    getTourDetailsobject,
    modelShow,
    setModelShow,
    cancelTourFunc,
    reSchduleTourFunc,
    modelShow1,
    setModelShow1,
    toCamelCase,
  };
};

export default UseReschduleTour;
