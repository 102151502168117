import { useEffect, useState } from "react";
import axiosClient from "../../../config/axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Suadi_fintech_URL } from "../../../config/url";
import moment from "moment";

const UseReviewDetails = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [photoDataByApi,setPhotoDataByApi] = useState([])
  const [photoDataByApi1,setPhotoDataByApi1] = useState([])
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  var errorTrue = false;

  const userEmail = localStorage.getItem("userEmail");

  const { state } = useLocation();
  const {

    profileData,
    companyInfoPrev,
    teamArrPre,
    slotValuePre,
    dateValuePre,
    selectedFiles,
    selectedFiles1,
  } = state;
 

  const [modelShow, setModelShow] = useState(false);
  const [photoUrl,setPhotoUrl] = useState()
  const[modelShow1,
    setModelShow1] = useState(false);

  function toLowerCase(email) {
    return email ? email.toLowerCase() : "";
  }

  function flattenArray(arr) {
    return arr.reduce((acc, curr) => {
      if (Array.isArray(curr)) {
        return acc.concat(flattenArray(curr));
      }
      return acc.concat(curr);
    }, []);
  }

 

  const logoUploadFunction= async ()=>{
    // var baseUrl = "https://hubportalapi.fintechsaudi.com/";
// //logo upload Api
if (companyInfoPrev?.registrationStatus === "Yes"){
 var  logo = selectedFiles

 var logoBlob = new Blob([logo.content], { type: logo.type });



 
    var formdata = new FormData();
    // formdata.append("file", logo);
    // Append logo information to FormData
formdata.append("file", logoBlob, logo.name);
formdata.append("size", logo.size);


    var config = {
      method: "post",
      // url: `${baseUrl}files`,
      url: `files`,
      headers: {
        "Content-Type": "multipart/form-data",
        // Accept: "application/json, text/plain",
      },
      data: formdata,
    };

  await  axiosClient(config)
      .then(function (response) {
        if (response?.status === 200) {
         
          setPhotoDataByApi([response?.data?.files[0]?.originalname]);
          toast.success("logo uploaded successfully")
        } else {
          toast.error("something went wrong to upload photo", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (err) {
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

}
  }



  const docxUploadFunction= async ()=>{
    // var baseUrl = "https://hubportalapi.fintechsaudi.com/";
// //logo upload Api
if (companyInfoPrev?.registrationStatus === "Yes"){
 var  logo = selectedFiles1

 var logoBlob = new Blob([logo.content], { type: logo.type });




    var formdata = new FormData();
    // formdata.append("file", logo);
    // Append logo information to FormData
formdata.append("file", logoBlob, logo.name);
formdata.append("size", logo.size);


    var config = {
      method: "post",
      // url: `${baseUrl}files`,
      url: `files`,
      headers: {
        "Content-Type": "multipart/form-data",
        // Accept: "application/json, text/plain",
      },
      data: formdata,
    };

  await  axiosClient(config)
      .then(function (response) {
        if (response?.status === 200) {
         
          setPhotoDataByApi1([response?.data?.files[0]?.originalname]);
          toast.success("Documents uploaded successfully")
        } else {
          toast.error("something went wrong to upload documents", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (err) {
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

}
  }
  
  const scheduledSubmitFunc = async() => {
    window.scrollTo(0, 0);
    try {



      if(companyInfoPrev?.registrationStatus === "Yes"){
    await logoUploadFunction() 
    await docxUploadFunction()
 

      }else{
  await   dataSubmitFunction()
      }



} catch (error) {
  console.error("Error:", error);
  // Handle the error if necessary
}
  };


 const dataSubmitFunction = async() => {

  if(companyInfoPrev?.registrationStatus === "Yes" && photoDataByApi?.length === 0 ){
    toast.error("logo is required")
  }
  else if(companyInfoPrev?.registrationStatus === "Yes" && photoDataByApi1?.length === 0 ){
    toast.error("Documents are required")
  }
  else{
    
    let data = {
      userId: userId,
      userName: profileData?.firstName + " " + profileData?.lastName,
      // status: "pending",
      companyName: companyInfoPrev?.companyName,
      operationalStatus: companyInfoPrev?.operationalStatus,
      companyDescription: companyInfoPrev?.companyDescription,
      registrationStatus: companyInfoPrev?.registrationStatus,
      companyAddress: companyInfoPrev?.companyAddress,
      email: toLowerCase(companyInfoPrev?.emailAddress),
      phoneNumber: companyInfoPrev?.phoneNum,
      website: companyInfoPrev?.webSite,
      linkedin: companyInfoPrev?.linkedinName,
      companyMembers: teamArrPre ? teamArrPre : [{}],
      twitter: companyInfoPrev?.twitterName,
      attachLogo: photoDataByApi.toString(),
      // attachLogo:"https://placehold.co/600x400/000000/FFFFFF.png",
      // images:["https://placehold.co/600x400/000000/FFFFFF.png"]
    };

    if (companyInfoPrev?.registrationStatus === "Yes") {
      data.vatNumber = Number(companyInfoPrev?.vatNumber);
      data.commercialRegister = Number(companyInfoPrev?.commercialRegister);
    }
    if (photoDataByApi1 !== []) {
      data.images = flattenArray(photoDataByApi1);
    }

    if(photoDataByApi){
      data.attachLogo = photoDataByApi.toString()
    }
    if (dateValuePre !== "") {
      data.date = moment(dateValuePre).toISOString();
    } else if (slotValuePre !== "") {
      data.joinPreference = slotValuePre;
    }

    var config = {
      method: "post",
      url: "/memberships",
      headers: {
        Access: "*/*",
        //   Content-Type : "Application/json"
        //   Authorization: ` ${bariertoken}`,
      },
      data: JSON.stringify(data),
    };

    


    axiosClient(config)
      .then(function (response) {
        if (response?.status === 200) {
          errorTrue = false;
          localStorage.setItem("navActive", ["1", "2", "3", "4", "5", "6"]);
          navigate("/membership/applicationSubmitted");
        } else {
          if (errorTrue === false) {
            errorTrue = true;
            return toast.error(response?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      })
      .catch(function (error) {
        if (errorTrue === false) {
          errorTrue = true;
          return toast.error(error?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  }
}

  function openPrivacyPolicy() {
    const url =
      "https://hubportalapi.fintechsaudi.com/uploads/images/Fintech%20Saudi_Bilingual%20Privacy%20Policy_v2.2.pdf";
    window.open(url, "_blank");
  }




  useEffect(()=>{
    
    if (photoDataByApi.length > 0 && photoDataByApi1.length > 0) {
     
      dataSubmitFunction(photoDataByApi)
  }
  },[photoDataByApi,photoDataByApi1])

  return {
    modelShow1,
    setModelShow1,
    openPrivacyPolicy,
    privacyCheck,
    setPrivacyCheck,
    userEmail,
    photoDataByApi,
    modelShow,
    setModelShow,
    scheduledSubmitFunc,
    profileData,
    companyInfoPrev,
    navigate,
    photoDataByApi1,
    teamArrPre,
    dateValuePre,
    slotValuePre,
    selectedFiles,
    selectedFiles1
  };
};

export default UseReviewDetails;
